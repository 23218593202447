"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTermsOfUseStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
exports.getTermsOfUseStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    mainContainer: {
        width: '100%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
    },
    date: Object.assign(Object.assign({}, theme.typography.$textPrimary150), { paddingBottom: theme.spacing.$s2 }),
    title: Object.assign(Object.assign({}, theme.typography.$textPrimary550), { 
        // DIFF lineHeight: '46px',
        textAlign: 'center', margin: `${theme.spacing.$s5} auto` }),
    section: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        margin: `${theme.spacing.$s3} 0`,
    },
    subTitle: Object.assign(Object.assign({}, theme.typography.$textPrimary250), { textTransform: 'uppercase' }),
    content: Object.assign(Object.assign({}, theme.typography.$textPrimary100), { whiteSpace: 'pre-wrap', margin: `${theme.spacing.$s3} 0 ${theme.spacing.$s4}` }),
    contentItem: Object.assign(Object.assign({}, theme.typography.$textPrimary150), { textTransform: 'uppercase' }),
    [`@media (max-width: ${theme.breakpoints.$sm}px)`]: {
        date: Object.assign(Object.assign({}, theme.typography.$textPrimary050), { paddingBottom: theme.spacing.$s2 }),
        title: Object.assign(Object.assign({}, theme.typography.$textPrimary450), { 
            // DIFF lineHeight: '36px',
            margin: `${theme.spacing.$s3} auto` }),
        section: {
            margin: `${theme.spacing.$s2} 0`,
        },
        subTitle: Object.assign(Object.assign({}, theme.typography.$textPrimary150), { textTransform: 'uppercase' }),
        content: {
            margin: `${theme.spacing.$s2} 0 ${theme.spacing.$s3}`,
        },
    },
}), { name: 'Blueprint-TermsOfUse' });
