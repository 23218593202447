"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSwitchStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
const theme_1 = require("../../../themes/typings/theme");
const StyleUtils_1 = require("../../../utils/StyleUtils");
exports.getSwitchStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    container: Object.assign(Object.assign({}, (0, StyleUtils_1.flexMixin)('row', 'center', 'center')), { position: 'relative', width: 'fit-content', transformOrigin: '0 0' }),
    label: {
        cursor: 'pointer',
        '&$disabled:hover': {
            cursor: 'initial',
        },
    },
    input: Object.assign(Object.assign({}, (0, StyleUtils_1.flexMixin)()), { bottom: 0, left: 0, opacity: 0, position: 'absolute', right: 0, top: 0, '&:focus-visible': {
            // target sibling icon for focus-visible
            '& ~ div > span': {
                outline: '2px solid blue',
            },
        } }),
    slideContainer: {
        position: 'relative',
        '&$disabled': {
            backgroundColor: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$primary300 : theme.colors.$neutral300,
        },
    },
    slideKnob: Object.assign(Object.assign(Object.assign({}, theme.shadows.$shadow200), (0, StyleUtils_1.flexMixin)('row', 'center', 'center')), { position: 'absolute', borderRadius: '50%', transition: 'transform .1s linear, background-color .1s linear, left .1s linear', '&$off': {
            left: 0,
            transform: 'translateX(0)',
        }, '&$on': {
            left: '100%',
            transform: 'translateX(-100%)',
        }, '&$disabled': {
            backgroundColor: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$primary200 : theme.colors.$neutral200,
        } }),
    off: {},
    on: {},
    disabled: {},
}), { name: 'Blueprint-Switch' });
