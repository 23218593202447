"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTextareaStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
exports.getTextareaStyles = (0, Utils_1.createStyleSheet)({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginBottom: 0,
        width: '100%',
    },
    wrapper: ({ styles }) => ({
        backgroundColor: styles.backgroundColor,
        borderColor: styles.borderColor,
        borderRadius: styles.borderRadius,
        borderStyle: styles.borderStyle,
        borderWidth: styles.borderWidth,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        padding: styles.padding,
        width: styles.width,
        '&:hover': {
            backgroundColor: styles.$hover.backgroundColor,
            borderColor: styles.$hover.borderColor,
            color: styles.$hover.color,
        },
        '&$focus': {
            backgroundColor: styles.$hover.backgroundColor,
            borderColor: styles.$hover.borderColor,
            color: styles.$hover.color,
        },
        '&$disabled': {
            backgroundColor: styles.$disabled.backgroundColor,
            borderColor: styles.$disabled.borderColor,
            color: styles.$disabled.color,
            '&:hover': {
                cursor: 'not-allowed',
            },
        },
    }),
    hasError: ({ styles }) => ({
        backgroundColor: styles.$error.backgroundColor,
        borderColor: styles.$error.borderColor,
        color: styles.$error.color,
        '&:hover': {
            borderColor: styles.$error.borderColor,
            color: styles.$error.color,
        },
        '&$focus': {
            borderColor: styles.$error.borderColor,
            color: styles.$error.color,
        },
        '&$disabled': {
            borderColor: styles.$error.borderColor,
            color: styles.$error.color,
        },
    }),
    preventResize: {
        resize: 'none',
    },
    hidden: {
        position: 'absolute',
        left: '-10000px',
        top: 'auto',
        width: '1px',
        height: '1px',
        overflow: 'hidden',
    },
    label: ({ styles }) => ({
        color: styles.$label.color,
        '&$disabled': {
            color: styles.$label.color,
            '&:hover': {
                cursor: 'not-allowed',
            },
        },
        '&$hasError, &$disabled$hasError': {
            color: styles.$labelError.color,
        },
    }),
    innerWrapper: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: ({ styles }) => styles.width,
        marginTop: ({ shouldHideLabel, theme }) => (!shouldHideLabel ? theme.spacing.$s1 : 0),
    },
    textarea: ({ styles }) => (Object.assign(Object.assign({}, styles), { backgroundColor: styles.backgroundColor, borderColor: 'transparent', borderStyle: 'none', borderWidth: '0px', height: '100%', width: styles.width, padding: 0, '&:disabled': {
            backgroundColor: styles.$disabled.backgroundColor,
            '&:hover': {
                cursor: 'not-allowed',
            },
        }, '&:focus': {
            outline: 'none',
        } })),
    focus: {},
    disabled: {},
    helperContainer: ({ theme }) => (Object.assign(Object.assign({ display: 'flex', justifyContent: 'flex-start' }, theme.typography.$textSecondary000), { color: theme.colors.$neutral400, margin: `${theme.spacing.$s1} 0 0` })),
    errorMessage: {
        color: ({ styles }) => styles.$error.color,
    },
}, { name: 'Blueprint-Textarea' });
