"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToastProvider = exports.useToasts = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const Utils_1 = require("../../utils/Utils");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const ToastContainer_1 = require("./ToastContainer");
const Toast_1 = require("./Toast");
let currentToasts;
const getCurrentToasts = () => {
    return currentToasts;
};
const ToastContext = (0, react_1.createContext)({
    addToast: () => { },
    removeToast: () => { },
    removeAll: () => { },
});
const useToasts = () => (0, react_1.useContext)(ToastContext);
exports.useToasts = useToasts;
const ToastProvider = (props) => {
    const { children, newestOnTop = true, autoHideDuration = 4000, offsetTop = 80 } = props;
    const [toasts, setToasts] = (0, react_1.useState)([]);
    const { pathname } = (0, Utils_1.parseLocation)((0, ThemeProvider_1.useThemeRouter)().router);
    (0, react_1.useEffect)(() => {
        currentToasts = toasts;
    }, [toasts]);
    (0, react_1.useEffect)(() => {
        removeAll();
    }, [pathname]);
    const addToast = (content, mode = Toast_1.ToastMode.SUCCESS, variant = Toast_1.ToastVariant.DEFAULT) => {
        const id = (0, Utils_1.generateUUID)();
        const toast = { content, id, mode, variant };
        const messages = newestOnTop ? [toast, ...currentToasts] : [...currentToasts, toast];
        setToasts(messages);
    };
    const removeToast = (id) => {
        const newToasts = getCurrentToasts().filter(t => t.id !== id);
        setToasts(newToasts);
    };
    const removeAll = () => {
        setToasts([]);
    };
    const value = (0, react_1.useMemo)(() => ({
        addToast,
        removeToast,
        removeAll,
    }), [toasts]);
    return ((0, jsx_runtime_1.jsxs)(ToastContext.Provider, Object.assign({ value: value }, { children: [children, (0, jsx_runtime_1.jsx)(ToastContainer_1.ToastContainer, Object.assign({ offsetTop: offsetTop }, { children: toasts.map(toast => {
                    return ((0, jsx_runtime_1.jsx)(Toast_1.Toast, { id: toast.id, content: toast.content, mode: toast.mode, duration: autoHideDuration, variant: toast.variant }, toast.id));
                }) }))] })));
};
exports.ToastProvider = ToastProvider;
