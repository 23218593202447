"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTableHeadStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
exports.getTableHeadStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    container: {
        width: '100%',
        height: 'fit-content',
        backgroundColor: theme.colors.$background100,
    },
}), { name: 'Blueprint-TableHead' });
