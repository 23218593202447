"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Footer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_intl_1 = require("react-intl");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const Utils_1 = require("../../utils/Utils");
const logos_1 = require("../../assets/logos");
const StyleUtils_1 = require("../../utils/StyleUtils");
const Footer_styles_1 = require("./styles/Footer.styles");
const defaultFooterItems_1 = require("./defaultFooterItems");
const FooterSection_1 = require("./FooterSection");
const SubFooter_1 = require("./SubFooter");
const AuxiliaryFooter_1 = require("./AuxiliaryFooter");
const LegalFooter_1 = require("./LegalFooter");
const __DEV__ = process.env.NODE_ENV !== 'production';
const Footer = (props) => {
    var _a, _b;
    const theme = (0, ThemeProvider_1.useTheme)();
    const { auxiliaryContent = '', className = '', domains, footerItems = defaultFooterItems_1.defaultFooterItems, logo, maxWidth = StyleUtils_1.maxContainerWidth, mobileBreakpoint = theme.breakpoints.$sm, showAuxiliaryFooter = true, showFooter = true, showLegalFooter = true, showSubFooter = true, testId, } = props;
    const intl = (0, react_intl_1.useIntl)();
    if (!theme) {
        if (__DEV__) {
            console.error('`Footer` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    if (!intl) {
        if (__DEV__) {
            console.error('`Footer` component must be a child of a `IntlProvider`');
        }
        return null;
    }
    const styles = (0, Footer_styles_1.getFooterStyles)();
    const dti = (0, Utils_1.getTestIdAttribute)(testId);
    const subFooterDti = (0, Utils_1.getTestIdAttribute)('footer-sub-footer');
    let footerLogo = logo;
    if (!footerLogo) {
        footerLogo = (0, jsx_runtime_1.jsx)(logos_1.FullLightLogo, { className: styles.logo });
    }
    const [isMobile, setIsMobile] = (0, react_1.useState)((_b = ((_a = global.window) === null || _a === void 0 ? void 0 : _a.innerWidth) <= mobileBreakpoint) !== null && _b !== void 0 ? _b : false);
    const handler = () => {
        if (window.innerWidth < mobileBreakpoint) {
            setIsMobile(true);
        }
        else
            setIsMobile(false);
    };
    (0, react_1.useEffect)(() => {
        handler();
        window.addEventListener('resize', handler);
        return () => {
            // Cleanup the event listener
            window.removeEventListener('resize', handler);
        };
    }, []);
    return ((0, jsx_runtime_1.jsxs)("footer", Object.assign({ id: "gromaFooter", className: (0, StyleUtils_1.classNames)('Footer-local', styles.footerContainer, styles.default, className) }, { children: [showFooter && ((0, jsx_runtime_1.jsx)("nav", Object.assign({ "aria-label": "gromaFooterNav", className: (0, StyleUtils_1.classNames)('Footer-wrapper-local', styles.footerWrapper), style: { maxWidth: `${maxWidth}px` } }, dti, { children: footerItems.map((section, index) => ((0, jsx_runtime_1.jsx)(FooterSection_1.FooterSection, { section: section, isLocationDetails: index === 0, domains: domains }, section.id))) }))), showSubFooter && ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: (0, StyleUtils_1.classNames)('Footer-subWrapper-local', styles.subFooterWrapper), style: { maxWidth: `${maxWidth}px` } }, subFooterDti, { children: (0, jsx_runtime_1.jsx)(SubFooter_1.SubFooter, { isMobile: isMobile, domains: domains, logo: footerLogo }, isMobile ? 1 : 0) }))), showAuxiliaryFooter && (0, jsx_runtime_1.jsx)(AuxiliaryFooter_1.AuxiliaryFooter, { content: auxiliaryContent, isMobile: isMobile }), showLegalFooter && (0, jsx_runtime_1.jsx)(LegalFooter_1.LegalFooter, {})] })));
};
exports.Footer = Footer;
