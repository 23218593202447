"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TablePagination = exports.RowsPerPage = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const Select_1 = require("../Select/Select");
const icons_1 = require("../../assets/icons");
const Utils_1 = require("../../utils/Utils");
const TablePagination_styles_1 = require("./styles/TablePagination.styles");
const Table_1 = require("./Table");
const __DEV__ = process.env.NODE_ENV !== 'production';
var RowsPerPage;
(function (RowsPerPage) {
    RowsPerPage[RowsPerPage["TEN"] = 10] = "TEN";
    RowsPerPage[RowsPerPage["TWENTY_FIVE"] = 25] = "TWENTY_FIVE";
    RowsPerPage[RowsPerPage["ONE_HUNDRED"] = 100] = "ONE_HUNDRED";
})(RowsPerPage = exports.RowsPerPage || (exports.RowsPerPage = {}));
const TablePagination = (props) => {
    const { rowsPerPageOptions = [RowsPerPage.TEN, RowsPerPage.TWENTY_FIVE, RowsPerPage.ONE_HUNDRED], showRowsPerPage = true, showRowsPrompt = true, disableNext, disablePrev, handleNextPage, handlePrevPage, handleRowsPerPage, isLoading = false, testId = 'tablePagination', } = props;
    const theme = (0, ThemeProvider_1.useTheme)();
    const table = (0, Table_1.useTable)();
    const styles = (0, TablePagination_styles_1.getTablePaginationStyles)();
    const dti = (0, Utils_1.getTestIdAttribute)(testId);
    const { rowsPerPage, page, count, data, setPage, setRowsPerPage } = table;
    const [initialized, setInitialized] = (0, react_1.useState)(false);
    if (!theme) {
        if (__DEV__) {
            console.error('`TablePagination` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    if (!table) {
        if (__DEV__) {
            console.error('`TablePagination` component must be a child of a `Table` component');
        }
        return null;
    }
    const getRecordsOnPage = () => {
        if ((page - 1) * rowsPerPage + rowsPerPage >= count) {
            return `${(page - 1) * rowsPerPage + 1} - ${count} of ${count}`;
        }
        else if (page - 1 === 0)
            return `1 - ${rowsPerPage} of ${count}`;
        else
            return `${(page - 1) * rowsPerPage + 1} - ${(page - 1) * rowsPerPage + rowsPerPage} of ${count}`;
    };
    // Default pagination methods
    const handleRowsPerPageChange = (value) => {
        setRowsPerPage(parseInt(value));
    };
    const handleNextPageChange = (page) => {
        setPage(page + 1);
    };
    const handlePrevPageChange = (page) => {
        setPage(page - 1);
    };
    (0, react_1.useEffect)(() => {
        setInitialized(true);
    }, []);
    return ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: `TablePagination-local ${styles.container}` }, dti, { children: (0, jsx_runtime_1.jsx)("div", Object.assign({ className: `TablePagination-wrapper-local ${styles.wrapper}` }, { children: initialized && data.rows.length > 0 && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [showRowsPerPage && ((0, jsx_runtime_1.jsx)(Select_1.Select, Object.assign({ className: styles.select, label: "Rows per page", name: "rowsPerPage", defaultValue: `${rowsPerPage}`, disabled: isLoading, testId: "rowsPerPageSelect", onChange: handleRowsPerPage
                            ? (value) => handleRowsPerPage(parseInt(value), setRowsPerPage)
                            : handleRowsPerPageChange }, { children: rowsPerPageOptions === null || rowsPerPageOptions === void 0 ? void 0 : rowsPerPageOptions.map((count) => ((0, jsx_runtime_1.jsx)(Select_1.Select.Option, Object.assign({ value: `${count}`, name: `${count}` }, { children: count }), count))) }))), showRowsPrompt && ((0, jsx_runtime_1.jsx)("p", Object.assign({ className: `TablePagination-prompt-local ${styles.prompt}` }, (0, Utils_1.getTestIdAttribute)('rowsPrompt'), { children: getRecordsOnPage() }))), (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: `TablePagination-buttonContainer-local ${styles.buttonContainer}` }, { children: [(0, jsx_runtime_1.jsx)("button", Object.assign({ className: `TablePagination-button-local ${styles.button}`, "aria-label": "previous page", onClick: () => (handlePrevPage ? handlePrevPage(page, setPage) : handlePrevPageChange(page)), disabled: isLoading || (disablePrev === undefined ? page === 1 : disablePrev) }, (0, Utils_1.getTestIdAttribute)('prevButton'), { children: (0, jsx_runtime_1.jsx)(icons_1.ArrowLeftIcon, { className: styles.icon }) })), (0, jsx_runtime_1.jsx)("button", Object.assign({ className: `TablePagination-button-local ${styles.button}`, "aria-label": "next page", onClick: () => (handleNextPage ? handleNextPage(page, setPage) : handleNextPageChange(page)), disabled: isLoading ||
                                    (disableNext === undefined ? (page - 1) * rowsPerPage + rowsPerPage >= count : disableNext) }, (0, Utils_1.getTestIdAttribute)('nextButton'), { children: (0, jsx_runtime_1.jsx)(icons_1.ArrowRightIcon, { className: styles.icon }) }))] }))] })) })) })));
};
exports.TablePagination = TablePagination;
