import isNil from 'lodash/isNil';
import { observable, action, makeObservable } from 'mobx';
import { APIClient as ScaffoldClient, FetchUserResponse, SignOutUserResponse } from '@groma-dev/scaffold';
import { AppConfig } from '../AppConfig';
import { User } from '../models';
import { ScaffoldClientHeaders, UserStateInterface } from './DataStoreInterface';

const userInitialState: UserStateInterface = {
  fetchingUser: false,
  user: undefined,
};

export default class DataStore {
  /**
   * CLASS VARIABLES
   */
  private static instance: any = null;

  /**
   * MOBX OBSERVABLES
   */

  @observable
  userState = userInitialState;

  constructor() {
    if (isNil(DataStore.instance)) {
      DataStore.instance = this;
    }

    makeObservable(this);

    // DataStore is a singleton class
    // eslint-disable-next-line no-constructor-return
    return DataStore.instance;
  }

  /**
   * MOBX ACTIONS
   */
  @action
  fetchUser = async (): Promise<FetchUserResponse> => {
    this.setFetchingUser(true);
    const { baseUrl } = AppConfig.Settings.Server;
    const response: FetchUserResponse = await ScaffoldClient.fetchUser({
      baseURL: baseUrl,
      headers: ScaffoldClientHeaders,
    });

    if (response.success) this.setUser(response.user);

    this.setFetchingUser(false);

    return response;
  };

  @action
  signOutUser = async (): Promise<SignOutUserResponse> => {
    const { baseUrl } = AppConfig.Settings.Server;
    const response = await ScaffoldClient.signOutUser({
      baseURL: baseUrl,
      headers: ScaffoldClientHeaders,
    });
    return response;
  };

  @action
  setFetchingUser = (value: boolean) => {
    this.userState.fetchingUser = value;
  };

  @action
  setUser = (user?: User) => {
    this.userState.user = user;
  };
}
