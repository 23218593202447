"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrivacyPolicy = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_intl_1 = require("react-intl");
const scaffold_1 = require("@groma-dev/scaffold");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const Utils_1 = require("../../utils/Utils");
const PrivacyPolicy_styles_1 = require("./styles/PrivacyPolicy.styles");
const locales_1 = require("./locales");
const __DEV__ = process.env.NODE_ENV !== 'production';
const PrivacyPolicy = (props) => {
    var _a;
    const { websiteLink = 'https://groma.com' } = props;
    const theme = (0, ThemeProvider_1.useTheme)();
    if (!theme) {
        if (__DEV__) {
            console.error('`PrivacyPolicy` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const styles = (0, PrivacyPolicy_styles_1.getPrivacyPolicyStyles)();
    const browserLocale = navigator.language;
    const currentLocale = (_a = locales_1.locales[browserLocale]) !== null && _a !== void 0 ? _a : locales_1.locales[scaffold_1.LocaleCode.en_us];
    const flattenedLocales = (0, Utils_1.flattenLocales)(currentLocale);
    return ((0, jsx_runtime_1.jsx)(react_intl_1.IntlProvider, Object.assign({ locale: browserLocale !== null && browserLocale !== void 0 ? browserLocale : scaffold_1.LocaleCode.en_us, messages: flattenedLocales, defaultLocale: scaffold_1.LocaleCode.en_us }, { children: (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: styles.mainContainer }, { children: [(0, jsx_runtime_1.jsx)("p", Object.assign({ className: styles.date }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "main.modifiedDate" }) })), (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: styles.section }, { children: [(0, jsx_runtime_1.jsx)("h1", Object.assign({ className: styles.title }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "main.title" }) })), (0, jsx_runtime_1.jsx)("p", Object.assign({ className: styles.content }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "main.content", values: {
                                    b: (chunk) => (0, jsx_runtime_1.jsx)("strong", { children: chunk }),
                                    websiteLink: ((0, jsx_runtime_1.jsx)("a", Object.assign({ href: websiteLink, style: { textDecoration: 'underline' } }, { children: websiteLink }))),
                                } }) }))] })), (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: styles.section }, { children: [(0, jsx_runtime_1.jsx)("h2", Object.assign({ className: styles.subTitle }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "personalInformation.title" }) })), (0, jsx_runtime_1.jsx)("p", Object.assign({ className: styles.content }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "personalInformation.content", values: {
                                    b: (chunk) => (0, jsx_runtime_1.jsx)("strong", { children: chunk }),
                                } }) })), (0, jsx_runtime_1.jsx)("ul", Object.assign({ className: styles.list }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "personalInformation.list", values: {
                                    li: (chunk) => (0, jsx_runtime_1.jsx)("li", Object.assign({ className: styles.listItem }, { children: chunk })),
                                } }) }))] })), (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: styles.section }, { children: [(0, jsx_runtime_1.jsx)("p", Object.assign({ className: styles.content }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "personalInformation.collection", values: {
                                    b: (chunk) => (0, jsx_runtime_1.jsx)("strong", { children: chunk }),
                                } }) })), (0, jsx_runtime_1.jsxs)("ul", { children: [(0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "personalInformation.servicesList", values: {
                                        li: (chunk) => (0, jsx_runtime_1.jsx)("li", Object.assign({ className: `${styles.listItem} ${styles.parentItem}` }, { children: chunk })),
                                        sli: (chunk) => (0, jsx_runtime_1.jsx)("li", Object.assign({ className: styles.subListItem }, { children: chunk })),
                                    } }), (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "personalInformation.sourcesList", values: {
                                        li: (chunk) => (0, jsx_runtime_1.jsx)("li", Object.assign({ className: `${styles.listItem} ${styles.parentItem}` }, { children: chunk })),
                                        sli: (chunk) => (0, jsx_runtime_1.jsx)("li", Object.assign({ className: styles.subListItem }, { children: chunk })),
                                    } })] }), (0, jsx_runtime_1.jsx)("p", Object.assign({ className: styles.content }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "personalInformation.subContent" }) }))] })), (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: styles.section }, { children: [(0, jsx_runtime_1.jsx)("p", Object.assign({ className: styles.content }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "personalInformation.usageContent", values: {
                                    b: (chunk) => (0, jsx_runtime_1.jsx)("strong", { children: chunk }),
                                } }) })), (0, jsx_runtime_1.jsx)("ul", { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "personalInformation.usageFunctionality", values: {
                                    li: (chunk) => (0, jsx_runtime_1.jsx)("li", Object.assign({ className: `${styles.listItem} ${styles.parentItem}` }, { children: chunk })),
                                    sli: (chunk) => (0, jsx_runtime_1.jsx)("li", Object.assign({ className: styles.subListItem }, { children: chunk })),
                                } }) }), (0, jsx_runtime_1.jsx)("p", Object.assign({ className: styles.subListContent }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "personalInformation.usageFunctionalitySubcontent" }) })), (0, jsx_runtime_1.jsx)("ul", { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "personalInformation.usageMarketing", values: {
                                    li: (chunk) => (0, jsx_runtime_1.jsx)("li", Object.assign({ className: `${styles.listItem} ${styles.parentItem}` }, { children: chunk })),
                                    sli: (chunk) => (0, jsx_runtime_1.jsx)("li", Object.assign({ className: styles.subListItem }, { children: chunk })),
                                } }) }), (0, jsx_runtime_1.jsx)("p", Object.assign({ className: styles.subListContent }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "personalInformation.usageMarketingSubcontent" }) })), (0, jsx_runtime_1.jsx)("ul", { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "personalInformation.usageAnalysis", values: {
                                    li: (chunk) => (0, jsx_runtime_1.jsx)("li", Object.assign({ className: `${styles.listItem} ${styles.parentItem}` }, { children: chunk })),
                                } }) }), (0, jsx_runtime_1.jsx)("p", Object.assign({ className: styles.subListContent }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "personalInformation.usageAnalysisSubcontent" }) })), (0, jsx_runtime_1.jsxs)("ul", { children: [(0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "personalInformation.usageAggregation", values: {
                                        li: (chunk) => (0, jsx_runtime_1.jsx)("li", Object.assign({ className: `${styles.listItem} ${styles.parentItem}` }, { children: chunk })),
                                        sli: (chunk) => (0, jsx_runtime_1.jsx)("li", Object.assign({ className: styles.subListItem }, { children: chunk })),
                                    } }), (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "personalInformation.usagePurpose", values: {
                                        li: (chunk) => (0, jsx_runtime_1.jsx)("li", Object.assign({ className: `${styles.listItem} ${styles.parentItem}` }, { children: chunk })),
                                        sli: (chunk) => (0, jsx_runtime_1.jsx)("li", Object.assign({ className: styles.subListItem }, { children: chunk })),
                                    } })] }), (0, jsx_runtime_1.jsx)("p", Object.assign({ className: styles.subListContent }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "personalInformation.usagePurposeSubcontent" }) }))] })), (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: styles.section }, { children: [(0, jsx_runtime_1.jsx)("p", Object.assign({ className: styles.content }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "personalInformation.disclosure", values: {
                                    b: (chunk) => (0, jsx_runtime_1.jsx)("strong", { children: chunk }),
                                } }) })), (0, jsx_runtime_1.jsx)("ul", { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "personalInformation.disclosureContent", values: {
                                    sli: (chunk) => (0, jsx_runtime_1.jsx)("li", Object.assign({ className: styles.subListItem }, { children: chunk })),
                                } }) })] })), (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: styles.section }, { children: [(0, jsx_runtime_1.jsx)("p", Object.assign({ className: styles.content }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "personalInformation.otherUsage", values: {
                                    b: (chunk) => (0, jsx_runtime_1.jsx)("strong", { children: chunk }),
                                } }) })), (0, jsx_runtime_1.jsxs)("ul", { children: [(0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "personalInformation.otherUsageLaw", values: {
                                        li: (chunk) => (0, jsx_runtime_1.jsx)("li", Object.assign({ className: `${styles.listItem} ${styles.parentItem}` }, { children: chunk })),
                                        sli: (chunk) => (0, jsx_runtime_1.jsx)("li", Object.assign({ className: styles.subListItem }, { children: chunk })),
                                    } }), (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "personalInformation.otherUsageCooperate", values: {
                                        li: (chunk) => (0, jsx_runtime_1.jsx)("li", Object.assign({ className: `${styles.listItem} ${styles.parentItem}` }, { children: chunk })),
                                        sli: (chunk) => (0, jsx_runtime_1.jsx)("li", Object.assign({ className: styles.subListItem }, { children: chunk })),
                                    } }), (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "personalInformation.otherUsageCooperateOther", values: {
                                        li: (chunk) => (0, jsx_runtime_1.jsx)("li", Object.assign({ className: `${styles.listItem} ${styles.parentItem}` }, { children: chunk })),
                                        sli: (chunk) => (0, jsx_runtime_1.jsx)("li", Object.assign({ className: styles.subListItem }, { children: chunk })),
                                    } }), (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "personalInformation.otherUsageCooperateSale", values: {
                                        li: (chunk) => (0, jsx_runtime_1.jsx)("li", Object.assign({ className: `${styles.listItem} ${styles.parentItem}` }, { children: chunk })),
                                        sli: (chunk) => (0, jsx_runtime_1.jsx)("li", Object.assign({ className: styles.subListItem }, { children: chunk })),
                                    } })] })] })), (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: styles.section }, { children: [(0, jsx_runtime_1.jsx)("h2", Object.assign({ className: styles.subTitle }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "otherInformation.title" }) })), (0, jsx_runtime_1.jsx)("p", Object.assign({ className: styles.content }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "otherInformation.content", values: { b: (chunk) => (0, jsx_runtime_1.jsx)("strong", { children: chunk }) } }) })), (0, jsx_runtime_1.jsx)("ul", { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "otherInformation.list", values: {
                                    li: (chunk) => (0, jsx_runtime_1.jsx)("li", Object.assign({ className: styles.listItem }, { children: chunk })),
                                } }) }), (0, jsx_runtime_1.jsx)("div", Object.assign({ className: styles.subSection }, { children: (0, jsx_runtime_1.jsx)("p", Object.assign({ className: styles.content }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "otherInformation.collection", values: {
                                        b: (chunk) => (0, jsx_runtime_1.jsx)("strong", { children: chunk }),
                                    } }) })) })), (0, jsx_runtime_1.jsxs)("ul", { children: [(0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "otherInformation.browser", values: {
                                        li: (chunk) => (0, jsx_runtime_1.jsx)("li", Object.assign({ className: `${styles.listItem} ${styles.parentItem}` }, { children: chunk })),
                                        sli: (chunk) => (0, jsx_runtime_1.jsx)("li", Object.assign({ className: styles.subListItem }, { children: chunk })),
                                    } }), (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "otherInformation.cookies", values: {
                                        a: (chunks) => ((0, jsx_runtime_1.jsx)("a", Object.assign({ href: chunks, style: { textDecoration: 'underline' } }, { children: chunks }))),
                                        li: (chunk) => (0, jsx_runtime_1.jsx)("li", Object.assign({ className: `${styles.listItem} ${styles.parentItem}` }, { children: chunk })),
                                        sli: (chunk) => (0, jsx_runtime_1.jsx)("li", Object.assign({ className: styles.subListItem }, { children: chunk })),
                                    } }), (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "otherInformation.pixels", values: {
                                        a: (chunks) => ((0, jsx_runtime_1.jsx)("a", Object.assign({ href: chunks, style: { textDecoration: 'underline' } }, { children: chunks }))),
                                        b: (chunk) => (0, jsx_runtime_1.jsx)("strong", { children: chunk }),
                                        li: (chunk) => (0, jsx_runtime_1.jsx)("li", Object.assign({ className: `${styles.listItem} ${styles.parentItem}` }, { children: chunk })),
                                        sli: (chunk) => (0, jsx_runtime_1.jsx)("li", Object.assign({ className: styles.subListItem }, { children: chunk })),
                                    } }), (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "otherInformation.location", values: {
                                        li: (chunk) => (0, jsx_runtime_1.jsx)("li", Object.assign({ className: `${styles.listItem} ${styles.parentItem}` }, { children: chunk })),
                                        sli: (chunk) => (0, jsx_runtime_1.jsx)("li", Object.assign({ className: styles.subListItem }, { children: chunk })),
                                    } })] }), (0, jsx_runtime_1.jsx)("div", Object.assign({ className: styles.subSection }, { children: (0, jsx_runtime_1.jsx)("p", Object.assign({ className: styles.content }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "otherInformation.disclosure", values: {
                                        b: (chunk) => (0, jsx_runtime_1.jsx)("strong", { children: chunk }),
                                    } }) })) }))] })), (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: styles.section }, { children: [(0, jsx_runtime_1.jsx)("h2", Object.assign({ className: styles.subTitle }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "security.title" }) })), (0, jsx_runtime_1.jsx)("p", Object.assign({ className: styles.content }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "security.content", values: {
                                    i: (chunk) => (0, jsx_runtime_1.jsx)("span", Object.assign({ style: { fontStyle: 'italic' } }, { children: chunk })),
                                } }) }))] })), (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: styles.section }, { children: [(0, jsx_runtime_1.jsx)("h2", Object.assign({ className: styles.subTitle }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "access.title" }) })), (0, jsx_runtime_1.jsx)("p", Object.assign({ className: styles.content }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "access.content", values: {
                                    b: (chunk) => (0, jsx_runtime_1.jsx)("strong", { children: chunk }),
                                } }) })), (0, jsx_runtime_1.jsxs)("ol", { children: [(0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "access.marketingEmail", values: {
                                        b: (chunk) => (0, jsx_runtime_1.jsx)("strong", { children: chunk }),
                                        li: (chunk) => (0, jsx_runtime_1.jsx)("li", Object.assign({ className: `${styles.listItem} ${styles.number}` }, { children: chunk })),
                                    } }), (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "access.textMessage", values: {
                                        b: (chunk) => (0, jsx_runtime_1.jsx)("strong", { children: chunk }),
                                        li: (chunk) => (0, jsx_runtime_1.jsx)("li", Object.assign({ className: `${styles.listItem} ${styles.number}` }, { children: chunk })),
                                    } })] }), (0, jsx_runtime_1.jsx)("p", Object.assign({ className: styles.content }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "access.subContent", values: {
                                    b: (chunk) => (0, jsx_runtime_1.jsx)("strong", { children: chunk }),
                                } }) }))] })), (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: styles.section }, { children: [(0, jsx_runtime_1.jsx)("h2", Object.assign({ className: styles.subTitle }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "retention.title" }) })), (0, jsx_runtime_1.jsx)("p", Object.assign({ className: styles.content }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "retention.content", values: {
                                    i: (chunk) => (0, jsx_runtime_1.jsx)("span", Object.assign({ style: { fontStyle: 'italic' } }, { children: chunk })),
                                } }) })), (0, jsx_runtime_1.jsx)("ul", { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "retention.criteria", values: {
                                    i: (chunk) => (0, jsx_runtime_1.jsx)("span", Object.assign({ style: { fontStyle: 'italic' } }, { children: chunk })),
                                    li: (chunk) => (0, jsx_runtime_1.jsx)("li", Object.assign({ className: styles.listItem }, { children: chunk })),
                                } }) })] })), (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: styles.section }, { children: [(0, jsx_runtime_1.jsx)("h2", Object.assign({ className: styles.subTitle }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "thirdParty.title" }) })), (0, jsx_runtime_1.jsx)("p", Object.assign({ className: styles.content }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "thirdParty.content", values: {
                                    i: (chunk) => (0, jsx_runtime_1.jsx)("span", Object.assign({ style: { fontStyle: 'italic' } }, { children: chunk })),
                                } }) }))] })), (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: styles.section }, { children: [(0, jsx_runtime_1.jsx)("h2", Object.assign({ className: styles.subTitle }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "jurisdiction.title" }) })), (0, jsx_runtime_1.jsx)("p", Object.assign({ className: styles.content }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "jurisdiction.content", values: {
                                    i: (chunk) => (0, jsx_runtime_1.jsx)("span", Object.assign({ style: { fontStyle: 'italic' } }, { children: chunk })),
                                } }) }))] })), (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: styles.section }, { children: [(0, jsx_runtime_1.jsx)("h2", Object.assign({ className: styles.subTitle }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "updates.title" }) })), (0, jsx_runtime_1.jsx)("p", Object.assign({ className: styles.content }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "updates.content", values: {
                                    i: (chunk) => (0, jsx_runtime_1.jsx)("span", Object.assign({ style: { fontStyle: 'italic' } }, { children: chunk })),
                                } }) }))] })), (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: styles.section }, { children: [(0, jsx_runtime_1.jsx)("h2", Object.assign({ className: styles.subTitle }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "contact.title" }) })), (0, jsx_runtime_1.jsx)("p", Object.assign({ className: styles.content }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "contact.content", values: {
                                    email: (chunks) => ((0, jsx_runtime_1.jsx)("a", Object.assign({ href: `mailto:${chunks}`, style: { textDecoration: 'underline' } }, { children: chunks }))),
                                } }) }))] }))] })) })));
};
exports.PrivacyPolicy = PrivacyPolicy;
