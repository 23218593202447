"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cardsStyles = void 0;
const Utils_1 = require("../../../../utils/Utils");
const color_1 = require("../../../typings/color");
const spacing_1 = require("../spacing");
const shadows_1 = require("../shadows");
const _rootPartial = Object.assign(Object.assign({}, shadows_1.semanticShadowValues.$shadow100), { alignItems: 'flex-start', backgroundColor: color_1.paletteColorValues.$primary300, border: `1px solid ${color_1.paletteColorValues.$primary300}`, borderRadius: '4px', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: spacing_1.spacingValues.$s4 });
exports.cardsStyles = {
    default: (0, Utils_1.constructVariantStyles)([_rootPartial]),
};
