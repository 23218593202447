"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getToggleGroupStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
const theme_1 = require("../../../themes/typings/theme");
const StyleUtils_1 = require("../../../utils/StyleUtils");
exports.getToggleGroupStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    container: Object.assign(Object.assign({}, (0, StyleUtils_1.flexMixin)('row')), { backgroundColor: theme.colors.$background100, border: `1px solid ${theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$primary300 : theme.colors.$primary200}`, borderRadius: '4px', gap: '2px', padding: '2px', width: 'fit-content', '&$disabled': {
            borderColor: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$neutral600 : theme.colors.$interactivePrimaryDisabled,
            cursor: 'not-allowed',
        } }),
    disabled: {},
}), {
    name: 'Blueprint-ToggleGroup',
});
