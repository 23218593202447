"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Divider = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const Utils_1 = require("../../utils/Utils");
const Divider_styles_1 = require("./styles/Divider.styles");
const __DEV__ = process.env.NODE_ENV !== 'production';
const Divider = (props) => {
    const theme = (0, ThemeProvider_1.useTheme)();
    if (!theme) {
        if (__DEV__) {
            console.error('`Divider` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const { color = theme.colors.$secondary300, height = 2, localClassName = 'Divider-local', className = '', testId, } = props;
    const styles = (0, Divider_styles_1.getDividerStyles)();
    const dti = (0, Utils_1.getTestIdAttribute)(testId);
    return ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: `${localClassName} ${styles.dividerContainer} ${className}` }, dti, { children: (0, jsx_runtime_1.jsx)("div", { className: styles.divider, style: { backgroundColor: color, height } }) })));
};
exports.Divider = Divider;
