"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListIcon = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Utils_1 = require("../../utils/Utils");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
/* eslint-disable max-len */
const __DEV__ = process.env.NODE_ENV !== 'production';
const ListIcon = (props) => {
    const theme = (0, ThemeProvider_1.useTheme)();
    if (!theme) {
        if (__DEV__) {
            console.error('`ListIcon` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const { className, testId, fill = theme.colors.$primary500 } = props;
    return ((0, jsx_runtime_1.jsx)("svg", Object.assign({ className: className, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, (0, Utils_1.getTestIdAttribute)(testId), { children: (0, jsx_runtime_1.jsx)("path", { d: "M3 8C3 8.55228 3.44772 9 4 9H16C16.5523 9 17 8.55228 17 8C17 7.44772 16.5523 7 16 7H4C3.44772 7 3 7.44772 3 8ZM3 12C3 12.5523 3.44772 13 4 13H16C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11H4C3.44772 11 3 11.4477 3 12ZM3 16C3 16.5523 3.44772 17 4 17H16C16.5523 17 17 16.5523 17 16C17 15.4477 16.5523 15 16 15H4C3.44772 15 3 15.4477 3 16ZM19 16C19 16.5523 19.4477 17 20 17C20.5523 17 21 16.5523 21 16C21 15.4477 20.5523 15 20 15C19.4477 15 19 15.4477 19 16ZM20 7C19.4477 7 19 7.44772 19 8C19 8.55228 19.4477 9 20 9C20.5523 9 21 8.55228 21 8C21 7.44772 20.5523 7 20 7ZM19 12C19 12.5523 19.4477 13 20 13C20.5523 13 21 12.5523 21 12C21 11.4477 20.5523 11 20 11C19.4477 11 19 11.4477 19 12Z", fill: fill }) })));
};
exports.ListIcon = ListIcon;
