"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNavDropdownStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
const theme_1 = require("../../../themes/typings/theme");
exports.getNavDropdownStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    dropdownContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    navItem: {
        width: '100%',
        height: '100%',
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
    },
    dropdownItem: Object.assign(Object.assign({ display: 'flex', alignItems: 'center', justifyContent: 'center' }, theme.variants.links.nav), { padding: '$s0' }),
    dropdownWrapper: {
        width: '100%',
        position: 'relative',
    },
    dropdownContentContainer: Object.assign({ display: 'flex', flexDirection: 'column', width: '100%', margin: '0 auto', justifyContent: 'space-evenly', alignItems: 'center', position: 'absolute', zIndex: '1000', backgroundColor: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$background300 : theme.colors.$background200, borderRadius: 0, borderStyle: 'solid', borderWidth: 0 }, theme.shadows.$shadow100),
    activeNav: {
        '&::after': {
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            content: '""',
            backgroundColor: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$interactiveLinkDefault : theme.colors.$secondary300,
            width: '100%',
            height: '4px',
        },
    },
    dropdownIcon: {
        width: '20px',
        height: '20px',
        marginLeft: theme.spacing.$s1,
        '& path': {
            fill: theme.colors.$content100,
        },
    },
}), { name: 'Blueprint-NavDropdown' });
