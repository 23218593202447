"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.labelStyles = void 0;
const Utils_1 = require("../../../../utils/Utils");
const typography_1 = require("../typography");
const _rootPartial = {
    $label: Object.assign(Object.assign({}, typography_1.typographyValues.$textSecondary000), { backgroundColor: 'transparent', color: '$neutral200', margin: 0, padding: 0, width: '100%' }),
};
exports.labelStyles = {
    default: (0, Utils_1.constructVariantStyles)([_rootPartial]),
};
