import React from 'react';

import { AppProps } from './components/app/App';
import { AuthProps } from './pages/auth/Auth';
import { ErrorPageProps } from './pages/errorpage/ErrorPage';
import { HomeProps } from './pages/home/Home';
/**
 *
 * STANDARD COMPONENTS
 *
 * */
import Loading, { LoadingProps } from './components/loading/Loading';
/**
 *
 * ASYNC / REACT LAZY COMPONENT
 *
 * */
/** COMPONENTS */
const App = import(/* webpackChunkName: "App" */ './components/app/App');

/** PAGES */
const Auth = import(/* webpackChunkName: "Auth" */ './pages/auth/Auth');
const ErrorPage = import(/* webpackChunkName: "ErrorPage" */ './pages/errorpage/ErrorPage');
const Home = import(/* webpackChunkName: "Home" */ './pages/home/Home');

type ComponentClassAsync<T> = Promise<{ default: React.ComponentClass<T> }>;

export class ComponentFactory {
  static get<T>(component: any): new () => React.Component<T> {
    return component;
  }

  static getAsync<T>(component: any): ComponentClassAsync<T> {
    return component;
  }

  static get AppAsync() {
    return ComponentFactory.getAsync<AppProps>(App);
  }

  static get AuthAsync() {
    return ComponentFactory.getAsync<AuthProps>(Auth);
  }

  static get ErrorPageAsync() {
    return ComponentFactory.getAsync<ErrorPageProps>(ErrorPage);
  }

  static get HomeAsync() {
    return ComponentFactory.getAsync<HomeProps>(Home);
  }

  static get Loading() {
    return ComponentFactory.get<LoadingProps>(Loading);
  }
}
