"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GromaCoinNumber = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const scaffold_1 = require("@groma-dev/scaffold");
const Icon_1 = require("../Icon/Icon");
const logos_1 = require("../../assets/logos");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const theme_1 = require("../../themes/typings/theme");
const StyleUtils_1 = require("../../utils/StyleUtils");
const Utils_1 = require("../../utils/Utils");
const GromaCoinNumber_styles_1 = require("./styles/GromaCoinNumber.styles");
const __DEV__ = process.env.NODE_ENV !== 'production';
const GromaCoinNumber = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const { amount, withGroups = true, showDecimals = true, iconSize = '32px', id, testId, className = '' } = props;
    const theme = (0, ThemeProvider_1.useTheme)();
    if (!theme) {
        if (__DEV__) {
            console.error('`GromaCoinNumber` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const styles = (0, GromaCoinNumber_styles_1.getGromaCoinNumberStyles)();
    const dti = (0, Utils_1.getTestIdAttribute)(testId);
    const [wholeValue, fractionalValue] = amount.split('.');
    const fractionalChunks = fractionalValue.match(/.{1,3}/g);
    const locale = (0, scaffold_1.getBrowserLocale)((_b = (_a = global === null || global === void 0 ? void 0 : global.window) === null || _a === void 0 ? void 0 : _a.navigator.language) !== null && _b !== void 0 ? _b : scaffold_1.LocaleCode.en_us);
    const parts = new Intl.NumberFormat(locale).formatToParts(parseFloat(amount));
    const decimalCharacter = (_d = (_c = parts.find((num) => num.type === 'decimal')) === null || _c === void 0 ? void 0 : _c.value) !== null && _d !== void 0 ? _d : '.';
    const groupCharacter = (_f = (_e = parts.find((num) => num.type === 'group')) === null || _e === void 0 ? void 0 : _e.value) !== null && _f !== void 0 ? _f : ',';
    const renderFractional = () => {
        if (fractionalChunks) {
            return ((0, jsx_runtime_1.jsxs)("div", Object.assign({ className: (0, StyleUtils_1.classNames)('GromaCoinNumber-fractional-local', className, styles.fractional) }, (0, Utils_1.getTestIdAttribute)('gromaCoinNumberFractional'), { children: [(0, jsx_runtime_1.jsxs)("span", Object.assign({ className: (0, StyleUtils_1.classNames)('GromaCoinNumber-fractionalDigits-local') }, (0, Utils_1.getTestIdAttribute)('gromaCoinNumberFractionalDigit'), { children: [(0, jsx_runtime_1.jsx)("span", Object.assign({ className: (0, StyleUtils_1.classNames)('GromaCoinNumber-fractionalBoldDigits-local', styles.fractionalBoldDigits) }, (0, Utils_1.getTestIdAttribute)('gromaCoinNumberFractionalBoldDigit'), { children: fractionalChunks[0].substring(0, 2) })), fractionalChunks[0].substring(2), withGroups && groupCharacter, fractionalChunks[1], withGroups && groupCharacter] })), (0, jsx_runtime_1.jsxs)("span", Object.assign({ className: (0, StyleUtils_1.classNames)('GromaCoinNumber-fractionalDigits-local') }, (0, Utils_1.getTestIdAttribute)('gromaCoinNumberFractionalDigit'), { children: [fractionalChunks[2], withGroups && groupCharacter, fractionalChunks[3], withGroups && groupCharacter] })), (0, jsx_runtime_1.jsxs)("span", Object.assign({ className: (0, StyleUtils_1.classNames)('GromaCoinNumber-fractionalDigits-local') }, (0, Utils_1.getTestIdAttribute)('gromaCoinNumberFractionalDigit'), { children: [fractionalChunks[4], withGroups && groupCharacter, fractionalChunks[5]] }))] })));
        }
    };
    return ((0, jsx_runtime_1.jsxs)("span", Object.assign({ id: id, className: (0, StyleUtils_1.classNames)('GromaCoinNumber-local', styles.numberContainer) }, dti, { children: [(0, jsx_runtime_1.jsx)(Icon_1.Icon, { component: theme.__base === theme_1.GlobalBaseTheme.DARK ? logos_1.MarkLightLogo : logos_1.MarkMediumLogo, size: iconSize, className: (0, StyleUtils_1.classNames)('GromaCoinNumber-icon-local', styles.icon), testId: "gromaCoinNumberIcon" }), (0, jsx_runtime_1.jsx)("span", Object.assign({ className: (0, StyleUtils_1.classNames)('GromaCoinNumber-whole-local', styles.whole) }, (0, Utils_1.getTestIdAttribute)('gromaCoinNumberWhole'), { children: `${(0, scaffold_1.formatNumber)(wholeValue)}${showDecimals ? decimalCharacter : ''}` })), showDecimals && renderFractional()] })));
};
exports.GromaCoinNumber = GromaCoinNumber;
