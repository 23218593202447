import { BrowserLanguage, LocaleCode } from '@groma-dev/scaffold';
import { AppConfigInterface } from './AppConfigInterface';
import { locales } from './locales';

export const AppConfig: AppConfigInterface = {
  Settings: {
    Authentication: {
      enabled: false,
      cookie: {
        name: process.env.AUTH_COOKIE_NAME ?? '',
        domain: process.env.AUTH_COOKIE_DOMAIN ?? '',
      },
    },
    Domains: {
      holdings: {
        baseUrl: process.env.HOLDINGS_BASE_URL ?? '',
      },
      id: {
        baseUrl: process.env.ID_BASE_URL ?? '',
      },
      investors: {
        baseUrl: process.env.INVESTORS_BASE_URL ?? '',
      },
      landing: {
        baseUrl: process.env.LANDING_BASE_URL ?? '',
      },
      tenants: {
        baseUrl: process.env.TENANTS_BASE_URL ?? '',
      },
    },
    Localization: {
      defaultLocale: LocaleCode.en_us,
      browserLanguage: BrowserLanguage.en,
      locales,
    },
    Metrics: {
      Firebase: {
        enabled: process.env.FIREBASE_ENABLED === 'true',
        config: {
          apiKey: process.env.FIREBASE_API_KEY ?? '',
          authDomain: process.env.FIREBASE_AUTH_DOMAIN ?? '',
          projectId: process.env.FIREBASE_PROJECT_ID ?? '',
          storageBucket: process.env.FIREBASE_STORAGE_BUCKET ?? '',
          messagingSenderId: process.env.FIREBASE_SENDER_ID ?? '',
          appId: process.env.FIREBASE_APP_ID ?? '',
          measurementId: process.env.FIREBASE_MEASUREMENT_ID ?? '',
        },
      },
      NewRelic: {
        enabled: process.env.NEWRELIC_ENABLED === 'true',
        config: {
          accountID: process.env.NEWRELIC_ACCOUNT_ID ?? '',
          trustKey: process.env.NEWRELIC_TRUST_ID ?? '',
          agentID: process.env.NEWRELIC_AGENT_ID ?? '',
          licenseKey: process.env.NEWRELIC_LICENSE_KEY ?? '',
          applicationID: process.env.NEWRELIC_APPLICATION_ID ?? '',
        },
      },
    },
    Routing: {
      searchParams: {
        allowList: [],
      },
    },
    Seo: {
      enabled: process.env.SEO_ENABLED === 'true',
    },
    Server: {
      apiVersion: 'v1',
      apiKey: process.env.BASE_API_KEY ?? '',
      baseUrl: process.env.BASE_API_URL ?? '',
      timeout: 30000,
      passwordProtection: {
        cookieName: process.env.APP_PROTECTION_COOKIE ?? '',
        enabled: process.env.APP_PROTECTION_ENABLED === 'true',
        password: process.env.APP_PROTECTION_PASSWORD ?? '',
        protectionKey: process.env.APP_PROTECTION_KEY ?? '',
      },
    },
    ServiceWorker: {
      enabled: true,
      options: {
        forceUpdateRefresh: false,
      },
    },
  },
  Pages: {},
  Components: {
    Footer: {
      enabled: true,
      subFooter: {
        enabled: true,
      },
      auxiliaryFooter: {
        enabled: false,
      },
    },
  },
};
