"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvestIcon = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Utils_1 = require("../../utils/Utils");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const __DEV__ = process.env.NODE_ENV !== 'production';
const InvestIcon = (props) => {
    const theme = (0, ThemeProvider_1.useTheme)();
    if (!theme) {
        if (__DEV__) {
            console.error('`InvestIcon` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const { className, testId, fill = theme.colors.$primary500 } = props;
    return ((0, jsx_runtime_1.jsx)("svg", Object.assign({ className: className, viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, (0, Utils_1.getTestIdAttribute)(testId), { children: (0, jsx_runtime_1.jsx)("path", { d: "M3.2 4.4H2V12.8C2 13.46 2.54 14 3.2 14H11.6V12.8H3.2V4.4ZM12.8 2H5.6C4.94 2 4.4 2.54 4.4 3.2V10.4C4.4 11.06 4.94 11.6 5.6 11.6H12.8C13.46 11.6 14 11.06 14 10.4V3.2C14 2.54 13.46 2 12.8 2ZM12.8 10.4H5.6V3.2H12.8V10.4ZM8.6 9.2H9.8V7.4H11.6V6.2H9.8V4.4H8.6V6.2H6.8V7.4H8.6V9.2Z", fill: fill }) })));
};
exports.InvestIcon = InvestIcon;
