"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoundMark = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Utils_1 = require("../../utils/Utils");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const __DEV__ = process.env.NODE_ENV !== 'production';
const RoundMark = (props) => {
    const theme = (0, ThemeProvider_1.useTheme)();
    if (!theme) {
        if (__DEV__) {
            console.error('`RoundMark` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const { background = theme.colors.$primary300, className, fill = theme.colors.$neutral100, testId } = props;
    return ((0, jsx_runtime_1.jsxs)("svg", Object.assign({ className: className, width: "210", height: "210", viewBox: "0 0 210 210", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, (0, Utils_1.getTestIdAttribute)(testId), { children: [(0, jsx_runtime_1.jsx)("circle", { cx: "105", cy: "105", r: "105", fill: background }), (0, jsx_runtime_1.jsx)("path", { d: "M145.788 73.6529L108.973 36.1508L106.741 33.8762L100.063 36.1508L63.2527 73.6529V81.6894L93.5875 59.7607C93.5875 59.7607 97.4652 57.7099 97.4652 72.6951V137.805C97.3507 150.375 94.082 148.589 94.082 148.589L63.2215 126.333L63.2579 134.166L104.508 176.124L111.571 169.061V72.6951C111.571 57.7203 115.443 59.7555 115.449 59.7607L145.788 81.6894V73.6529Z", fill: fill })] })));
};
exports.RoundMark = RoundMark;
