"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrowserLanguage = exports.LocaleCode = exports.ParamAllowList = void 0;
exports.ParamAllowList = ['theme'];
var LocaleCode;
(function (LocaleCode) {
    LocaleCode["en_us"] = "en-us";
    LocaleCode["es_es"] = "es-es";
})(LocaleCode = exports.LocaleCode || (exports.LocaleCode = {}));
var BrowserLanguage;
(function (BrowserLanguage) {
    BrowserLanguage["en"] = "en";
    BrowserLanguage["es"] = "es";
})(BrowserLanguage = exports.BrowserLanguage || (exports.BrowserLanguage = {}));
