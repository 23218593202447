"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLabelStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
exports.getLabelStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    hidden: {
        position: 'absolute',
        left: '-10000px',
        top: 'auto',
        width: '1px',
        height: '1px',
        overflow: 'hidden',
    },
    default: Object.assign({}, theme.variants.labels.default.$label),
}), { name: 'Blueprint-Label' });
