"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Slide = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const Utils_1 = require("../../utils/Utils");
const Carousel_1 = require("./Carousel");
const Slide_styles_1 = require("./styles/Slide.styles");
const __DEV__ = process.env.NODE_ENV !== 'production';
const Slide = (props) => {
    const { children, className = '', testId, isActiveSlide } = props, args = __rest(props, ["children", "className", "testId", "isActiveSlide"]);
    const { 'data-index': dataIndex } = args;
    const carousel = (0, Carousel_1.useCarousel)();
    const slideRef = (0, react_1.createRef)();
    const [isActive, setIsActive] = (0, react_1.useState)(false);
    if (!carousel) {
        if (__DEV__) {
            console.error('`Carousel.Slide` component must be a child of a the `Carousel` component.');
        }
        return null;
    }
    const styles = (0, Slide_styles_1.getSlideStyles)();
    const dti = (0, Utils_1.getTestIdAttribute)(testId);
    const styleObj = {
        width: `${carousel.carouselWidth}px`,
        minWidth: `${carousel.carouselWidth}px`,
        maxWidth: `${carousel.carouselWidth}px`,
    };
    const handleActiveSlide = () => {
        // eslint-disable-next-line react/prop-types
        const active = carousel.isActiveSlide(parseInt(props['data-index']));
        if (isActiveSlide)
            isActiveSlide(active);
        setIsActive(active);
    };
    (0, react_1.useEffect)(() => {
        handleActiveSlide();
    }, [carousel]);
    return ((0, jsx_runtime_1.jsx)("div", Object.assign({ role: "option", ref: slideRef, className: `Carousel-slide-local ${styles.slide} ${className}`, style: styleObj, "data-index": dataIndex, "aria-selected": isActive ? true : false }, dti, { children: children })));
};
exports.Slide = Slide;
