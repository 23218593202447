"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FooterSectionItem = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Link_1 = require("../Link/Link");
const Utils_1 = require("../../utils/Utils");
const FooterSectionItem_styles_1 = require("./styles/FooterSectionItem.styles");
const FooterSectionItem = (props) => {
    const { item, domains } = props;
    const styles = (0, FooterSectionItem_styles_1.getFooterSectionItemStyle)();
    if (item.render)
        return item.render(props);
    return ((0, jsx_runtime_1.jsx)("li", { children: (0, jsx_runtime_1.jsx)(Link_1.Link, Object.assign({ id: item.id, href: `${item.experience ? (0, Utils_1.getDomainBasedOnExperience)(domains, item.experience) : ''}${item.href}`, variant: Link_1.LinkVariants.NAV, className: styles.link, target: item.linkTarget ? item.linkTarget : '_self', useRouter: false }, { children: item.label })) }));
};
exports.FooterSectionItem = FooterSectionItem;
