"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RadioGroup = exports.useRadioGroup = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const StyleUtils_1 = require("../../utils/StyleUtils");
const Utils_1 = require("../../utils/Utils");
const RadioGroup_styles_1 = require("./styles/RadioGroup.styles");
const __DEV__ = process.env.NODE_ENV !== 'production';
const RadioGroupContext = (0, react_1.createContext)({
    name: undefined,
    selectedValue: undefined,
    onChange: undefined,
});
const useRadioGroup = () => (0, react_1.useContext)(RadioGroupContext);
exports.useRadioGroup = useRadioGroup;
const RadioGroup = (props) => {
    const { children, className = '', id, name, testId, defaultValue, onChange } = props;
    const theme = (0, ThemeProvider_1.useTheme)();
    const dti = (0, Utils_1.getTestIdAttribute)(testId);
    if (!theme) {
        if (__DEV__) {
            console.error('`RadioGroup` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const [selectedValue, setSelectedValue] = (0, react_1.useState)(defaultValue !== null && defaultValue !== void 0 ? defaultValue : '');
    const styles = (0, RadioGroup_styles_1.getRadioGroupStyles)();
    const handleChange = (e) => {
        if (onChange)
            onChange(e);
        setSelectedValue(e.target.value);
    };
    const memoValue = (0, react_1.useMemo)(() => ({
        name,
        selectedValue,
        onChange: handleChange,
    }), [name, defaultValue, selectedValue]);
    return ((0, jsx_runtime_1.jsx)(RadioGroupContext.Provider, Object.assign({ value: memoValue }, { children: (0, jsx_runtime_1.jsx)("div", Object.assign({ id: id, className: (0, StyleUtils_1.classNames)('RadioGroup-local', styles.container, className) }, dti, { children: children })) })));
};
exports.RadioGroup = RadioGroup;
