"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModalProvider = exports.useModals = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = __importStar(require("react"));
const Utils_1 = require("../../utils/Utils");
const StyleUtils_1 = require("../../utils/StyleUtils");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const ModalContext = (0, react_1.createContext)({
    addModal: () => { },
    removeModal: () => { },
    removeAll: () => { },
});
const useModals = () => (0, react_1.useContext)(ModalContext);
exports.useModals = useModals;
// modals lose state when rendering if this is assigned in state.
let focusableElements = [];
let prevFocusedElement;
const ModalProvider = (props) => {
    const { children } = props;
    const [modals, setModals] = (0, react_1.useState)({});
    const { pathname } = (0, Utils_1.parseLocation)((0, ThemeProvider_1.useThemeRouter)().router);
    (0, react_1.useEffect)(() => {
        removeAll();
    }, [pathname]);
    const setBodyOverflow = (style) => {
        document.body.style.overflow = style;
    };
    const addModal = (id, content) => {
        const elements = (0, StyleUtils_1.getFocusableElements)();
        const { activeElement } = document;
        if (elements) {
            (0, StyleUtils_1.setFocusableOnElement)(elements, -1);
            focusableElements = elements;
            setBodyOverflow('hidden');
        }
        if (activeElement)
            prevFocusedElement = activeElement;
        const updatedModals = Object.assign(Object.assign({}, modals), { [id]: content });
        setModals(updatedModals);
    };
    const removeModal = (id) => {
        (0, StyleUtils_1.setFocusableOnElement)(focusableElements, 0);
        focusableElements = [];
        setBodyOverflow('auto');
        if (prevFocusedElement) {
            prevFocusedElement.focus();
            prevFocusedElement = undefined;
        }
        const updatedModals = Object.assign({}, modals);
        delete updatedModals[id];
        setModals(updatedModals);
    };
    const removeAll = () => {
        if (focusableElements) {
            (0, StyleUtils_1.setFocusableOnElement)(focusableElements, 0);
            focusableElements = [];
            setBodyOverflow('auto');
        }
        if (prevFocusedElement) {
            prevFocusedElement.focus();
            prevFocusedElement = undefined;
        }
        setModals({});
    };
    const value = (0, react_1.useMemo)(() => ({
        addModal,
        removeModal,
        removeAll,
    }), [modals]);
    return ((0, jsx_runtime_1.jsxs)(ModalContext.Provider, Object.assign({ value: value }, { children: [children, Object.keys(modals).map(key => {
                return (0, jsx_runtime_1.jsx)(react_1.default.Fragment, { children: modals[key] }, key);
            })] })));
};
exports.ModalProvider = ModalProvider;
