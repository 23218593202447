import React, { Suspense } from 'react';
import {
  BlueprintProvider,
  NewRelic,
  GlobalBaseTheme,
  globalDarkTheme,
  globalLightTheme,
  SCSSThemeProvider,
  BaseTheme,
  BaseThemeDark,
} from '@groma-dev/blueprint';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import { getBrowserLocale, parseLocationSearch } from '@groma-dev/scaffold';
import { AppConfig } from '../core/AppConfig';
import DataStore from '../core/stores/DataStore';
import { defaultLocaleMessages } from '../core/locales';
import AppStore from './stores/AppStore';
import StoresProvider from './providers/StoresProvider';
import { ComponentFactory } from './ComponentFactory';

const App = React.lazy(() => ComponentFactory.AppAsync);

export interface AppProps {}

const initializeStores = () => {
  const dataStore = new DataStore();
  const appStore = new AppStore();

  return { dataStore, appStore };
};

const AppLoader = (): any => {
  const { appStore, dataStore } = initializeStores();
  const { Localization, Metrics } = AppConfig.Settings;
  const { defaultLocale, locales } = Localization;
  const { theme } = parseLocationSearch(window.location.search);
  const language = getBrowserLocale(navigator.language);
  const currentLocale: { [key: string]: string } = locales[language];
  const isLightTheme = theme === GlobalBaseTheme.LIGHT;
  if (Metrics.Firebase.enabled) {
    appStore.initializeFirebase(Metrics.Firebase.config);
  }

  if (Metrics.NewRelic.enabled) {
    const newRelic = new NewRelic();
    newRelic.initializeNewRelic(Metrics.NewRelic.config);
  }

  return (
    <BrowserRouter>
      <StoresProvider appStore={appStore} dataStore={dataStore}>
        <IntlProvider locale={language} key={language} messages={currentLocale} defaultLocale={defaultLocale}>
          <BlueprintProvider
            cssReset
            theme={isLightTheme ? globalLightTheme : globalDarkTheme}
            defineMessages={defaultLocaleMessages}>
            <SCSSThemeProvider theme={isLightTheme ? BaseTheme : BaseThemeDark}>
              <Suspense fallback={<ComponentFactory.Loading isPage />}>
                <App />
              </Suspense>
            </SCSSThemeProvider>
          </BlueprintProvider>
        </IntlProvider>
      </StoresProvider>
    </BrowserRouter>
  );
};

export default AppLoader;
