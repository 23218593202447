"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSubMenuStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
exports.getSubMenuStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    subMenuContainer: {
        backgroundColor: theme.colors.$background100,
        height: '100%',
        position: 'absolute',
        top: 0,
        right: 0,
        transition: 'all ease-in .3s',
        width: '0',
        overflow: 'auto',
    },
    openSubMenu: {
        width: '100%',
    },
    header: {
        width: '100%',
        padding: '8px 24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        borderBottom: `1px solid ${theme.colors.$content100}`,
    },
    backButton: Object.assign(Object.assign({}, theme.typography.$textSecondary200), { backgroundColor: 'transparent', padding: '16px', width: '100%', display: 'flex', justifyContent: 'start', alignItems: 'center', color: theme.colors.$secondary300, textDecoration: 'underline', '&:hover': {
            cursor: 'pointer',
        } }),
    backIcon: {
        width: '24px',
        height: '24px',
        paddingRight: '10px',
        '& path': {
            fill: theme.colors.$secondary300,
        },
    },
}), { name: 'Blueprint-SubMenu' });
