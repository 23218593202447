"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableBody = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const Utils_1 = require("../../utils/Utils");
const TableBody_styles_1 = require("./styles/TableBody.styles");
const Table_1 = require("./Table");
const __DEV__ = process.env.NODE_ENV !== 'production';
const TableBody = (props) => {
    const { children, className = '', testId = 'tableBody' } = props;
    const theme = (0, ThemeProvider_1.useTheme)();
    const table = (0, Table_1.useTable)();
    const styles = (0, TableBody_styles_1.getTableBodyStyles)();
    const dti = (0, Utils_1.getTestIdAttribute)(testId);
    if (!theme) {
        if (__DEV__) {
            console.error('`TableBody` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    if (!table) {
        if (__DEV__) {
            console.error('`TableBody` component must be a child of a `Table` component');
        }
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(Table_1.TableSubProvider, Object.assign({ variant: Table_1.TableVariantType.BODY }, { children: (0, jsx_runtime_1.jsx)("tbody", Object.assign({ className: `TableBody-local ${styles.container} ${className}` }, dti, { children: children })) })));
};
exports.TableBody = TableBody;
