"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubMenuItem = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Link_1 = require("../Link/Link");
const icons_1 = require("../../assets/icons");
const StyleUtils_1 = require("../../utils/StyleUtils");
const Utils_1 = require("../../utils/Utils");
const SubMenuItem_styles_1 = require("./styles/SubMenuItem.styles");
const SubMenuItem = (props) => {
    const { navItem, tabIndex, subMenuOpen, handleOnClose, domains } = props;
    const styles = (0, SubMenuItem_styles_1.getSubMenuItemStyles)();
    if (navItem === null || navItem === void 0 ? void 0 : navItem.render)
        return navItem.render(props);
    return ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: `SubMenuItem-subMenuItem-local ${styles.subMenuItem}` }, { children: (0, jsx_runtime_1.jsx)("li", { children: (0, jsx_runtime_1.jsxs)(Link_1.Link, Object.assign({ tabIndex: tabIndex, id: navItem.id, href: `${navItem.experience ? (0, Utils_1.getDomainBasedOnExperience)(domains, navItem.experience) : ''}${navItem.href}`, className: (0, StyleUtils_1.classNames)(styles.link, navItem.disabled && styles.disabled), variant: Link_1.LinkVariants.NAV, disabled: navItem.disabled, onClick: (e) => {
                    if (navItem.disabled)
                        e.preventDefault();
                    else
                        handleOnClose(true);
                }, fullWidth: true, useRouter: !navItem.isExternal, target: navItem.openNewTab ? '_blank' : '_self' }, { children: [navItem === null || navItem === void 0 ? void 0 : navItem.label, subMenuOpen && ((0, jsx_runtime_1.jsx)(icons_1.ArrowRightIcon, { className: (0, StyleUtils_1.classNames)(styles.rightIcon, navItem.disabled && styles.disabled) }))] })) }) })));
};
exports.SubMenuItem = SubMenuItem;
