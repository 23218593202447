"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CopyIcon = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Utils_1 = require("../../utils/Utils");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const __DEV__ = process.env.NODE_ENV !== 'production';
const CopyIcon = (props) => {
    const theme = (0, ThemeProvider_1.useTheme)();
    if (!theme) {
        if (__DEV__) {
            console.error('`CopyIcon` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const { className, testId, fill = theme.colors.$primary500 } = props;
    return ((0, jsx_runtime_1.jsx)("svg", Object.assign({ className: className, viewBox: "0 0 14 14", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, (0, Utils_1.getTestIdAttribute)(testId), { children: (0, jsx_runtime_1.jsx)("path", { d: "M4.66667 0.583374H11.6667C12.3083 0.583374 12.8333 1.10837 12.8333 1.75004V9.91671H11.6667V1.75004H4.66667V0.583374ZM2.91667 2.91671H9.33334C9.975 2.91671 10.5 3.44171 10.5 4.08337V12.25C10.5 12.8917 9.975 13.4167 9.33334 13.4167H2.91667C2.275 13.4167 1.75 12.8917 1.75 12.25V4.08337C1.75 3.44171 2.275 2.91671 2.91667 2.91671ZM2.91667 12.25H9.33334V4.08337H2.91667V12.25Z", fill: fill }) })));
};
exports.CopyIcon = CopyIcon;
