"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseThemeDark = exports.BaseTheme = void 0;
const color_1 = require("./typings/color");
const theme_1 = require("./typings/theme");
const colorPalette = {
    // COLOR PALETTE
    primary100: color_1.paletteColorValues.$primary100,
    primary200: color_1.paletteColorValues.$primary200,
    primary300: color_1.paletteColorValues.$primary300,
    primary400: color_1.paletteColorValues.$primary400,
    primary500: color_1.paletteColorValues.$primary500,
    secondary100: color_1.paletteColorValues.$secondary100,
    secondary200: color_1.paletteColorValues.$secondary200,
    secondary300: color_1.paletteColorValues.$secondary300,
    secondary400: color_1.paletteColorValues.$secondary400,
    secondary500: color_1.paletteColorValues.$secondary500,
    tertiary100: color_1.paletteColorValues.$tertiary100,
    tertiary200: color_1.paletteColorValues.$tertiary200,
    tertiary300: color_1.paletteColorValues.$tertiary300,
    tertiary400: color_1.paletteColorValues.$tertiary400,
    tertiary500: color_1.paletteColorValues.$tertiary500,
    black: color_1.paletteColorValues.$black,
    neutral100: color_1.paletteColorValues.$neutral100,
    neutral200: color_1.paletteColorValues.$neutral200,
    neutral300: color_1.paletteColorValues.$neutral300,
    neutral400: color_1.paletteColorValues.$neutral400,
    neutral500: color_1.paletteColorValues.$neutral500,
    neutral600: color_1.paletteColorValues.$neutral600,
    white: color_1.paletteColorValues.$white,
    success100: color_1.paletteColorValues.$success100,
    success200: color_1.paletteColorValues.$success200,
    success300: color_1.paletteColorValues.$success300,
    success400: color_1.paletteColorValues.$success400,
    success500: color_1.paletteColorValues.$success500,
    error100: color_1.paletteColorValues.$error100,
    error200: color_1.paletteColorValues.$error200,
    error300: color_1.paletteColorValues.$error300,
    error400: color_1.paletteColorValues.$error400,
    error500: color_1.paletteColorValues.$error500,
    accent100: color_1.paletteColorValues.$accent100,
    accent200: color_1.paletteColorValues.$accent200,
    accent300: color_1.paletteColorValues.$accent300,
};
const fonts = {
    primaryFont: 'Good Sans, Helvetica, san-serif',
    secondaryFont: 'IBM Plex, Helvetica, san-serif',
};
const shadows = {
    dropShadow100: '0 4px 20px 0 rgba(41, 56, 49, 0.15)',
    dropShadow200: '0 4px 4px 0 rgba(0, 0, 0, 0.25)',
    dropShadow300: '0 4px 20px 0 rgba(11, 16, 14, 0.8)',
    shadow100: '0 4px 20px 0 rgba(41, 56, 49, 0.15)',
    shadow200: '0 4px 20px 0 rgba(41, 56, 49, 0.15)',
};
const spacing = {
    s0: '0px',
    s1: '4px',
    s2: '8px',
    s3: '16px',
    s4: '24px',
    s5: '32px',
    s6: '48px',
    s7: '64px',
    s8: '80px',
};
exports.BaseTheme = {
    __baseTheme: theme_1.GlobalBaseTheme.LIGHT,
    colors: Object.assign(Object.assign({}, colorPalette), { 
        // SEMANTIC PALETTE
        actionIcon100: color_1.paletteColorValues.$primary300, background100: color_1.paletteColorValues.$tertiary100, background200: color_1.paletteColorValues.$tertiary100, background300: color_1.paletteColorValues.$primary300, content100: color_1.paletteColorValues.$primary300, content200: color_1.paletteColorValues.$neutral600, content300: color_1.paletteColorValues.$neutral100, inputPrimaryDefault: color_1.paletteColorValues.$primary300, inputPrimaryDisabled: color_1.paletteColorValues.$tertiary200, inputPrimaryError: color_1.paletteColorValues.$error400, inputPrimaryHover: color_1.paletteColorValues.$primary100, interactiveLinkDefault: color_1.paletteColorValues.$secondary300, interactiveLinkDisabled: color_1.paletteColorValues.$neutral400, interactiveLinkHover: color_1.paletteColorValues.$secondary200, interactiveLinkPressed: color_1.paletteColorValues.$secondary500, interactivePrimaryDefault: color_1.paletteColorValues.$primary300, interactivePrimaryDisabled: color_1.paletteColorValues.$neutral400, interactivePrimaryHover: color_1.paletteColorValues.$primary200, interactivePrimaryPressed: color_1.paletteColorValues.$primary400, staticIcon100: color_1.paletteColorValues.$primary300 }),
    fonts,
    shadows,
    spacing,
};
exports.BaseThemeDark = Object.assign(Object.assign({}, exports.BaseTheme), { __baseTheme: theme_1.GlobalBaseTheme.DARK, colors: Object.assign(Object.assign({}, exports.BaseTheme.colors), { 
        // SEMANTIC PALETTE
        actionIcon100: color_1.paletteColorValues.$tertiary100, background100: color_1.paletteColorValues.$primary500, background200: color_1.paletteColorValues.$primary400, background300: color_1.paletteColorValues.$primary300, content100: color_1.paletteColorValues.$neutral100, content200: color_1.paletteColorValues.$tertiary100, content300: color_1.paletteColorValues.$primary400, inputPrimaryDefault: color_1.paletteColorValues.$primary200, inputPrimaryDisabled: color_1.paletteColorValues.$primary500, inputPrimaryError: color_1.paletteColorValues.$error100, inputPrimaryHover: color_1.paletteColorValues.$primary100, interactiveLinkDefault: color_1.paletteColorValues.$secondary300, interactiveLinkDisabled: color_1.paletteColorValues.$neutral300, interactiveLinkHover: color_1.paletteColorValues.$secondary100, interactiveLinkPressed: color_1.paletteColorValues.$secondary200, interactivePrimaryDefault: color_1.paletteColorValues.$secondary300, interactivePrimaryDisabled: color_1.paletteColorValues.$neutral300, interactivePrimaryHover: color_1.paletteColorValues.$secondary100, interactivePrimaryPressed: color_1.paletteColorValues.$secondary200, staticIcon100: color_1.paletteColorValues.$tertiary100 }), shadows: Object.assign(Object.assign({}, exports.BaseTheme.shadows), { shadow100: exports.BaseTheme.shadows.dropShadow200, shadow200: exports.BaseTheme.shadows.dropShadow300 }) });
