"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToggleGroup = exports.useToggleGroup = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const StyleUtils_1 = require("../../utils/StyleUtils");
const Utils_1 = require("../../utils/Utils");
const ToggleGroup_styles_1 = require("./styles/ToggleGroup.styles");
const __DEV__ = process.env.NODE_ENV !== 'production';
const ToggleGroupContext = (0, react_1.createContext)(undefined);
const useToggleGroup = () => (0, react_1.useContext)(ToggleGroupContext);
exports.useToggleGroup = useToggleGroup;
const ToggleGroup = (props) => {
    const { children, className = '', defaultValues = [], disabled = false, exclusive = true, id, onChange, testId, } = props;
    const theme = (0, ThemeProvider_1.useTheme)();
    const dti = (0, Utils_1.getTestIdAttribute)(testId);
    if (!theme) {
        if (__DEV__) {
            console.error('`ToggleGroup` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const [initialized, setInitialized] = (0, react_1.useState)(false);
    const [selectedValues, setSelectedValues] = (0, react_1.useState)(defaultValues);
    const styles = (0, ToggleGroup_styles_1.getToggleGroupStyles)();
    const handleChange = (value) => {
        let newValues;
        if (exclusive) {
            newValues = [value];
        }
        else if (selectedValues.includes(value)) {
            newValues = selectedValues.filter(v => v !== value);
        }
        else {
            newValues = [...selectedValues, value];
        }
        setSelectedValues(newValues);
        if (onChange)
            onChange(newValues);
    };
    (0, react_1.useEffect)(() => {
        if (!initialized)
            setInitialized(true);
    }, []);
    const memoValue = (0, react_1.useMemo)(() => ({
        isDisabled: disabled,
        exclusive,
        values: selectedValues,
        onChange: handleChange,
    }), [defaultValues, disabled, selectedValues]);
    return ((0, jsx_runtime_1.jsx)(ToggleGroupContext.Provider, Object.assign({ value: memoValue }, { children: (0, jsx_runtime_1.jsx)("div", Object.assign({ id: id, role: "group", className: (0, StyleUtils_1.classNames)('ToggleGroup-local', className, styles.container, disabled && styles.disabled) }, dti, { children: children })) })));
};
exports.ToggleGroup = ToggleGroup;
