"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlueprintProvider = exports.useDefineMessages = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const ThemeProvider_1 = require("./ThemeProvider");
const Utils_1 = require("./Utils");
const DefineMessagesContext = (0, react_1.createContext)({});
const useDefineMessages = () => (0, react_1.useContext)(DefineMessagesContext);
exports.useDefineMessages = useDefineMessages;
const SProvider = ({ children, cssReset = false, defineMessages = {}, }) => {
    if (cssReset)
        (0, Utils_1.useCssReset)();
    (0, Utils_1.setGlobalFonts)();
    const value = (0, react_1.useMemo)(() => defineMessages, [defineMessages]);
    return (0, jsx_runtime_1.jsx)(DefineMessagesContext.Provider, Object.assign({ value: value }, { children: children }));
};
const BlueprintProvider = (props) => {
    const { theme, children, cssReset = false, router, defineMessages } = props;
    return ((0, jsx_runtime_1.jsx)(ThemeProvider_1.ThemeProvider, Object.assign({ theme: theme, router: router, themeId: "mainTheme" }, { children: (0, jsx_runtime_1.jsx)(SProvider, Object.assign({ cssReset: cssReset, defineMessages: defineMessages }, { children: children })) })));
};
exports.BlueprintProvider = BlueprintProvider;
