"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScrollTrigger = exports.Gsap = void 0;
/** GSAP library
 * Professional-grade JavaScript animation for the modern web
 * https://greensock.com/gsap/
 */
var gsap_1 = require("gsap");
Object.defineProperty(exports, "Gsap", { enumerable: true, get: function () { return gsap_1.gsap; } });
/** export from gsap/dist for workaround to bug:
 * `Cannot use import statement outside a module` */
var ScrollTrigger_1 = require("gsap/dist/ScrollTrigger");
Object.defineProperty(exports, "ScrollTrigger", { enumerable: true, get: function () { return ScrollTrigger_1.ScrollTrigger; } });
