"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNativeSelectStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
const StyleUtils_1 = require("../../../utils/StyleUtils");
exports.getNativeSelectStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    container: Object.assign(Object.assign({}, (0, StyleUtils_1.flexMixin)('column')), { width: '100%', zIndex: 1 }),
    wrapper: Object.assign(Object.assign({}, (0, StyleUtils_1.flexMixin)('row', 'normal', 'center')), { height: '100%', width: '100%' }),
    select: Object.assign(Object.assign({}, theme.typography.$textPrimary200), { '-moz-appearance': 'none' /* Firefox */, '-webkit-appearance': 'none' /* Safari and Chrome */, appearance: 'none', backgroundColor: 'transparent', height: '100%', width: '100%', '&:disabled': {
            cursor: 'not-allowed',
        } }),
}), {
    name: 'Blueprint-NativeSelect',
});
