"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuIcon = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Utils_1 = require("../../utils/Utils");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
/* eslint-disable max-len */
const __DEV__ = process.env.NODE_ENV !== 'production';
const MenuIcon = (props) => {
    const theme = (0, ThemeProvider_1.useTheme)();
    if (!theme) {
        if (__DEV__) {
            console.error('`MenuIcon` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const { className, testId, fill = theme.colors.$primary500 } = props;
    return ((0, jsx_runtime_1.jsx)("svg", Object.assign({ className: className, viewBox: "0 0 38 30", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, (0, Utils_1.getTestIdAttribute)(testId), { children: (0, jsx_runtime_1.jsx)("path", { d: "M0.5 19.1429C0.5 20.2869 1.42741 21.2143 2.57143 21.2143H35.4286C36.5726 21.2143 37.5 20.2869 37.5 19.1429C37.5 17.9988 36.5726 17.0714 35.4286 17.0714H2.57143C1.42741 17.0714 0.5 17.9988 0.5 19.1429ZM0.5 27.4286C0.5 28.5726 1.42741 29.5 2.57143 29.5H35.4286C36.5726 29.5 37.5 28.5726 37.5 27.4286C37.5 26.2846 36.5726 25.3571 35.4286 25.3571H2.57143C1.42741 25.3571 0.5 26.2846 0.5 27.4286ZM0.5 10.8571C0.5 12.0012 1.42741 12.9286 2.57143 12.9286H35.4286C36.5726 12.9286 37.5 12.0012 37.5 10.8571C37.5 9.71312 36.5726 8.78571 35.4286 8.78571H2.57143C1.42741 8.78571 0.5 9.71312 0.5 10.8571ZM2.57143 0.5C1.42741 0.5 0.5 1.42741 0.5 2.57143C0.5 3.71545 1.42741 4.64286 2.57143 4.64286H35.4286C36.5726 4.64286 37.5 3.71545 37.5 2.57143C37.5 1.42741 36.5726 0.5 35.4286 0.5H2.57143Z", fill: fill }) })));
};
exports.MenuIcon = MenuIcon;
