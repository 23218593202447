"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileIcon = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Utils_1 = require("../../utils/Utils");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const __DEV__ = process.env.NODE_ENV !== 'production';
const FileIcon = (props) => {
    const theme = (0, ThemeProvider_1.useTheme)();
    if (!theme) {
        if (__DEV__) {
            console.error('`FileIcon` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const { className, testId, fill = theme.colors.$primary500 } = props;
    return ((0, jsx_runtime_1.jsx)("svg", Object.assign({ className: className, viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, (0, Utils_1.getTestIdAttribute)(testId), { children: (0, jsx_runtime_1.jsx)("path", { d: "M10.6591 1.5H3.56817C2.91817 1.5 2.38635 2.03182 2.38635 2.68182V10.9545H3.56817V2.68182H10.6591V1.5ZM10.0682 3.86364H5.93181C5.28181 3.86364 4.7559 4.39545 4.7559 5.04545L4.74999 13.3182C4.74999 13.9682 5.2759 14.5 5.9259 14.5H12.4318C13.0818 14.5 13.6136 13.9682 13.6136 13.3182V7.40909L10.0682 3.86364ZM5.93181 13.3182V5.04545H9.47726V8H12.4318V13.3182H5.93181Z", fill: fill }) })));
};
exports.FileIcon = FileIcon;
