"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTableCellStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
const theme_1 = require("../../../themes/typings/theme");
exports.getTableCellStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    headCell: Object.assign(Object.assign({}, theme.typography.$textPrimary150), { borderBottom: `1px solid ${theme.__base === theme_1.GlobalBaseTheme.LIGHT ? theme.colors.$content100 : theme.colors.$neutral500}`, padding: theme.spacing.$s3, whiteSpace: 'nowrap', maxWidth: '100%' }),
    bodyCell: Object.assign(Object.assign({}, theme.typography.$textPrimary100), { color: theme.colors.$content200, borderBottom: `1px solid ${theme.__base === theme_1.GlobalBaseTheme.LIGHT ? theme.colors.$content200 : theme.colors.$neutral500}`, padding: theme.spacing.$s3, maxWidth: '100%', whiteSpace: 'nowrap' }),
    left: {
        textAlign: 'left',
    },
    right: {
        textAlign: 'right',
    },
    center: {
        textAlign: 'center',
    },
    sticky: {
        backgroundColor: theme.colors.$background100,
        position: 'sticky',
        zIndex: 1,
    },
    sort: {
        padding: `${theme.spacing.$s2} 0`,
        width: 'fit-content',
        backgroundColor: 'transparent',
        position: 'relative',
        '&$left': {
            paddingRight: theme.spacing.$s4,
            justifyContent: 'flex-start',
            '& $sortIcon': {
                right: 0,
            },
        },
        '&$right': {
            paddingLeft: theme.spacing.$s4,
            justifyContent: 'flex-end',
            '& $sortIcon': {
                left: 0,
            },
        },
        '&:hover': {
            cursor: 'pointer',
        },
    },
    sortIcon: {
        position: 'absolute',
        width: '20px',
        '& path': {
            fill: theme.colors.$content100,
        },
    },
    grid: {
        border: `1px solid ${theme.__base === theme_1.GlobalBaseTheme.LIGHT ? theme.colors.$content100 : theme.colors.$neutral500}`,
    },
}), { name: 'Blueprint-TableCell' });
