"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Toggle = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const Utils_1 = require("../../utils/Utils");
const StyleUtils_1 = require("../../utils/StyleUtils");
const Toggle_styles_1 = require("./styles/Toggle.styles");
const ToggleGroup_1 = require("./ToggleGroup");
const __DEV__ = process.env.NODE_ENV !== 'production';
const Toggle = (props) => {
    const toggleGroup = (0, ToggleGroup_1.useToggleGroup)();
    const { children, className = '', disabled = (toggleGroup === null || toggleGroup === void 0 ? void 0 : toggleGroup.isDisabled) || false, id, name, onClick, selected = false, testId, value } = props, buttonProps = __rest(props, ["children", "className", "disabled", "id", "name", "onClick", "selected", "testId", "value"]);
    const theme = (0, ThemeProvider_1.useTheme)();
    const dti = (0, Utils_1.getTestIdAttribute)(testId);
    if (!theme) {
        if (__DEV__) {
            console.error('`Toggle` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const styles = (0, Toggle_styles_1.getToggleStyles)();
    const [isSelected, setIsSelected] = (0, react_1.useState)(selected);
    const handleOnClick = (e) => {
        if (toggleGroup) {
            // prevent ToggleGroup onChange from firing while exclusive is true and the current value is already selected
            if (toggleGroup.exclusive && toggleGroup.values.includes(value))
                return;
            toggleGroup.onChange(e.currentTarget.value);
        }
        setIsSelected(prevState => !prevState);
        if (onClick)
            onClick(e);
    };
    (0, react_1.useEffect)(() => {
        setIsSelected(selected);
    }, [selected]);
    (0, react_1.useEffect)(() => {
        if (!toggleGroup)
            return;
        if (toggleGroup.values.includes(value)) {
            setIsSelected(true);
        }
        else {
            setIsSelected(false);
        }
    }, [toggleGroup]);
    const renderButton = ((0, jsx_runtime_1.jsx)("button", Object.assign({}, buttonProps, { "aria-disabled": disabled, "aria-pressed": isSelected, className: (0, StyleUtils_1.classNames)('Toggle-local', className, styles.button, isSelected && styles.active), disabled: disabled, onClick: handleOnClick, value: value }, dti, { children: children })));
    return !toggleGroup ? ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: (0, StyleUtils_1.classNames)('Toggle-container-local', styles.container, disabled && styles.disabled) }, { children: renderButton }))) : (renderButton);
};
exports.Toggle = Toggle;
