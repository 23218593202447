"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccordionDetails = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const Utils_1 = require("../../utils/Utils");
const StyleUtils_1 = require("../../utils/StyleUtils");
const AccordionDetails_styles_1 = require("./styles/AccordionDetails.styles");
const Accordion_1 = require("./Accordion");
const __DEV__ = process.env.NODE_ENV !== 'production';
const AccordionDetails = (props) => {
    const { children, className = '', testId, id } = props;
    const theme = (0, ThemeProvider_1.useTheme)();
    const accordion = (0, Accordion_1.useAccordion)();
    if (!theme) {
        if (__DEV__) {
            console.error('`AccordionDetails` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    if (!accordion) {
        if (__DEV__) {
            console.error('`AccordionDetails` component must be a child of a `Accordion` component');
        }
        return null;
    }
    const styles = (0, AccordionDetails_styles_1.getAccordionDetailsStyles)();
    const dti = (0, Utils_1.getTestIdAttribute)(testId);
    return ((0, jsx_runtime_1.jsx)("div", Object.assign({ id: id, className: (0, StyleUtils_1.classNames)('AccordionDetails-local', styles.container, className) }, dti, { children: children })));
};
exports.AccordionDetails = AccordionDetails;
