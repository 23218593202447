"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTablePaginationStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
exports.getTablePaginationStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        margin: `${theme.spacing.$s3} 0`,
    },
    wrapper: {
        width: 'fit-content',
        display: 'flex',
        gap: theme.spacing.$s2,
        alignItems: 'center',
        flexWrap: 'wrap',
        '& .Select-container-local': {
            marginRight: theme.spacing.$s3,
            width: 'fit-content',
        },
    },
    prompt: Object.assign(Object.assign({}, theme.typography.$textSecondary100), { whiteSpace: 'pre', marginRight: theme.spacing.$s3 }),
    select: {
        width: 'fit-content',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: 'fit-content',
    },
    button: {
        padding: `${theme.spacing.$s1}`,
        backgroundColor: 'transparent',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:disabled': {
            '& $icon': {
                '& path': {
                    fill: theme.colors.$neutral400,
                },
            },
            '&:hover': {
                cursor: 'not-allowed',
            },
        },
        '&:hover': {
            cursor: 'pointer',
        },
    },
    icon: {
        width: '30px',
        '& path': {
            fill: theme.colors.$content100,
        },
    },
}), { name: 'Blueprint-TablePagination' });
