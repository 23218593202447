"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableHead = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const Utils_1 = require("../../utils/Utils");
const TableHead_styles_1 = require("./styles/TableHead.styles");
const Table_1 = require("./Table");
const __DEV__ = process.env.NODE_ENV !== 'production';
const TableHead = (props) => {
    const { children, className = '', testId = 'tableHead' } = props;
    const theme = (0, ThemeProvider_1.useTheme)();
    const table = (0, Table_1.useTable)();
    const dti = (0, Utils_1.getTestIdAttribute)(testId);
    const styles = (0, TableHead_styles_1.getTableHeadStyles)();
    if (!theme) {
        if (__DEV__) {
            console.error('`TableHead` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    if (!table) {
        if (__DEV__) {
            console.error('`TableHead` component must be a child of a `Table` component');
        }
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(Table_1.TableSubProvider, Object.assign({ variant: Table_1.TableVariantType.HEAD }, { children: (0, jsx_runtime_1.jsx)("thead", Object.assign({ className: `TableHead-local ${styles.container} ${className}` }, dti, { children: children })) })));
};
exports.TableHead = TableHead;
