"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPillStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
exports.getPillStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    label: {
        textTransform: 'inherit',
    },
    default: Object.assign({}, theme.variants.pills.default),
}), { name: 'Blueprint-Pill' });
