"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WalletIcon = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Utils_1 = require("../../utils/Utils");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const __DEV__ = process.env.NODE_ENV !== 'production';
const WalletIcon = (props) => {
    const theme = (0, ThemeProvider_1.useTheme)();
    if (!theme) {
        if (__DEV__) {
            console.error('`WalletIcon` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const { className, testId, fill = theme.colors.$primary500 } = props;
    return ((0, jsx_runtime_1.jsx)("svg", Object.assign({ className: className, viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, (0, Utils_1.getTestIdAttribute)(testId), { children: (0, jsx_runtime_1.jsx)("path", { d: "M13.3684 4.85333V3.33333C13.3684 2.6 12.8 2 12.1053 2H3.26316C2.56211 2 2 2.6 2 3.33333V12.6667C2 13.4 2.56211 14 3.26316 14H12.1053C12.8 14 13.3684 13.4 13.3684 12.6667V11.1467C13.7411 10.9133 14 10.4933 14 10V6C14 5.50667 13.7411 5.08667 13.3684 4.85333ZM12.7368 6V10H8.31579V6H12.7368ZM3.26316 12.6667V3.33333H12.1053V4.66667H8.31579C7.62105 4.66667 7.05263 5.26667 7.05263 6V10C7.05263 10.7333 7.62105 11.3333 8.31579 11.3333H12.1053V12.6667H3.26316Z", fill: fill }) })));
};
exports.WalletIcon = WalletIcon;
