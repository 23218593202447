"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Option = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const Utils_1 = require("../../utils/Utils");
const Option_styles_1 = require("./styles/Option.styles");
const Select_1 = require("./Select");
const NativeSelect_1 = require("./NativeSelect");
const __DEV__ = process.env.NODE_ENV !== 'production';
const Option = (props) => {
    const { children, value, name, id = name, disabled, testId, localClassName = 'Select-option-local', className = '', dataIndex, } = props;
    const theme = (0, ThemeProvider_1.useTheme)();
    const select = (0, Select_1.useSelect)();
    const optionRef = (0, react_1.createRef)();
    const dti = (0, Utils_1.getTestIdAttribute)(testId);
    if (!theme) {
        if (__DEV__) {
            console.error('`Select.Option` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    if (!select) {
        if (__DEV__) {
            console.error('`Select.Option` component must be a child of a `Select`');
        }
        return null;
    }
    const [isActive, setIsActive] = (0, react_1.useState)(select.selectedOption === value);
    const styles = (0, Option_styles_1.getOptionStyles)();
    const handleKeyDown = (e) => {
        if (!isActive && !disabled && (e.keyCode === 13 || e.keyCode === 32)) {
            // stop event from rolling up to select event
            e.stopPropagation();
            select.handleOnChange({ value, name });
        }
    };
    const handleOnClick = (e) => {
        e.preventDefault();
        if (!isActive && !disabled) {
            select.handleOnChange({ value, name });
        }
    };
    (0, react_1.useEffect)(() => {
        if (select.selectedOption === value) {
            setIsActive(true);
        }
    }, [select.selectedOption]);
    if (select.useNative) {
        return ((0, jsx_runtime_1.jsx)(NativeSelect_1.NativeSelect.Option, Object.assign({ disabled: disabled, id: id, value: value, testId: testId }, { children: children })));
    }
    return ((0, jsx_runtime_1.jsx)("li", Object.assign({ id: id, role: "option", "data-option-index": dataIndex, "aria-disabled": disabled, "aria-selected": isActive, className: `${localClassName} ${styles.container} ${className} ${disabled ? `${styles.disabled} Select-option-disabled` : ''} ${isActive ? `${styles.active} Select-option-active` : ''}`, ref: optionRef, onKeyDown: handleKeyDown, onMouseDown: handleOnClick }, dti, { children: children })));
};
exports.Option = Option;
