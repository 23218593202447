"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAccountStyles = void 0;
const theme_1 = require("../../../themes/typings/theme");
const Utils_1 = require("../../../utils/Utils");
exports.getAccountStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    mainContainer: Object.assign({ minWidth: '250px', width: '100%', maxWidth: '300px', display: 'flex', backgroundColor: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$primary300 : theme.colors.$background100, position: 'absolute', top: '110%', right: 0, borderRadius: '4px', zIndex: '1001' }, theme.shadows.$shadow100),
    mainWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    contentContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    detailsContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: theme.spacing.$s3,
        borderBottom: `1px solid ${theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$neutral600 : theme.colors.$neutral200}`,
    },
    detailsWrapper: {
        width: '100%',
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        marginLeft: theme.spacing.$s3,
        overflow: 'hidden',
    },
    avatarContainer: {
        display: 'flex',
        width: 'fit-content',
    },
    avatar: Object.assign(Object.assign({}, theme.typography.$textPrimary200), { width: '50px', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50px', padding: 0, margin: 0, backgroundColor: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$neutral100 : theme.colors.$primary300, color: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$primary300 : theme.colors.$neutral100 }),
    name: Object.assign(Object.assign({}, theme.typography.$textPrimary150), { marginBottom: theme.spacing.$s1, textOverflow: 'ellipsis', overflow: 'hidden' }),
    email: Object.assign(Object.assign({}, theme.typography.$textPrimary000), { marginBottom: theme.spacing.$s1, overflow: 'hidden', textOverflow: 'ellipsis', width: '100%', maxWidth: '100%', flexWrap: 'nowrap' }),
    linkContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'flex-start',
        borderBottom: `1px solid ${theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$neutral600 : theme.colors.$neutral200}`,
    },
    link: {
        width: '100%',
        '& a': {
            display: 'block',
            padding: `${theme.spacing.$s3} ${theme.spacing.$s3}`,
            width: '100%',
            textAlign: 'left',
            alignSelf: 'flex-start',
        },
    },
    [`@media (max-width: ${theme.breakpoints.$md}px)`]: {
        mainContainer: {
            backgroundColor: theme.colors.$background100,
            borderBottom: `1px solid ${theme.colors.$content100}`,
            borderRadius: 0,
            boxShadow: 'none',
            maxWidth: '100%',
            minWidth: '100%',
            position: 'relative',
            width: '100%',
        },
        detailsContainer: {
            padding: theme.spacing.$s4,
        },
        link: {
            '& a': {
                padding: `${theme.spacing.$s5}`,
            },
        },
    },
}), { name: 'Blueprint-Account' });
