"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCollapseStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
exports.getCollapseStyles = (0, Utils_1.createStyleSheet)({
    container: {
        height: '0px',
        opacity: 0,
        overflow: 'hidden',
        width: '100%',
    },
}, {
    name: 'Blueprint-Collapse',
});
