"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NativeOption = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Utils_1 = require("../../utils/Utils");
const StyleUtils_1 = require("../../utils/StyleUtils");
const NativeOption = (props) => {
    const { children, id, disabled, value, testId, className = '' } = props;
    const dti = (0, Utils_1.getTestIdAttribute)(testId);
    return ((0, jsx_runtime_1.jsx)("option", Object.assign({ id: id, className: (0, StyleUtils_1.classNames)('NativeOption-local', className), value: value, disabled: disabled }, dti, { children: children })));
};
exports.NativeOption = NativeOption;
