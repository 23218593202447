"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSubNavItemStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
const theme_1 = require("../../../themes/typings/theme");
exports.getSubNavItemStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    listItem: {
        width: '100%',
        '&:not(:last-child)': {
            borderBottom: `1px solid ${theme.colors.$content100}`,
        },
    },
    subNavItem: {
        '&.Link-local': {
            '& a': {
                margin: '0',
                padding: `${theme.spacing.$s4} ${theme.spacing.$s3}`,
                width: '100%',
                '&:hover, &:focus': {
                    color: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$interactiveLinkHover : theme.colors.$secondary500,
                },
            },
        },
    },
    active: {
        '&.Link-local': {
            '& a': {
                '&:active, &:visited': {
                    color: theme.colors.$secondary400,
                },
            },
        },
    },
    disabled: {
        '&.Link-local': {
            '& a': {
                color: theme.colors.$interactiveLinkDisabled,
                '&:active, &:visited, ': {
                    color: theme.colors.$interactiveLinkDisabled,
                },
                '&:hover, &:focus': {
                    color: theme.colors.$interactiveLinkDisabled,
                    cursor: 'not-allowed',
                },
            },
        },
    },
}), { name: 'Blueprint-SubNavItem' });
