"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpIcon = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Utils_1 = require("../../utils/Utils");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
/* eslint-disable max-len */
const __DEV__ = process.env.NODE_ENV !== 'production';
const UpIcon = (props) => {
    const theme = (0, ThemeProvider_1.useTheme)();
    if (!theme) {
        if (__DEV__) {
            console.error('`UpIcon` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const { className, testId, fill = theme.colors.$primary500 } = props;
    return ((0, jsx_runtime_1.jsx)("svg", Object.assign({ className: className, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, (0, Utils_1.getTestIdAttribute)(testId), { children: (0, jsx_runtime_1.jsx)("path", { d: "M7.41 15.41 12 10.83l4.59 4.58L18 14l-6-6-6 6z", fill: fill }) })));
};
exports.UpIcon = UpIcon;
