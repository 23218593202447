"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Card = exports.CardVariant = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const Utils_1 = require("../../utils/Utils");
const Card_styles_1 = require("./styles/Card.styles");
const __DEV__ = process.env.NODE_ENV !== 'production';
var CardVariant;
(function (CardVariant) {
    CardVariant["DEFAULT"] = "default";
})(CardVariant = exports.CardVariant || (exports.CardVariant = {}));
const Card = (props) => {
    const { children, testId, className = '', localClassName = 'Card-local', variant = CardVariant.DEFAULT } = props;
    const theme = (0, ThemeProvider_1.useTheme)();
    if (!theme) {
        if (__DEV__) {
            console.error('`Card` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const styles = (0, Card_styles_1.getCardStyles)();
    const dti = (0, Utils_1.getTestIdAttribute)(testId);
    return ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: `${localClassName} ${styles[variant]} ${className}` }, dti, { children: children })));
};
exports.Card = Card;
