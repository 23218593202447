"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAuxiliaryFooterStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
exports.getAuxiliaryFooterStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    container: Object.assign(Object.assign({ width: '100%', display: 'flex' }, theme.variants.footers.default.$aux), { '&$mobile': {
            padding: theme.spacing.$s4,
        } }),
    mobile: {},
}), { name: 'Blueprint-AuxiliaryFooter' });
