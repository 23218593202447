"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SCSSThemeProvider = exports.withSCSSTheme = exports.setThemeVariables = exports.useSCSSTheme = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = __importStar(require("react"));
const baseTheme_1 = require("../themes/baseTheme");
const Utils_1 = require("./Utils");
const ThemeProvider_1 = require("./ThemeProvider");
const TProvider = (0, react_1.createContext)(baseTheme_1.BaseTheme);
/**
 * Context hook for accessing the current theme set in SCSSThemeProvider
 */
const useSCSSTheme = () => (0, react_1.useContext)(TProvider);
exports.useSCSSTheme = useSCSSTheme;
/**
 * @param theme - instance of IBaseTheme that is going to be constructed
 * @param el - element to find to scope variables to or to root of document
 */
const setThemeVariables = (theme, el) => {
    const traverse = (obj) => {
        Object.keys(obj).forEach(key => {
            if (typeof obj[key] === 'object')
                traverse(obj[key]);
            // checks if key starts with __ which references non-variable metadata
            else if (!key.startsWith('__')) {
                if (el) {
                    el.style.setProperty(`--${key}`, obj[key]);
                }
                else {
                    document.documentElement.style.setProperty(`--${key}`, obj[key]);
                }
            }
        });
    };
    traverse(theme);
};
exports.setThemeVariables = setThemeVariables;
/**
 * @param component - ReactNode to wrap theme provider around
 * @param theme - Theme provided which could be a custom extended theme from a global theme or a global theme itself.
 * @param scoped - Make the theme variables scoped to this tree or not. Defaults to true.
 */
const withSCSSTheme = (component, theme, scoped = true) => {
    return ((0, jsx_runtime_1.jsx)(exports.SCSSThemeProvider, Object.assign({ theme: theme, scoped: scoped }, { children: component })));
};
exports.withSCSSTheme = withSCSSTheme;
/**
 * SCSSThemeProvider should be set as high as possible in the application
 * Leveraging the SCSSThemeProvider will give you a hook into the global theme set and any
 * component leverages this theme.
 *
 * @param theme - theme to be set
 * @param children - child node (ReactElement)
 * @param router - RouterTypes
 * @param scoped - Scope SCSS variables to component or not (defaults to false)
 * @param theme - Partial or entire BaseTheme
 * @constructor
 */
const SCSSThemeProvider = ({ children, cssReset = false, router = ThemeProvider_1.RouterTypes.REACT_ROUTER, scoped = false, theme = baseTheme_1.BaseTheme, }) => {
    const routerValue = (0, react_1.useMemo)(() => {
        return {
            router,
        };
    }, [router]);
    const themeValue = (0, react_1.useMemo)(() => theme, [theme]);
    if (cssReset)
        (0, Utils_1.useCssReset)();
    (0, Utils_1.setGlobalFonts)();
    const scopedRef = (0, react_1.createRef)();
    const child = react_1.default.cloneElement(children, { ref: scoped ? scopedRef : undefined });
    (0, react_1.useEffect)(() => {
        (0, exports.setThemeVariables)(theme, scopedRef.current);
    }, [theme, scopedRef.current]);
    return ((0, jsx_runtime_1.jsx)(ThemeProvider_1.RouterContext.Provider, Object.assign({ value: routerValue }, { children: (0, jsx_runtime_1.jsx)(TProvider.Provider, Object.assign({ value: themeValue }, { children: child })) })));
};
exports.SCSSThemeProvider = SCSSThemeProvider;
