"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BypassBlock = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const Utils_1 = require("../../utils/Utils");
const BypassBlock_styles_1 = require("./styles/BypassBlock.styles");
const __DEV__ = process.env.NODE_ENV !== 'production';
const BypassBlock = (props) => {
    const { id, children, testId, className = '' } = props;
    const theme = (0, ThemeProvider_1.useTheme)();
    const dti = (0, Utils_1.getTestIdAttribute)(testId);
    if (!theme) {
        if (__DEV__) {
            console.error('`BypassBlock` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const styles = (0, BypassBlock_styles_1.getBypassBlockStyles)();
    return ((0, jsx_runtime_1.jsx)("a", Object.assign({ href: `#${id}`, className: `BypassBlock-local ${className} ${styles.link}` }, dti, { children: children })));
};
exports.BypassBlock = BypassBlock;
