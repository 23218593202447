"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LinkIcon = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Utils_1 = require("../../utils/Utils");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
/* eslint-disable max-len */
const __DEV__ = process.env.NODE_ENV !== 'production';
const LinkIcon = (props) => {
    const theme = (0, ThemeProvider_1.useTheme)();
    if (!theme) {
        if (__DEV__) {
            console.error('`LinkIcon` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const { className, testId, fill = theme.colors.$primary500 } = props;
    return ((0, jsx_runtime_1.jsx)("svg", Object.assign({ className: className, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, (0, Utils_1.getTestIdAttribute)(testId), { children: (0, jsx_runtime_1.jsx)("path", { d: "M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z", fill: fill }) })));
};
exports.LinkIcon = LinkIcon;
