"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubMenu = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const icons_1 = require("../../assets/icons");
const SubMenuItem_1 = require("./SubMenuItem");
const SubMenu_styles_1 = require("./styles/SubMenu.styles");
const SubMenu = (props) => {
    var _a;
    const { navItem, handleOnClose, subMenuOpen, domains } = props;
    const styles = (0, SubMenu_styles_1.getSubMenuStyles)();
    return ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: `SubMenu-subMenuContainer-local ${styles.subMenuContainer} ${subMenuOpen ? styles.openSubMenu : ''}` }, { children: subMenuOpen && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("div", Object.assign({ className: `SubMenu-header-local ${styles.header}` }, { children: (0, jsx_runtime_1.jsxs)("button", Object.assign({ tabIndex: subMenuOpen ? 0 : -1, className: `SubMenu-backButton-local ${styles.backButton}`, onClick: () => handleOnClose(false) }, { children: [(0, jsx_runtime_1.jsx)(icons_1.ArrowLeftIcon, { className: styles.backIcon }), navItem === null || navItem === void 0 ? void 0 : navItem.label] })) })), (0, jsx_runtime_1.jsx)("ul", { children: (_a = navItem === null || navItem === void 0 ? void 0 : navItem.subNavItems) === null || _a === void 0 ? void 0 : _a.map(navItem => ((0, jsx_runtime_1.jsx)(SubMenuItem_1.SubMenuItem, { tabIndex: subMenuOpen ? 0 : -1, navItem: navItem, subMenuOpen: subMenuOpen, handleOnClose: handleOnClose, domains: domains }, navItem.id))) })] })) })));
};
exports.SubMenu = SubMenu;
