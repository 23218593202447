"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getButtonStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
exports.getButtonStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    wrapper: {
        position: 'relative',
        width: 'fit-content',
        maxWidth: '100%',
    },
    button: {
        width: 'fit-content',
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    busy: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'wait',
    },
    fullWidth: {
        width: '100%',
    },
    default: Object.assign({}, theme.variants.buttons.default),
    ghost: Object.assign({}, theme.variants.buttons.ghost),
    small: Object.assign({}, theme.variants.buttons.small),
}), { name: 'Blueprint-Button' });
