"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.footerStyles = void 0;
const Utils_1 = require("../../../../utils/Utils");
const color_1 = require("../../../typings/color");
const colors_1 = require("../colors");
const spacing_1 = require("../spacing");
const typography_1 = require("../typography");
const _rootPartial = {
    backgroundColor: colors_1.semanticLightColorValues.$background300,
    color: color_1.paletteColorValues.$neutral100,
    $link: {
        color: color_1.paletteColorValues.$neutral100,
        '& a': {
            padding: `${spacing_1.spacingValues.$s1} 0`,
            color: color_1.paletteColorValues.$neutral100,
            '&:hover, &:focus': {
                color: color_1.paletteColorValues.$neutral100,
            },
            '&:visited': {
                color: color_1.paletteColorValues.$neutral100,
                '&:hover, &:focus': {
                    color: color_1.paletteColorValues.$neutral100,
                },
            },
            '&:active': {
                color: color_1.paletteColorValues.$neutral100,
            },
        },
    },
    $subLink: {
        color: color_1.paletteColorValues.$neutral100,
        '& a': {
            color: color_1.paletteColorValues.$neutral100,
            '&:hover, &:focus': {
                color: color_1.paletteColorValues.$neutral100,
            },
            '&:visited': {
                color: color_1.paletteColorValues.$neutral100,
                '&:hover, &:focus': {
                    color: color_1.paletteColorValues.$neutral100,
                },
            },
            '&:active': {
                color: color_1.paletteColorValues.$neutral100,
            },
        },
    },
    $aux: Object.assign(Object.assign({ backgroundColor: color_1.paletteColorValues.$primary400 }, typography_1.typographyValues.$textPrimary050), { color: color_1.paletteColorValues.$tertiary100, padding: spacing_1.spacingValues.$s5 }),
};
exports.footerStyles = {
    default: (0, Utils_1.constructVariantStyles)([_rootPartial]),
};
