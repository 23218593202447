"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMenuStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
const StyleUtils_1 = require("../../../utils/StyleUtils");
exports.getMenuStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    menuContainer: {
        position: 'absolute',
        backgroundColor: theme.colors.$background100,
        height: '0px',
        maxHeight: '0px',
        minHeight: '0px',
        top: `${80}px`,
        transition: 'all ease-in .3s',
        width: '100%',
        overflow: 'hidden',
    },
    menuOpen: {
        height: `calc(${(0, StyleUtils_1.vh)()} - ${80}px)`,
        maxHeight: `calc(${(0, StyleUtils_1.vh)()} - ${80}px)`,
        minHeight: `calc(${(0, StyleUtils_1.vh)()} - ${80}px)`,
    },
    menuWrapper: {
        position: 'relative',
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        overflow: 'auto',
    },
    menuItems: {
        height: 'auto',
        minHeight: '100%',
        width: '100%',
    },
}), { name: 'Blueprint-Menu' });
