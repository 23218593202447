"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageIcon = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Utils_1 = require("../../utils/Utils");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const __DEV__ = process.env.NODE_ENV !== 'production';
const MessageIcon = (props) => {
    const theme = (0, ThemeProvider_1.useTheme)();
    if (!theme) {
        if (__DEV__) {
            console.error('`MessageIcon` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const { className, testId, fill = theme.colors.$primary500 } = props;
    return ((0, jsx_runtime_1.jsx)("svg", Object.assign({ className: className, viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, (0, Utils_1.getTestIdAttribute)(testId), { children: (0, jsx_runtime_1.jsx)("path", { d: "M14.25 4.25C14.25 3.5625 13.6875 3 13 3H3C2.3125 3 1.75 3.5625 1.75 4.25V11.75C1.75 12.4375 2.3125 13 3 13H13C13.6875 13 14.25 12.4375 14.25 11.75V4.25ZM13 4.25L8 7.375L3 4.25H13ZM13 11.75H3V5.5L8 8.625L13 5.5V11.75Z", fill: fill }) })));
};
exports.MessageIcon = MessageIcon;
