"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loginItems = exports.defaultNavItems = void 0;
exports.defaultNavItems = [
    {
        id: 'homeHeader',
        href: '/',
        label: 'Home',
    },
    {
        id: 'propertiesHeader',
        href: '/properties',
        label: 'Properties',
    },
    {
        id: 'technologyHeader',
        href: '/technology',
        label: 'Technology',
    },
    {
        id: 'companyHeader',
        href: '/company',
        label: 'Company',
        subNavItems: [
            { id: 'company-aboutHeader', href: '/about', label: 'About' },
            { id: 'company-careersHeader', href: '/careers', label: 'Careers' },
            { id: 'company-visionHeader', href: '/vision', label: 'Vision' },
        ],
    },
    {
        id: 'realtyHeader',
        href: '/realty',
        label: 'Groma Realty',
    },
    {
        id: 'contactHeader',
        href: '/contact',
        label: 'Contact',
    },
];
exports.loginItems = {
    id: 'signInHeader',
    label: 'Sign In',
    href: '',
    subNavItems: [
        {
            id: 'investorInfoLogin',
            href: '/',
            isExternal: true,
            experience: 'investors',
            label: 'Investor Portal',
        },
        {
            id: 'tenantPortalLogin',
            href: 'https://groma.managebuilding.com/Resident/portal/login',
            label: 'Tenant Portal',
            isExternal: true,
        },
    ],
};
