"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorPage = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_intl_1 = require("react-intl");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const Utils_1 = require("../../utils/Utils");
const Link_1 = require("../Link/Link");
const icons_1 = require("../../assets/icons");
const locales_1 = require("./locales");
const ErrorPage_styles_1 = require("./styles/ErrorPage.styles");
const __DEV__ = process.env.NODE_ENV !== 'production';
const ErrorPage = (props) => {
    var _a;
    const { backgroundColor, content, errorMessage, image, link, maxContainerWidth = 1440, title, testId } = props;
    const theme = (0, ThemeProvider_1.useTheme)();
    if (!theme) {
        if (__DEV__) {
            console.error('`ErrorPage` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const styles = (0, ErrorPage_styles_1.getErrorPageStyles)();
    const browserLocale = navigator.language;
    const currentLocale = (_a = locales_1.locales[browserLocale]) !== null && _a !== void 0 ? _a : locales_1.locales['en-US'];
    const flattenedLocales = (0, Utils_1.flattenLocales)(currentLocale);
    const dti = (0, Utils_1.getTestIdAttribute)(testId);
    return ((0, jsx_runtime_1.jsx)(react_intl_1.IntlProvider, Object.assign({ locale: browserLocale !== null && browserLocale !== void 0 ? browserLocale : 'en-US', messages: flattenedLocales, defaultLocale: "en-US" }, { children: (0, jsx_runtime_1.jsx)("div", Object.assign({ className: `ErrorPage-mainContainer-local ${styles.mainContainer}` }, dti, { style: { backgroundColor } }, { children: (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: `ErrorPage-mainWrapper-local ${styles.mainWrapper}`, style: { maxWidth: `${maxContainerWidth}px` } }, { children: [(0, jsx_runtime_1.jsxs)("div", Object.assign({ className: `ErrorPage-leftContent-local ${styles.leftContent}` }, { children: [(0, jsx_runtime_1.jsx)("p", Object.assign({ className: `ErrorPage-error-local ${styles.error}` }, { children: errorMessage !== null && errorMessage !== void 0 ? errorMessage : (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "error" }) })), (0, jsx_runtime_1.jsx)("h1", Object.assign({ className: `ErrorPage-title-local  ${styles.title}` }, { children: title !== null && title !== void 0 ? title : (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "title" }) })), (0, jsx_runtime_1.jsx)("p", Object.assign({ className: `ErrorPage-content-local ${styles.content}` }, { children: content !== null && content !== void 0 ? content : (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "content" }) })), link !== null && link !== void 0 ? link : ((0, jsx_runtime_1.jsx)(Link_1.Link, Object.assign({ href: "/", className: styles.link }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "button" }) })))] })), (0, jsx_runtime_1.jsx)("div", Object.assign({ className: `ErrorPage-rightContent-local ${styles.rightContent}` }, { children: image !== null && image !== void 0 ? image : (0, jsx_runtime_1.jsx)(icons_1.CryingBeanIcon, { className: styles.image, testId: "errorPageImage" }) }))] })) })) })));
};
exports.ErrorPage = ErrorPage;
