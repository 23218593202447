"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOptionStyles = void 0;
const theme_1 = require("../../../themes/typings/theme");
const Utils_1 = require("../../../utils/Utils");
exports.getOptionStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    container: Object.assign(Object.assign({}, theme.typography.$textPrimary100), { alignItems: 'center', backgroundColor: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$primary400 : theme.colors.$neutral100, borderRadius: theme.spacing.$s1, display: 'flex', margin: `${theme.spacing.$s1} 0`, padding: `${theme.spacing.$s2} ${theme.spacing.$s1}`, width: '100%', '&:hover': {
            backgroundColor: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$primary200 : theme.colors.$neutral200,
            cursor: 'pointer',
        }, '&$disabled, &$disabled:hover': {
            backgroundColor: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$primary400 : theme.colors.$neutral100,
            color: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$neutral500 : theme.colors.$neutral300,
            cursor: 'not-allowed',
        } }),
    disabled: {},
    active: {
        backgroundColor: theme.colors.$primary100,
        color: theme.colors.$tertiary100,
        '&:hover': {
            backgroundColor: theme.colors.$primary100,
            color: theme.colors.$tertiary100,
        },
    },
}), { name: 'Blueprint-Option' });
