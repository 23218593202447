"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFooterStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
const StyleUtils_1 = require("../../../utils/StyleUtils");
exports.getFooterStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    footerContainer: Object.assign(Object.assign({}, (0, StyleUtils_1.flexMixin)('column', 'normal', 'center')), { height: '100%', width: '100%' }),
    footerWrapper: Object.assign(Object.assign({}, (0, StyleUtils_1.flexMixin)('row', 'space-between', 'stretch')), { width: '100%' }),
    footerColumn: Object.assign(Object.assign({}, (0, StyleUtils_1.flexMixin)('column', 'flex-start')), { width: '100%' }),
    subFooterWrapper: Object.assign(Object.assign({}, (0, StyleUtils_1.flexMixin)('row', 'space-between', 'center')), { width: '100%' }),
    logo: {
        width: '100px',
        height: 'fit-content',
    },
    default: Object.assign({}, theme.variants.footers.default),
    mobile: {},
    [`@media (max-width: ${theme.breakpoints.$sm}px)`]: {
        footerWrapper: Object.assign({}, (0, StyleUtils_1.flexMixin)('column', 'flex-start', 'center')),
        logo: {
            marginLeft: 0,
        },
    },
}), { name: 'Blueprint-Footer' });
