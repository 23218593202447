"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogoutIcon = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Utils_1 = require("../../utils/Utils");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const __DEV__ = process.env.NODE_ENV !== 'production';
const LogoutIcon = (props) => {
    const theme = (0, ThemeProvider_1.useTheme)();
    if (!theme) {
        if (__DEV__) {
            console.error('`LogoutIcon` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const { className, testId, fill = theme.colors.$primary500 } = props;
    return ((0, jsx_runtime_1.jsx)("svg", Object.assign({ className: className, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, (0, Utils_1.getTestIdAttribute)(testId), { children: (0, jsx_runtime_1.jsx)("path", { d: "m17 7-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z", fill: fill }) })));
};
exports.LogoutIcon = LogoutIcon;
