"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGromaCoinNumberStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
const StyleUtils_1 = require("../../../utils/StyleUtils");
exports.getGromaCoinNumberStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    numberContainer: Object.assign(Object.assign({}, (0, StyleUtils_1.flexMixin)('row', 'normal', 'center')), theme.typography.$textPrimary450),
    icon: Object.assign({}, (0, StyleUtils_1.flexMixin)('row', 'normal', 'center')),
    whole: {},
    fractional: Object.assign(Object.assign({}, theme.typography.$textSecondary000), { color: theme.colors.$neutral400, display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: theme.spacing.$s1, position: 'relative', whiteSpace: 'normal', width: 50, wordBreak: 'break-all', '& > *': {
            lineHeight: '10px', // override for fractional grid
        } }),
    fractionalBoldDigits: {
        color: theme.colors.$content100,
    },
    [`@media (max-width: ${theme.breakpoints.$md}px)`]: {
        numberContainer: Object.assign({}, theme.typography.$textPrimary350),
        whole: {},
        fractional: {
            fontSize: '8px',
            '& > *': {
                lineHeight: '8px', // override for fractional grid
            },
        },
    },
}), { name: 'Blueprint-GromaCoinNumber' });
