"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBypassBlockStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
exports.getBypassBlockStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    link: Object.assign(Object.assign({ alignItems: 'center', backgroundColor: theme.colors.$background100, display: 'flex', flexDirection: 'row', height: '1px', justifyContent: 'center', overflow: 'hidden', position: 'absolute', textAlign: 'center', top: '-1000px', width: '1px', borderRadius: '4px' }, theme.typography.$textSecondary100), { color: theme.colors.$interactiveLinkDefault, boxShadow: '0px 4px 19px rgba(235, 228, 218, 0.68)', textDecoration: 'underline', '&:focus': {
            height: 'fit-content',
            position: 'fixed',
            top: '80px',
            left: '5vw',
            width: 'fit-content',
            minWidth: '300px',
            maxWidth: '400px',
            padding: `${theme.spacing.$s3}`,
            zIndex: '1001',
        } }),
    [`@media (max-width: ${theme.breakpoints.$sm}px)`]: {
        link: {
            '&:focus': {
                left: theme.spacing.$s4,
            },
        },
    },
}), { name: 'Blueprint-BypassBlock' });
