export enum AppMode {
  MobileSmall = 'mobileSmall',
  MobileLarge = 'mobileLarge',
  Desktop = 'desktop',
}

export interface AppStoreState {
  mode: AppMode;
  firebaseInitialized: boolean;
}
