"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBentoBoxStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
const theme_1 = require("../../../themes/typings/theme");
const StyleUtils_1 = require("../../../utils/StyleUtils");
exports.getBentoBoxStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    container: Object.assign(Object.assign({}, (0, StyleUtils_1.flexMixin)('row', 'center', 'center')), { width: 'fit-content' }),
    button: Object.assign(Object.assign({}, (0, StyleUtils_1.flexMixin)('row', 'center', 'center')), { width: 'fit-content', padding: theme.spacing.$s1, borderRadius: '50%', backgroundColor: 'transparent', '&:hover': {
            cursor: 'pointer',
        } }),
    bentoBoxIcon: {
        width: '30px',
        height: '30px',
    },
    panelContainer: {
        display: 'grid',
        gridGap: theme.spacing.$s4,
        gridTemplateColumns: '1fr 1fr',
        width: '100%',
        margin: '0 auto',
        minWidth: '350px',
    },
    panelLink: {
        '&.Link-local': Object.assign(Object.assign({}, (0, StyleUtils_1.flexMixin)('column')), { width: '100%', '& a': Object.assign(Object.assign({}, theme.typography.$textPrimary000), { width: '100%', borderRadius: theme.spacing.$s1, color: theme.colors.$content100, margin: '0 auto', padding: `${theme.spacing.$s3} ${theme.spacing.$s2}`, textDecoration: 'none', transition: 'box-shadow 0.1s ease', '&:visited': {
                    backgroundColor: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$primary300 : 'transparent',
                    color: theme.colors.$content100,
                    textDecoration: 'none',
                }, '&:hover, &:focus': Object.assign(Object.assign({ backgroundColor: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$primary300 : 'transparent' }, theme.shadows.$dropShadow100), { color: theme.colors.$content100, textDecoration: 'none' }) }) }),
    },
    icon: {
        width: '50px',
        height: '50px',
        marginBottom: theme.spacing.$s2,
    },
    [`@media (max-width: ${theme.breakpoints.$md}px)`]: {},
}), {
    name: 'Blueprint-BentoBox',
});
