"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccordionSummary = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const Utils_1 = require("../../utils/Utils");
const StyleUtils_1 = require("../../utils/StyleUtils");
const icons_1 = require("../../assets/icons");
const AccordionSummary_styles_1 = require("./styles/AccordionSummary.styles");
const Accordion_1 = require("./Accordion");
const __DEV__ = process.env.NODE_ENV !== 'production';
const AccordionSummary = (props) => {
    const { children, className = '', testId, id, showIcon = true, iconPlacement = 'left' } = props;
    const theme = (0, ThemeProvider_1.useTheme)();
    const accordion = (0, Accordion_1.useAccordion)();
    if (!theme) {
        if (__DEV__) {
            console.error('`AccordionSummary` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    if (!accordion) {
        if (__DEV__) {
            console.error('`AccordionSummary` component must be a child of a `Accordion` component');
        }
        return null;
    }
    const styles = (0, AccordionSummary_styles_1.getAccordionSummaryStyles)();
    const dti = (0, Utils_1.getTestIdAttribute)(testId);
    const { allowExpand, expanded, disabled, toggle } = accordion;
    const handleOnClick = () => {
        toggle(!expanded);
    };
    const El = (allowExpand ? 'button' : 'div');
    return ((0, jsx_runtime_1.jsxs)(El, Object.assign({ id: id, disabled: allowExpand && disabled, className: (0, StyleUtils_1.classNames)('AccordionSummary-local', styles.container, className, allowExpand && expanded && styles.open, !allowExpand && styles.noExpand), onClick: allowExpand ? handleOnClick : undefined }, dti, { children: [allowExpand && showIcon && iconPlacement === 'left' && ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: (0, StyleUtils_1.classNames)('Accordion-iconContainer-local', styles.iconContainer, styles.left) }, (0, Utils_1.getTestIdAttribute)('accordionIcon'), { children: (0, jsx_runtime_1.jsx)(icons_1.DownIcon, { className: (0, StyleUtils_1.classNames)('Accordion-icon-local', styles.icon, expanded && styles.open) }) }))), (0, jsx_runtime_1.jsx)("div", Object.assign({ className: (0, StyleUtils_1.classNames)('AccordionSummary-content-local', styles.content) }, { children: children })), allowExpand && showIcon && iconPlacement === 'right' && ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: (0, StyleUtils_1.classNames)('Accordion-iconContainer-local', styles.iconContainer, styles.right) }, (0, Utils_1.getTestIdAttribute)('accordionIcon'), { children: (0, jsx_runtime_1.jsx)(icons_1.DownIcon, { className: (0, StyleUtils_1.classNames)('Accordion-icon-local', styles.icon, expanded && styles.open) }) })))] })));
};
exports.AccordionSummary = AccordionSummary;
