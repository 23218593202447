"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Icon = exports.IconSize = exports.IconType = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const Utils_1 = require("../../utils/Utils");
const Icon_styles_1 = require("./styles/Icon.styles");
const __DEV__ = process.env.NODE_ENV !== 'production';
var IconType;
(function (IconType) {
    IconType["ACTION"] = "action";
    IconType["STATIC"] = "static";
})(IconType = exports.IconType || (exports.IconType = {}));
var IconSize;
(function (IconSize) {
    IconSize["SMALL"] = "24px";
    IconSize["MEDIUM"] = "48px";
    IconSize["LARGE"] = "96px";
})(IconSize = exports.IconSize || (exports.IconSize = {}));
const Icon = (props) => {
    const theme = (0, ThemeProvider_1.useTheme)();
    if (!theme) {
        if (__DEV__) {
            console.error('`Icon` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const { className = '', color, component, localClassName = 'Icon-local', size = IconSize.MEDIUM, testId, type = IconType.STATIC, } = props;
    const { $staticIcon100, $actionIcon100 } = theme.colors;
    const IconComponent = component;
    const iconColor = color ? color : type === IconType.STATIC ? $staticIcon100 : $actionIcon100;
    const styles = (0, Icon_styles_1.getIconStyles)({ size, color: iconColor });
    const dti = (0, Utils_1.getTestIdAttribute)(testId);
    return ((0, jsx_runtime_1.jsx)("span", Object.assign({ className: styles.iconContainer }, dti, { children: (0, jsx_runtime_1.jsx)(IconComponent, { className: `${localClassName} ${styles.icon} ${className}` }) })));
};
exports.Icon = Icon;
