"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MarkDarkLogo = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Utils_1 = require("../../utils/Utils");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const __DEV__ = process.env.NODE_ENV !== 'production';
const MarkDarkLogo = (props) => {
    const theme = (0, ThemeProvider_1.useTheme)();
    if (!theme) {
        if (__DEV__) {
            console.error('`MarkDarkLogo` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const { className, fill = theme.colors.$primary500, testId } = props;
    return ((0, jsx_runtime_1.jsx)("svg", Object.assign({ className: className, width: "66", height: "112", viewBox: "0 0 66 112", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, (0, Utils_1.getTestIdAttribute)(testId), { children: (0, jsx_runtime_1.jsx)("path", { d: "M65.0099 31.3185L36.0233 1.79092L34.2652 0L29.0072 1.79092L0.0246198 31.3185V37.6462L23.909 20.3804C23.909 20.3804 26.9622 18.7657 26.9622 30.5645V81.829C26.872 91.7262 24.2983 90.3205 24.2983 90.3205L3.05176e-05 72.7965L0.028718 78.9643L32.507 112L38.0683 106.439V30.5645C38.0683 18.7739 41.1174 20.3763 41.1215 20.3804L65.0099 37.6462V31.3185Z", fill: fill }) })));
};
exports.MarkDarkLogo = MarkDarkLogo;
