"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Input = exports.InputVariants = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = __importStar(require("react"));
const Utils_1 = require("../../utils/Utils");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const Label_1 = require("../Label/Label");
const StyleUtils_1 = require("../../utils/StyleUtils");
const Input_styles_1 = require("./styles/Input.styles");
const __DEV__ = process.env.NODE_ENV !== 'production';
var InputVariants;
(function (InputVariants) {
    InputVariants["DEFAULT"] = "default";
})(InputVariants = exports.InputVariants || (exports.InputVariants = {}));
const Input = (props) => {
    const { className = '', disabled = false, hasError = false, helperMessage, label, name, id = name !== null && name !== void 0 ? name : react_1.default.useMemo(() => (0, Utils_1.generateUUID)(), []), onBlur, onChange, onFocus, onKeyPress, placeholder, prefix, readOnly = false, shouldHideLabel = false, suffix, testId, type = 'text', variant = InputVariants.DEFAULT } = props, inputProps = __rest(props, ["className", "disabled", "hasError", "helperMessage", "label", "name", "id", "onBlur", "onChange", "onFocus", "onKeyPress", "placeholder", "prefix", "readOnly", "shouldHideLabel", "suffix", "testId", "type", "variant"]);
    const [hasFocus, setHasFocus] = (0, react_1.useState)(false);
    const dti = (0, Utils_1.getTestIdAttribute)(testId);
    const theme = (0, ThemeProvider_1.useTheme)();
    if (!theme) {
        if (__DEV__) {
            console.error('`Input` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const styles = (0, Input_styles_1.getInputStyles)();
    const additionalContent = (el, classname) => ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: styles[classname] }, { children: el })));
    const helperContent = (el) => ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: `Input-helperContainer-local ${styles.helperContainer} ${hasError ? styles.errorMessage : ''}` }, (0, Utils_1.getTestIdAttribute)('inputHelperContent'), { children: el })));
    const handleOnFocus = (e) => {
        setHasFocus(true);
        if (onFocus)
            onFocus(e);
    };
    const handleOnBlur = (e) => {
        setHasFocus(false);
        if (onBlur)
            onBlur(e);
    };
    const handleOnChange = (e) => {
        if (onChange)
            onChange(e);
    };
    const handleOnKeyPress = (e) => {
        if (onKeyPress)
            onKeyPress(e);
    };
    return ((0, jsx_runtime_1.jsxs)("div", Object.assign({ className: (0, StyleUtils_1.classNames)('Input-container-local', className, styles.container) }, dti, { children: [(0, jsx_runtime_1.jsxs)("div", Object.assign({ className: (0, StyleUtils_1.classNames)('Input-local', styles.wrapper, styles[variant], hasFocus && styles.focus, disabled && styles.disabled, hasError && styles.hasError, readOnly && styles.readOnly, shouldHideLabel && styles.hideLabel) }, { children: [(0, jsx_runtime_1.jsx)(Label_1.Label, { htmlFor: id, content: !shouldHideLabel ? label : undefined, screenReaderOnlyText: shouldHideLabel ? label : undefined, className: (0, StyleUtils_1.classNames)(styles.label, disabled && styles.disabled, hasError && styles.hasError, readOnly && styles.readOnly) }), (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: styles.innerWrapper }, { children: [prefix && additionalContent(prefix, 'prefix'), (0, jsx_runtime_1.jsx)("input", Object.assign({ id: id, name: name, placeholder: placeholder, type: type, readOnly: readOnly, disabled: disabled, className: styles.input, onBlur: handleOnBlur, onChange: handleOnChange, onFocus: handleOnFocus, onKeyDown: handleOnKeyPress, autoCapitalize: "off" }, inputProps)), suffix && additionalContent(suffix, 'suffix')] }))] })), helperMessage && helperContent(helperMessage)] })));
};
exports.Input = Input;
