"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuItem = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Link_1 = require("../Link/Link");
const icons_1 = require("../../assets/icons");
const StyleUtils_1 = require("../../utils/StyleUtils");
const Utils_1 = require("../../utils/Utils");
const MenuItem_styles_1 = require("./styles/MenuItem.styles");
const MenuItem = (props) => {
    const { navItem, subMenuOpen, handleOpenSubMenu, handleOnClose, isOpen, domains } = props;
    const styles = (0, MenuItem_styles_1.getMenuItemStyles)();
    if (navItem.render)
        return navItem.render(props);
    return ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: `MenuItem-menuItem-local ${styles.menuItem}` }, { children: (0, jsx_runtime_1.jsx)("li", { children: navItem.subNavItems ? ((0, jsx_runtime_1.jsxs)("button", Object.assign({ tabIndex: !isOpen || subMenuOpen ? -1 : 0, className: (0, StyleUtils_1.classNames)('MenuItem-subNavItem-local', styles.subNavItem, navItem.disabled && styles.disabled), disabled: navItem.disabled, id: navItem.id, onClick: (e) => {
                    if (navItem.disabled)
                        e.preventDefault();
                    else
                        handleOpenSubMenu(navItem);
                } }, { children: [navItem.label, (0, jsx_runtime_1.jsx)(icons_1.ArrowRightIcon, { className: (0, StyleUtils_1.classNames)(styles.rightIcon, navItem.disabled && styles.disabled) })] }))) : ((0, jsx_runtime_1.jsxs)(Link_1.Link, Object.assign({ tabIndex: !isOpen || subMenuOpen ? -1 : 0, id: navItem.id, href: `${navItem.experience ? (0, Utils_1.getDomainBasedOnExperience)(domains, navItem.experience) : ''}${navItem.href}`, className: (0, StyleUtils_1.classNames)(styles.navItem, navItem.disabled && styles.disabled), variant: Link_1.LinkVariants.NAV, disabled: navItem.disabled, onClick: handleOnClose, fullWidth: true, useRouter: !navItem.isExternal, target: navItem.openNewTab ? '_blank' : '_self' }, { children: [navItem.label, (0, jsx_runtime_1.jsx)(icons_1.ArrowRightIcon, { className: (0, StyleUtils_1.classNames)(styles.rightIcon, navItem.disabled && styles.disabled) })] }))) }) })));
};
exports.MenuItem = MenuItem;
