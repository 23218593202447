"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccordionGroup = exports.useAccordionGroup = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const StyleUtils_1 = require("../../utils/StyleUtils");
const Utils_1 = require("../../utils/Utils");
const AccordionGroup_styles_1 = require("./styles/AccordionGroup.styles");
const __DEV__ = process.env.NODE_ENV !== 'production';
const AccordionGroupContext = (0, react_1.createContext)(undefined);
const useAccordionGroup = () => (0, react_1.useContext)(AccordionGroupContext);
exports.useAccordionGroup = useAccordionGroup;
const AccordionGroup = (props) => {
    const theme = (0, ThemeProvider_1.useTheme)();
    if (!theme) {
        if (__DEV__) {
            console.error('`AccordionGroup` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const { children, defaultAccordionId = '', testId, className = '', nested = false, onChange } = props;
    const styles = (0, AccordionGroup_styles_1.getAccordionGroupStyles)();
    const dti = (0, Utils_1.getTestIdAttribute)(testId);
    const [selectedId, setSelectedId] = (0, react_1.useState)(defaultAccordionId);
    const handleOnChange = (id) => {
        if (onChange)
            onChange(id);
        setSelectedId(id);
    };
    const value = (0, react_1.useMemo)(() => ({
        selectedAccordionId: selectedId,
        isNested: nested,
        setActiveAccordion: handleOnChange,
    }), [selectedId]);
    return ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: (0, StyleUtils_1.classNames)('AccordionGroup-local', styles.container, className) }, dti, { children: (0, jsx_runtime_1.jsx)(AccordionGroupContext.Provider, Object.assign({ value: value }, { children: children })) })));
};
exports.AccordionGroup = AccordionGroup;
