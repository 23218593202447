"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getToastStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
exports.getToastStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    mainContainer: Object.assign({}, theme.variants.toasts.default),
    success: Object.assign({}, theme.variants.toasts.default.$success),
    error: Object.assign({}, theme.variants.toasts.default.$error),
    leftContent: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        maxWidth: '42px',
        minWidth: '42px',
        width: '42px',
    },
    rightContent: {
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'flex-start',
        maxWidth: '80vw',
        minWidth: '260px',
        overflow: 'hidden',
        padding: `${theme.spacing.$s4} ${theme.spacing.$s3}`,
        position: 'relative',
        width: '100%',
    },
    content: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
        width: '100%',
    },
    successBackground: {
        backgroundColor: theme.variants.toasts.default.$success.borderColor,
    },
    errorBackground: {
        backgroundColor: theme.variants.toasts.default.$error.borderColor,
    },
    icon: Object.assign({}, theme.variants.toasts.default.$icon),
    closeButton: Object.assign({}, theme.variants.toasts.default.$close),
    closeIcon: Object.assign({}, theme.variants.toasts.default.$closeIcon),
}), { name: 'Blueprint-Toast' });
