"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.textareaStyles = void 0;
const Utils_1 = require("../../../../utils/Utils");
const spacing_1 = require("../spacing");
const typography_1 = require("../typography");
const _rootPartial = Object.assign(Object.assign({}, typography_1.typographyValues.$textPrimary200), { backgroundColor: '$neutral100', borderColor: '$inputPrimaryDefault', borderRadius: '4px', borderStyle: 'solid', borderWidth: '1px', color: '$content100', margin: 0, padding: `6px ${spacing_1.spacingValues.$s2}`, width: '100%', 
    // Values below are objects to be used by the input component to reference theme values
    $label: {
        color: '$neutral500',
    }, $labelError: {
        color: '$error500',
    }, $hover: {
        backgroundColor: '$neutral100',
        borderColor: '$inputPrimaryHover',
        color: '$primary200',
    }, $disabled: {
        backgroundColor: '$inputPrimaryDisabled',
        borderColor: '$inputPrimaryDisabled',
        color: '$neutral500',
    }, $error: {
        backgroundColor: '$neutral100',
        borderColor: '$inputPrimaryError',
        color: '$inputPrimaryError',
    } });
exports.textareaStyles = {
    default: (0, Utils_1.constructVariantStyles)([_rootPartial]),
};
