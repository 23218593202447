"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getModalStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
exports.getModalStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    maskContainer: ({ styles, maskBackgroundColor }) => (Object.assign(Object.assign({}, styles.$mask), { backgroundColor: maskBackgroundColor !== null && maskBackgroundColor !== void 0 ? maskBackgroundColor : styles.$mask.backgroundColor, opacity: 0, '&$fadeIn': {
            opacity: 1,
            transition: 'opacity 0.1s linear',
        }, '&$visible': {
            opacity: 1,
        } })),
    mainContainer: ({ styles, contentBackgroundColor, minHeight }) => (Object.assign(Object.assign(Object.assign({}, styles), styles.$content), { height: 'fit-content', minHeight: minHeight ? 'fit-content' : styles.minHeight, display: 'flex', flexDirection: 'column', backgroundColor: contentBackgroundColor !== null && contentBackgroundColor !== void 0 ? contentBackgroundColor : styles.$content.backgroundColor, opacity: 0, '&$fadeIn': {
            opacity: 1,
            transition: 'opacity 0.125s linear',
        }, '&$visible': {
            opacity: 1,
        } })),
    header: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    closeButton: {
        margin: theme.spacing.$s3,
        width: 'fit-content',
        height: 'fit-content',
        backgroundColor: 'transparent',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    icon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '20px',
        height: '20px',
        '& path': {
            fill: theme.colors.$content100,
        },
    },
    mainWrapper: ({ styles, minHeight }) => ({
        height: minHeight ? `${minHeight}px` : styles.minHeight,
        overflowY: 'auto',
    }),
    fadeIn: {},
    visible: {},
}), { name: 'Blueprint-Modal' });
