"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Helmet = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_helmet_1 = require("react-helmet");
const Helmet = (props) => {
    const { children } = props;
    return (0, jsx_runtime_1.jsx)(react_helmet_1.Helmet, { children: children });
};
exports.Helmet = Helmet;
