"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSelectStyles = void 0;
const theme_1 = require("../../../themes/typings/theme");
const Utils_1 = require("../../../utils/Utils");
const StyleUtils_1 = require("../../../utils/StyleUtils");
exports.getSelectStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        width: '100%',
        zIndex: 1,
    },
    wrapper: {
        backgroundColor: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$primary400 : theme.colors.$neutral100,
        borderColor: theme.colors.$inputPrimaryDefault,
        borderRadius: theme.spacing.$s1,
        borderStyle: 'solid',
        borderWidth: '1px',
        height: '56px',
        margin: 0,
        padding: `${theme.spacing.$s1} ${theme.spacing.$s2}`,
        width: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        '&:hover': {
            borderColor: theme.colors.$inputPrimaryHover,
            color: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$primary200 : theme.colors.$primary200,
            cursor: 'pointer',
        },
        '&$focus': {
            cursor: 'pointer',
            outline: 'initial solid 1px',
        },
        '&$dropdownOpen': {
            borderRadius: `${theme.spacing.$s1} ${theme.spacing.$s1} 0 0 `,
            borderColor: theme.colors.$inputPrimaryHover,
            zIndex: 1,
        },
        '&$disabled': {
            backgroundColor: theme.colors.$inputPrimaryDisabled,
            borderColor: theme.colors.$inputPrimaryDisabled,
            color: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$neutral300 : theme.colors.$neutral500,
            cursor: 'not-allowed',
        },
        '&$hasError': {
            borderColor: theme.colors.$inputPrimaryError,
            color: theme.colors.$inputPrimaryError,
        },
    },
    hasError: {},
    focus: {},
    disabled: {},
    dropdownOpen: {},
    label: Object.assign(Object.assign({}, theme.typography.$textSecondary000), { color: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$neutral200 : theme.colors.$neutral500, '&:hover': {
            cursor: 'pointer',
        }, '&$disabled': {
            color: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$neutral300 : theme.colors.$neutral500,
            '&:hover': {
                cursor: 'not-allowed',
            },
        }, '&$hasError, &$disabled$hasError': {
            color: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$error100 : theme.colors.$error500,
        } }),
    hidden: {
        position: 'absolute',
        left: '-10000px',
        top: 'auto',
        width: '1px',
        height: '1px',
        overflow: 'hidden',
    },
    innerWrapper: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    content: Object.assign(Object.assign({}, theme.typography.$textPrimary200), { overflow: 'hidden', whiteSpace: 'nowrap', width: '100%' }),
    icon: {
        width: '30px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'all .3s ease',
        '& path': {
            fill: theme.colors.$content100,
        },
    },
    rotate: {
        transform: 'rotate(180deg)',
    },
    dropdownContainer: Object.assign(Object.assign({}, theme.shadows.$shadow100), { backgroundColor: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$primary400 : theme.colors.$neutral100, borderColor: theme.colors.$inputPrimaryHover, borderRadius: `0 0 0 ${theme.spacing.$s2}`, borderStyle: 'solid', borderWidth: '1px', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', height: 'auto', left: 0, margin: 0, maxHeight: '200px', overflowY: 'auto', position: 'absolute', right: '0px', visibility: 'hidden', width: '100%', '&$visible': {
            borderWidth: '1px',
            padding: `${theme.spacing.$s1} ${theme.spacing.$s2}`,
            visibility: 'visible',
        }, '&$hasError': {
            borderColor: theme.colors.$inputPrimaryError,
        }, '& .Select-option-focused': {
            '&:not(.Select-option-disabled)': {
                backgroundColor: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$primary200 : theme.colors.$neutral200,
                color: theme.colors.$content100,
                '&.Select-option-active': {
                    backgroundColor: theme.colors.$primary100,
                    color: theme.colors.$tertiary100,
                },
            },
        } }),
    helperContainer: Object.assign(Object.assign({}, theme.typography.$textSecondary000), { color: theme.colors.$neutral400, display: 'flex', justifyContent: 'flex-start', margin: `${theme.spacing.$s1} 0 0` }),
    errorMessage: {
        color: theme.colors.$inputPrimaryError,
    },
    searchInput: {
        outline: 'none',
        borderBottom: '2px solid transparent',
        background: 'transparent',
        '&:focus': {
            borderBottom: `2px solid ${theme.colors.$primary100}`,
        },
        '&:disabled': {
            cursor: 'not-allowed',
        },
    },
    noResultsContainer: Object.assign(Object.assign({}, (0, StyleUtils_1.flexMixin)('column')), { alignItems: 'flex-start', justifyContent: 'center', padding: theme.spacing.$s2, width: '100%' }),
    noResults: Object.assign({}, theme.typography.$textPrimary100),
    visible: {},
    [`@media (max-width: ${theme.breakpoints.$sm}px)`]: {},
}), { name: 'Blueprint-Select' });
