"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CloseIcon = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Utils_1 = require("../../utils/Utils");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const __DEV__ = process.env.NODE_ENV !== 'production';
const CloseIcon = (props) => {
    const theme = (0, ThemeProvider_1.useTheme)();
    if (!theme) {
        if (__DEV__) {
            console.error('`CloseIcon` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const { className, testId, fill = theme.colors.$primary500 } = props;
    return ((0, jsx_runtime_1.jsx)("svg", Object.assign({ className: className, viewBox: "0 0 38 38", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, (0, Utils_1.getTestIdAttribute)(testId), { children: (0, jsx_runtime_1.jsx)("path", { d: "M38 3.82714L34.1729 0L19 15.1729L3.82714 0L0 3.82714L15.1729 19L0 34.1729L3.82714 38L19 22.8271L34.1729 38L38 34.1729L22.8271 19L38 3.82714Z", fill: fill }) })));
};
exports.CloseIcon = CloseIcon;
