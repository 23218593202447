"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.linkStyles = void 0;
const Utils_1 = require("../../../../utils/Utils");
const color_1 = require("../../../typings/color");
const spacing_1 = require("../spacing");
const typography_1 = require("../typography");
const _rootPartial = Object.assign(Object.assign({}, typography_1.typographyValues.$textSecondary200), { backgroundColor: 'transparent', borderColor: 'transparent', borderRadius: '0', borderStyle: 'solid', borderWidth: '0', boxSizing: 'border-box', color: '$interactiveLinkDefault', padding: '$s0', textDecoration: 'underline', transition: 'background-color 300ms, border 300ms, color 300ms, opacity 300ms', whiteSpace: 'nowrap', width: 'fit-content', '&:hover': {
        cursor: 'pointer',
    }, '&:hover, &:focus-visible': {
        backgroundColor: 'transparent',
        color: '$interactiveLinkHover',
    }, '&:active': {
        backgroundColor: 'transparent',
        color: '$interactiveLinkPressed',
    }, '&:visited': {
        backgroundColor: 'transparent',
        color: '$interactiveLinkDefault',
        '&:hover, &:focus-visible': {
            backgroundColor: 'transparent',
            color: '$interactiveLinkHover',
        },
        '&:active': {
            backgroundColor: 'transparent',
            color: '$interactiveLinkPressed',
        },
    } });
const _navPartial = Object.assign(Object.assign({}, typography_1.typographyValues.$textPrimary100), { color: '$tertiary100', fontFamily: '$primaryFont', textDecoration: 'none', '&:hover, &:focus-visible': {
        backgroundColor: 'transparent',
        color: '$tertiary100',
    }, '&:active': {
        backgroundColor: 'transparent',
        color: '$tertiary100',
    }, '&:visited': {
        backgroundColor: 'transparent',
        color: '$tertiary100',
        '&:hover, &:focus-visible': {
            backgroundColor: 'transparent',
            color: '$tertiary100',
        },
        '&:active': {
            backgroundColor: 'transparent',
            color: '$tertiary100',
        },
    } });
const _subNavPartial = Object.assign(Object.assign({}, typography_1.typographyValues.$textPrimary050), { color: '$neutral100', fontFamily: '$primaryFont', textDecoration: 'underline', '&:hover, &:focus-visible': {
        backgroundColor: 'transparent',
        color: '$neutral100',
    }, '&:active': {
        backgroundColor: 'transparent',
        color: '$neutral100',
    }, '&:visited': {
        backgroundColor: 'transparent',
        color: '$neutral100',
        '&:hover, &:focus-visible': {
            backgroundColor: 'transparent',
            color: '$neutral100',
        },
        '&:active': {
            backgroundColor: 'transparent',
            color: '$neutral100',
        },
    } });
const _callToActionPartial = Object.assign(Object.assign({}, typography_1.typographyValues.$textSecondary100), { color: '$neutral200', textDecoration: 'none', backgroundColor: `${color_1.paletteColorValues.$secondary500}4D`, borderWidth: '2px', borderColor: '$interactiveLinkDefault', borderRadius: '4px', padding: spacing_1.spacingValues.$s2, '&:hover, &:focus-visible': {
        backgroundColor: `${color_1.paletteColorValues.$secondary500}4D`,
        borderColor: '$interactiveLinkHover',
        color: '$neutral200',
        textDecoration: 'underline',
    }, '&:active': {
        backgroundColor: `${color_1.paletteColorValues.$secondary500}4D`,
        borderColor: '$interactiveLinkPressed',
        color: '$neutral200',
    }, '&:visited': {
        backgroundColor: `${color_1.paletteColorValues.$secondary500}4D`,
        color: '$neutral200',
        '&:hover, &:focus-visible': {
            backgroundColor: `${color_1.paletteColorValues.$secondary500}4D`,
            borderColor: '$interactiveLinkHover',
            color: '$neutral200',
            textDecoration: 'underline',
        },
        '&:active': {
            backgroundColor: `${color_1.paletteColorValues.$secondary500}4D`,
            borderColor: '$interactiveLinkPressed',
            color: '$neutral200',
        },
    } });
exports.linkStyles = {
    default: (0, Utils_1.constructVariantStyles)([_rootPartial]),
    nav: (0, Utils_1.constructVariantStyles)([_rootPartial, _navPartial]),
    subNav: (0, Utils_1.constructVariantStyles)([_rootPartial, _subNavPartial]),
    callToAction: (0, Utils_1.constructVariantStyles)([_rootPartial, _callToActionPartial]),
};
