"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pill = exports.PillVariants = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const Utils_1 = require("../../utils/Utils");
const Pill_styles_1 = require("./styles/Pill.styles");
const __DEV__ = process.env.NODE_ENV !== 'production';
var PillVariants;
(function (PillVariants) {
    PillVariants["DEFAULT"] = "default";
})(PillVariants = exports.PillVariants || (exports.PillVariants = {}));
const Pill = ({ children, testId, id, className = '', localClassName = 'Pill-local', variant = PillVariants.DEFAULT, }) => {
    const theme = (0, ThemeProvider_1.useTheme)();
    if (!theme) {
        if (__DEV__) {
            console.error('`Pill` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const styles = (0, Pill_styles_1.getPillStyles)();
    const dti = (0, Utils_1.getTestIdAttribute)(testId);
    return ((0, jsx_runtime_1.jsx)("div", Object.assign({ id: id, className: `${localClassName} ${styles[variant]} ${className}` }, dti, { children: typeof children === 'string' ? (0, jsx_runtime_1.jsx)("span", Object.assign({ className: styles.label }, { children: children })) : children })));
};
exports.Pill = Pill;
