"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Toast = exports.ToastVariant = exports.ToastMode = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_intl_1 = require("react-intl");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const Utils_1 = require("../../utils/Utils");
const icons_1 = require("../../assets/icons");
const StyleUtils_1 = require("../../utils/StyleUtils");
const ToastProvider_1 = require("./ToastProvider");
const Toast_styles_1 = require("./styles/Toast.styles");
const __DEV__ = process.env.NODE_ENV !== 'production';
var ToastMode;
(function (ToastMode) {
    ToastMode["SUCCESS"] = "success";
    ToastMode["ERROR"] = "error";
})(ToastMode = exports.ToastMode || (exports.ToastMode = {}));
var ToastVariant;
(function (ToastVariant) {
    ToastVariant["DEFAULT"] = "default";
})(ToastVariant = exports.ToastVariant || (exports.ToastVariant = {}));
const Toast = (props) => {
    const { content, id, duration, mode = ToastMode.SUCCESS, testId, className = '' } = props;
    const theme = (0, ThemeProvider_1.useTheme)();
    const { removeToast } = (0, ToastProvider_1.useToasts)();
    const intl = (0, react_intl_1.useIntl)();
    if (!theme) {
        if (__DEV__) {
            console.error('`Toast` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    if (!intl) {
        if (__DEV__) {
            console.error('`Toast` component must be a child of a `IntlProvider`');
        }
        return null;
    }
    if (!removeToast) {
        if (__DEV__) {
            console.error('`Toast` component must be a child of a `ToastProvider`');
        }
        return null;
    }
    const isSuccess = mode === ToastMode.SUCCESS;
    const styles = (0, Toast_styles_1.getToastStyles)();
    const dti = (0, Utils_1.getTestIdAttribute)(testId);
    let toastTimeout;
    const handleOnClose = () => {
        removeToast(id);
    };
    const setTimeoutHandler = (cb) => {
        toastTimeout = setTimeout(() => cb(), duration);
    };
    const handleOnFocus = () => {
        if (toastTimeout)
            clearTimeout(toastTimeout);
    };
    const handleOnBlur = () => {
        if (isSuccess)
            setTimeoutHandler(handleOnClose);
    };
    const handleOnMouseEnter = () => {
        if (toastTimeout)
            clearTimeout(toastTimeout);
    };
    const handleOnMouseLeave = () => {
        if (isSuccess)
            setTimeoutHandler(handleOnClose);
    };
    (0, react_1.useEffect)(() => {
        if (isSuccess) {
            setTimeoutHandler(handleOnClose);
        }
    });
    const leftIcon = isSuccess ? ((0, jsx_runtime_1.jsx)(icons_1.SuccessCircleIcon, { className: (0, StyleUtils_1.classNames)(styles.icon) })) : ((0, jsx_runtime_1.jsx)(icons_1.InfoIcon, { className: (0, StyleUtils_1.classNames)(styles.icon) }));
    return ((0, jsx_runtime_1.jsxs)("div", Object.assign({ id: id, className: (0, StyleUtils_1.classNames)('Toast-local', styles.mainContainer, isSuccess ? styles.success : styles.error, className), onMouseEnter: handleOnMouseEnter, onMouseLeave: handleOnMouseLeave }, dti, { children: [(0, jsx_runtime_1.jsx)("div", Object.assign({ className: (0, StyleUtils_1.classNames)(styles.leftContent, mode === ToastMode.SUCCESS ? styles.successBackground : styles.errorBackground) }, { children: leftIcon })), (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: (0, StyleUtils_1.classNames)(styles.rightContent) }, { children: [!isSuccess && ((0, jsx_runtime_1.jsx)("button", Object.assign({ "aria-label": intl.formatMessage({
                            id: 'blueprint.toast.close.ariaLabel',
                            defaultMessage: 'close toast message',
                            description: 'Blueprint Toast close button aria label',
                        }), className: (0, StyleUtils_1.classNames)(styles.closeButton), onClick: handleOnClose, onFocus: handleOnFocus, onBlur: handleOnBlur }, { children: (0, jsx_runtime_1.jsx)(icons_1.CloseIcon, { className: (0, StyleUtils_1.classNames)('Toast-icon-local', styles.closeIcon) }) }))), (0, jsx_runtime_1.jsx)("div", Object.assign({ className: (0, StyleUtils_1.classNames)(styles.content) }, { children: content }))] }))] })));
};
exports.Toast = Toast;
