"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Link = exports.LinkVariants = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const StyleUtils_1 = require("../../utils/StyleUtils");
const Link_styles_1 = require("./styles/Link.styles");
const RawLink_1 = require("./RawLink");
const __DEV__ = process.env.NODE_ENV !== 'production';
var LinkVariants;
(function (LinkVariants) {
    LinkVariants["DEFAULT"] = "default";
    LinkVariants["NAV"] = "nav";
    LinkVariants["SUB_NAV"] = "subNav";
    LinkVariants["CALL_TO_ACTION"] = "callToAction";
})(LinkVariants = exports.LinkVariants || (exports.LinkVariants = {}));
const Link = (props) => {
    const { accessibilityLabel, children, className, disabled = false, doDownloadCheck, download, fullWidth = false, href = '', localClassName = 'Link-local', onClick, tabIndex = 0, variant = LinkVariants.DEFAULT } = props, additionalProps = __rest(props, ["accessibilityLabel", "children", "className", "disabled", "doDownloadCheck", "download", "fullWidth", "href", "localClassName", "onClick", "tabIndex", "variant"]);
    const theme = (0, ThemeProvider_1.useTheme)();
    if (!theme) {
        if (__DEV__) {
            console.error('`Link` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    let onClickOverride;
    if (!onClick && download && doDownloadCheck) {
        // If a download link goes to an invalid url, the user will get the 404 page -- which might be an ugly S3 error
        // or something equally off-putting. This handler checks that the url is valid (by doing a HEAD fetch on it),
        // and only allows the download to proceed if it is.
        // Since the fetch is async, the original event handler can’t do the download -- i.e., we can’t pause/resume the
        // original click event -- so it creates a temporary button pointed at the same url (but without the original
        // click-interceptor) and re-enacts the click there.
        //
        // This only happens if the caller passes in a `doDownloadCheck` callback -- which they should then use to handle
        // errors on their own.
        onClickOverride = (e) => {
            e.preventDefault();
            e.stopPropagation();
            const headCheckPromise = fetch(href, {
                method: 'HEAD',
            }).then(res => {
                if (res.ok) {
                    const downloadLink = document.createElement('a');
                    downloadLink.href = href;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    downloadLink.remove();
                }
                return res;
            });
            doDownloadCheck(headCheckPromise);
        };
    }
    const styles = (0, Link_styles_1.getLinkStyles)();
    return ((0, jsx_runtime_1.jsx)("span", Object.assign({ className: `${localClassName} ${styles.wrapper} ${className !== null && className !== void 0 ? className : ''} ${fullWidth ? styles.fullWidth : ''}` }, { children: (0, jsx_runtime_1.jsx)(RawLink_1.RawLink, Object.assign({ href: href, "aria-label": accessibilityLabel, onClick: onClickOverride !== null && onClickOverride !== void 0 ? onClickOverride : onClick, styles: styles, className: (0, StyleUtils_1.classNames)(styles[variant], fullWidth && styles.fullWidth, disabled && styles.disabled), tabIndex: tabIndex, disabled: disabled }, additionalProps, { children: children })) })));
};
exports.Link = Link;
