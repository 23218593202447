"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tooltip = exports.TooltipArrowSize = exports.TooltipPosition = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = __importStar(require("react"));
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const Utils_1 = require("../../utils/Utils");
const StyleUtils_1 = require("../../utils/StyleUtils");
const Tooltip_styles_1 = require("./styles/Tooltip.styles");
const TooltipProvider_1 = require("./TooltipProvider");
const __DEV__ = process.env.NODE_ENV !== 'production';
var TooltipPosition;
(function (TooltipPosition) {
    TooltipPosition["BOTTOM"] = "bottom";
    TooltipPosition["LEFT"] = "left";
    TooltipPosition["RIGHT"] = "right";
    TooltipPosition["TOP"] = "top";
})(TooltipPosition = exports.TooltipPosition || (exports.TooltipPosition = {}));
var TooltipArrowSize;
(function (TooltipArrowSize) {
    TooltipArrowSize[TooltipArrowSize["SM"] = 6] = "SM";
    TooltipArrowSize[TooltipArrowSize["MD"] = 10] = "MD";
    TooltipArrowSize[TooltipArrowSize["LG"] = 14] = "LG";
})(TooltipArrowSize = exports.TooltipArrowSize || (exports.TooltipArrowSize = {}));
const Tooltip = (props) => {
    const theme = (0, ThemeProvider_1.useTheme)();
    const tooltips = (0, TooltipProvider_1.useTooltips)();
    const { arrow = false, arrowSize = TooltipArrowSize.MD, children, className = '', content, delay = 450, gap = 12, position = TooltipPosition.TOP, testId, } = props;
    if (!react_1.default.isValidElement(children)) {
        if (__DEV__) {
            console.error('`children` are required and must be a valid React element');
        }
        return null;
    }
    if (!content && !react_1.default.isValidElement(content)) {
        if (__DEV__) {
            console.error('`content` is required and must be a valid React element');
        }
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: children });
    }
    if (!theme) {
        if (__DEV__) {
            console.error('`Tooltip` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    if (!tooltips) {
        if (__DEV__) {
            console.error('`Tooltip` component must be a child of a `TooltipProvider`');
        }
        return null;
    }
    const containerRef = (0, react_1.useRef)();
    const popperRef = (0, react_1.useRef)();
    const arrowRef = (0, react_1.useRef)();
    const styles = (0, Tooltip_styles_1.getTooltipStyles)({ arrowSize });
    const dtiList = (0, Utils_1.getTestIdAttribute)(testId);
    const { addTooltip, removeTooltip } = tooltips;
    const timeout = (0, react_1.useRef)();
    const id = react_1.default.useMemo(() => (0, Utils_1.generateUUID)(), []);
    // Padding in `px` that an overflowing tooltip will add from the screen / window edge
    const tooltipOverflowPadding = 16;
    const handleArrowPlacement = (dir) => {
        if (arrowRef.current && containerRef.current && popperRef.current) {
            if (dir === 'left') {
                // to center tooltip, get container left position from window and subtract overflow padding
                // add half the container width, which allows for the styles to apply a transform: translateX(-50%)
                arrowRef.current.style.left = `${containerRef.current.getBoundingClientRect().left -
                    tooltipOverflowPadding +
                    containerRef.current.offsetWidth / 2}px`;
            }
            else {
                // to center tooltip, subtract the left position of the container and the popper
                // add half the container width, which allows for the styles to apply a transform: translateX(-50%)
                arrowRef.current.style.left = `${Math.abs(popperRef.current.getBoundingClientRect().left - containerRef.current.getBoundingClientRect().left) +
                    containerRef.current.offsetWidth / 2}px`;
            }
        }
    };
    const confirmBounds = (isCheck = false) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
        if (popperRef.current) {
            switch (position) {
                case TooltipPosition.TOP:
                    if (popperRef.current && containerRef.current) {
                        popperRef.current.style.left = `${containerRef.current.getBoundingClientRect().left +
                            containerRef.current.offsetWidth / 2 -
                            popperRef.current.offsetWidth / 2}px`;
                        if (popperRef.current.getBoundingClientRect().left <= 0) {
                            popperRef.current.style.left = `${tooltipOverflowPadding}px`;
                            handleArrowPlacement('left');
                        }
                        if (popperRef.current.getBoundingClientRect().right >= window.innerWidth) {
                            popperRef.current.style.left = `${window.innerWidth - popperRef.current.offsetWidth - tooltipOverflowPadding}px`;
                            handleArrowPlacement('right');
                        }
                        // check if top of popper is in window bounds
                        if (popperRef.current.getBoundingClientRect().top >= 0) {
                            popperRef.current.style.top = `${containerRef.current.getBoundingClientRect().top - popperRef.current.offsetHeight - gap}px`;
                            (_a = arrowRef.current) === null || _a === void 0 ? void 0 : _a.classList.remove(styles.bottom);
                            (_b = arrowRef.current) === null || _b === void 0 ? void 0 : _b.classList.add(styles.top);
                        }
                        else {
                            popperRef.current.style.top = `${containerRef.current.getBoundingClientRect().bottom + gap}px`;
                            (_c = arrowRef.current) === null || _c === void 0 ? void 0 : _c.classList.remove(styles.top);
                            (_d = arrowRef.current) === null || _d === void 0 ? void 0 : _d.classList.add(styles.bottom);
                        }
                    }
                    break;
                case TooltipPosition.RIGHT:
                    if (popperRef.current && containerRef.current) {
                        const offsetHeight = containerRef.current.offsetHeight / 2 - popperRef.current.offsetHeight / 2;
                        popperRef.current.style.top = `${containerRef.current.getBoundingClientRect().top + offsetHeight}px`;
                        // check if right of popper is in window bounds
                        if (popperRef.current.getBoundingClientRect().right <= window.innerWidth) {
                            popperRef.current.style.left = `${containerRef.current.getBoundingClientRect().right + gap}px`;
                            (_e = arrowRef.current) === null || _e === void 0 ? void 0 : _e.classList.remove(styles.right);
                            (_f = arrowRef.current) === null || _f === void 0 ? void 0 : _f.classList.add(styles.left);
                        }
                        else {
                            popperRef.current.style.left = `${containerRef.current.getBoundingClientRect().left - popperRef.current.offsetWidth - gap}px`;
                            (_g = arrowRef.current) === null || _g === void 0 ? void 0 : _g.classList.remove(styles.left);
                            (_h = arrowRef.current) === null || _h === void 0 ? void 0 : _h.classList.add(styles.right);
                        }
                    }
                    break;
                case TooltipPosition.LEFT:
                    if (popperRef.current && containerRef.current) {
                        const offsetHeight = containerRef.current.offsetHeight / 2 - popperRef.current.offsetHeight / 2;
                        popperRef.current.style.top = `${containerRef.current.getBoundingClientRect().top + offsetHeight}px`;
                        // check if left of popper is in window bounds
                        if (popperRef.current.getBoundingClientRect().left > 0) {
                            popperRef.current.style.left = `${containerRef.current.getBoundingClientRect().left - popperRef.current.offsetWidth - gap}px`;
                            (_j = arrowRef.current) === null || _j === void 0 ? void 0 : _j.classList.remove(styles.left);
                            (_k = arrowRef.current) === null || _k === void 0 ? void 0 : _k.classList.add(styles.right);
                        }
                        else {
                            popperRef.current.style.left = `${containerRef.current.getBoundingClientRect().right + gap}px`;
                            (_l = arrowRef.current) === null || _l === void 0 ? void 0 : _l.classList.remove(styles.right);
                            (_m = arrowRef.current) === null || _m === void 0 ? void 0 : _m.classList.add(styles.left);
                        }
                    }
                    break;
                case TooltipPosition.BOTTOM:
                    if (popperRef.current && containerRef.current) {
                        popperRef.current.style.left = `${containerRef.current.getBoundingClientRect().left +
                            containerRef.current.offsetWidth / 2 -
                            popperRef.current.offsetWidth / 2}px`;
                        if (popperRef.current.getBoundingClientRect().left <= 0) {
                            popperRef.current.style.left = `${tooltipOverflowPadding}px`;
                            handleArrowPlacement('left');
                        }
                        if (popperRef.current.getBoundingClientRect().right >= window.innerWidth) {
                            popperRef.current.style.left = `${window.innerWidth - popperRef.current.offsetWidth - tooltipOverflowPadding}px`;
                            handleArrowPlacement('right');
                        }
                        // check if bottom of popper is in window bounds
                        if (((_o = popperRef.current) === null || _o === void 0 ? void 0 : _o.getBoundingClientRect().bottom) <= window.innerHeight) {
                            popperRef.current.style.top = `${containerRef.current.getBoundingClientRect().bottom + gap}px`;
                            (_p = arrowRef.current) === null || _p === void 0 ? void 0 : _p.classList.remove(styles.top);
                            (_q = arrowRef.current) === null || _q === void 0 ? void 0 : _q.classList.add(styles.bottom);
                        }
                        else {
                            popperRef.current.style.top = `${containerRef.current.getBoundingClientRect().top - popperRef.current.offsetHeight - gap}px`;
                            (_r = arrowRef.current) === null || _r === void 0 ? void 0 : _r.classList.remove(styles.bottom);
                            (_s = arrowRef.current) === null || _s === void 0 ? void 0 : _s.classList.add(styles.top);
                        }
                    }
                    break;
                default:
                    break;
            }
            // recursive check to ensure the tooltip is in view
            // mainly used for initial render but allows for a double check given scroll and other interactions
            if (!isCheck)
                confirmBounds(true);
        }
    };
    const showTip = () => {
        if (timeout.current)
            clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {
            renderTooltipToDOM();
            // allow for any existing tooltips to be removed before adding the new
        }, delay + 25);
    };
    const hideTip = () => {
        if (timeout.current)
            clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {
            removeTooltipFromDOM();
        }, delay);
    };
    const childrenProps = Object.assign(Object.assign({}, children.props), { onMouseEnter: () => {
            if (children.props.onMouseEnter)
                children.props.onMouseEnter();
            showTip();
        }, onMouseLeave: () => {
            if (children.props.onMouseLeave)
                children.props.onMouseLeave();
            hideTip();
        }, onFocus: () => {
            if (children.props.onFocus)
                children.props.onFocus();
            showTip();
        }, onBlur: () => {
            if (children.props.onBlur)
                children.props.onBlur();
            hideTip();
        } });
    const handlePersistTooltip = () => {
        showTip();
    };
    const handleHideTooltip = () => {
        hideTip();
    };
    const tooltipContent = ((0, jsx_runtime_1.jsx)("div", Object.assign({ role: "presentation", ref: popperRef, className: (0, StyleUtils_1.classNames)('Tooltip-popper-local', styles.contentPopper, styles.hidden, className) }, (0, Utils_1.getTestIdAttribute)('tt-popper'), { onMouseEnter: handlePersistTooltip, onMouseLeave: handleHideTooltip, onFocus: handlePersistTooltip, onBlur: handleHideTooltip }, { children: (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: (0, StyleUtils_1.classNames)('Tooltip-container-local', styles.contentContainer) }, { children: [(0, jsx_runtime_1.jsx)("div", Object.assign({ className: (0, StyleUtils_1.classNames)('Tooltip-content-local', styles.contentWrapper) }, { children: content })), arrow && ((0, jsx_runtime_1.jsx)("span", Object.assign({ ref: arrowRef, className: (0, StyleUtils_1.classNames)('Tooltip-arrow-local', styles.arrow, styles[position]) }, (0, Utils_1.getTestIdAttribute)('tt-arrow'))))] })) })));
    const renderTooltipToDOM = () => {
        addTooltip(tooltipContent, id, confirmBounds);
        setTimeout(() => {
            if (popperRef.current) {
                popperRef.current.classList.remove(styles.hidden);
            }
        }, 100);
    };
    const removeTooltipFromDOM = () => {
        removeTooltip(id);
    };
    (0, react_1.useEffect)(() => {
        confirmBounds();
        window.addEventListener('scroll', () => confirmBounds());
        window.addEventListener('resize', () => confirmBounds());
        return () => {
            window.removeEventListener('scroll', () => confirmBounds());
            window.removeEventListener('resize', () => confirmBounds());
        };
    }, []);
    return ((0, jsx_runtime_1.jsx)("span", Object.assign({ ref: containerRef, className: (0, StyleUtils_1.classNames)('Tooltip-local', styles.mainContainer, className) }, dtiList, { children: react_1.default.cloneElement(children, childrenProps) })));
};
exports.Tooltip = Tooltip;
