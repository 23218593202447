"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FooterSection = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Divider_1 = require("../Divider/Divider");
const Link_1 = require("../Link/Link");
const FooterSection_styles_1 = require("./styles/FooterSection.styles");
const FooterSectionItem_1 = require("./FooterSectionItem");
const FooterSectionItem_styles_1 = require("./styles/FooterSectionItem.styles");
const addressDetails = {
    street: '31 New Chardon Street',
    city: 'Boston, MA 02114',
    tel: '(855) 476-6291',
    email: 'hello@groma.com',
};
const FooterSection = (props) => {
    const { section, isLocationDetails = false, domains } = props;
    const styles = (0, FooterSection_styles_1.getFooterSectionStyles)();
    const itemStyles = (0, FooterSectionItem_styles_1.getFooterSectionItemStyle)();
    if (section.render)
        return section.render(props);
    return !isLocationDetails ? ((0, jsx_runtime_1.jsxs)("div", Object.assign({ id: section.id, className: `FooterSection-local ${styles.container}` }, { children: [(0, jsx_runtime_1.jsx)("p", Object.assign({ className: styles.title }, { children: section.title })), (0, jsx_runtime_1.jsx)(Divider_1.Divider, { className: styles.divider }), (0, jsx_runtime_1.jsx)("div", Object.assign({ className: `FooterSection-contentContainer-local ${styles.contentContainer}` }, { children: (0, jsx_runtime_1.jsx)("ul", { children: section.items.map(item => ((0, jsx_runtime_1.jsx)(FooterSectionItem_1.FooterSectionItem, { item: item, domains: domains }, item.id))) }) }))] }))) : ((0, jsx_runtime_1.jsxs)("div", Object.assign({ id: section.id, className: styles.container }, { children: [(0, jsx_runtime_1.jsx)("p", Object.assign({ className: styles.title }, { children: section.title })), (0, jsx_runtime_1.jsx)(Divider_1.Divider, { className: styles.divider }), (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: styles.contentContainer }, { children: [(0, jsx_runtime_1.jsx)("p", Object.assign({ className: styles.staticContent }, { children: addressDetails.street })), (0, jsx_runtime_1.jsx)("p", Object.assign({ className: styles.staticContent }, { children: addressDetails.city })), (0, jsx_runtime_1.jsx)(Link_1.Link, Object.assign({ href: `tel:${addressDetails.tel}`, variant: Link_1.LinkVariants.NAV, className: itemStyles.link, useRouter: false }, { children: addressDetails.tel })), (0, jsx_runtime_1.jsx)(Link_1.Link, Object.assign({ href: `mailto:${addressDetails.email}`, variant: Link_1.LinkVariants.NAV, className: itemStyles.link, useRouter: false }, { children: addressDetails.email }))] }))] })));
};
exports.FooterSection = FooterSection;
