"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.inputStyles = void 0;
const Utils_1 = require("../../../../utils/Utils");
const typography_1 = require("../typography");
const spacing_1 = require("../spacing");
const _rootPartial = Object.assign(Object.assign({}, typography_1.typographyValues.$textPrimary200), { backgroundColor: '$primary400', borderColor: '$inputPrimaryDefault', borderRadius: '4px', borderStyle: 'solid', borderWidth: '1px', color: '$content100', height: '56px', margin: 0, padding: `6px ${spacing_1.spacingValues.$s2}`, width: '100%', 
    // Values below are objects to be used by the input component to reference theme values
    $label: {
        color: '$neutral200',
    }, $labelError: {
        color: '$error100',
    }, $hover: {
        backgroundColor: '$primary400',
        borderColor: '$inputPrimaryHover',
        color: '$primary200',
    }, $disabled: {
        backgroundColor: '$inputPrimaryDisabled',
        borderColor: '$inputPrimaryDisabled',
        color: '$neutral300',
    }, $error: {
        backgroundColor: '$primary400',
        borderColor: '$inputPrimaryError',
        color: '$inputPrimaryError',
    } });
exports.inputStyles = {
    default: (0, Utils_1.constructVariantStyles)([_rootPartial]),
};
