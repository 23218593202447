"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSubFooterStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
exports.getSubFooterStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        width: '100%',
        minWidth: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing.$s4,
        '&$mobile': {
            flexDirection: 'column',
            justifyContent: 'center',
            padding: `${theme.spacing.$s4} ${theme.spacing.$s5}`,
        },
    },
    links: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    branding: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '&$mobile': {
            justifyContent: 'space-between',
            marginBottom: theme.spacing.$s4,
        },
    },
    linkWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    link: Object.assign(Object.assign({ flexDirection: 'row', '&.Link-local': {
            height: 'fit-content',
        } }, theme.variants.footers.default.$subLink), { '& a': Object.assign(Object.assign({}, theme.variants.footers.default.$subLink['& a']), { marginRight: theme.spacing.$s2 }), '&$mobile': Object.assign(Object.assign({}, theme.variants.footers.default.$subLink), { '& a': Object.assign(Object.assign({}, theme.variants.footers.default.$subLink['& a']), { padding: 0, marginRight: theme.spacing.$s2 }) }) }),
    copyright: Object.assign(Object.assign({}, theme.typography.$textPrimary050), { color: theme.colors.$neutral100, display: 'flex', justifyContent: 'center', width: '100%', padding: theme.spacing.$s2, '&$mobile': {
            justifyContent: 'flex-start',
            padding: `0 0 ${theme.spacing.$s2} 0`,
        } }),
    facebookIcon: {
        width: '24px',
        height: '24px',
    },
    mobile: {},
}), { name: 'Blueprint-SubFooter' });
