"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRadioStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
const StyleUtils_1 = require("../../../utils/StyleUtils");
exports.getRadioStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    container: Object.assign(Object.assign({}, (0, StyleUtils_1.flexMixin)('row', 'flex-start', 'center')), { position: 'relative', width: 'fit-content', margin: `${theme.spacing.$s2} 0` }),
    inputContainer: Object.assign(Object.assign({}, (0, StyleUtils_1.flexMixin)('row', 'center', 'center')), { position: 'relative', width: 'fit-content', '&$focus': Object.assign({}, theme.shadows.$dropShadow200) }),
    input: Object.assign(Object.assign({}, (0, StyleUtils_1.flexMixin)()), { bottom: 0, left: 0, opacity: 0, position: 'absolute', right: 0, top: 0, '&:not($disabled):hover': {
            cursor: 'pointer',
        }, '&:focus-visible': {
            // target sibling icon for focus-visible
            '& ~ span > $icon': {
                outline: '2px solid blue',
            },
        } }),
    iconContainer: Object.assign(Object.assign({}, (0, StyleUtils_1.flexMixin)('row', 'center', 'center')), { width: 'fit-content', height: 'fit-content', marginRight: theme.spacing.$s2 }),
    label: Object.assign(Object.assign(Object.assign({}, (0, StyleUtils_1.flexMixin)('row', 'flex-start', 'center')), theme.typography.$textPrimary100), { '&$disabled': {
            color: theme.colors.$interactivePrimaryDisabled,
        }, '&:not($disabled):hover': {
            cursor: 'pointer',
        } }),
    icon: Object.assign(Object.assign({}, (0, StyleUtils_1.flexMixin)('row', 'center', 'center')), { width: '20px', height: '20px', borderRadius: '20px', '& path': {
            fill: theme.colors.$interactivePrimaryDefault,
            outline: 'none',
        }, '&$disabled': {
            '& path': {
                fill: theme.colors.$interactivePrimaryDisabled,
            },
        } }),
    disabled: {},
    focus: {},
}), { name: 'Blueprint-Radio' });
