"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Firebase = void 0;
const app_1 = require("firebase/app");
const analytics_1 = require("firebase/analytics");
class Firebase {
    constructor(props) {
        // eslint-disable-next-line no-constructor-return
        if (Firebase.instance)
            return Firebase.instance;
        this.initializeFirebase(props);
        Firebase.instance = this;
    }
    initializeFirebase(firebaseConfig) {
        Firebase.app = (0, app_1.initializeApp)(firebaseConfig);
        Firebase.analytics = (0, analytics_1.getAnalytics)(Firebase.app);
    }
    fireEvent(eventName, eventParams = {}) {
        if (Firebase.analytics) {
            (0, analytics_1.logEvent)(Firebase.analytics, eventName, eventParams);
        }
    }
}
exports.Firebase = Firebase;
Firebase.instance = undefined;
