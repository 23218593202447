"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableRow = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const Utils_1 = require("../../utils/Utils");
const TableRow_styles_1 = require("./styles/TableRow.styles");
const Table_1 = require("./Table");
const __DEV__ = process.env.NODE_ENV !== 'production';
const TableRow = (props) => {
    const { children, className = '', testId } = props;
    const theme = (0, ThemeProvider_1.useTheme)();
    const table = (0, Table_1.useTable)();
    const tableSubContext = (0, react_1.useContext)(Table_1.TableSubContext);
    const styles = (0, TableRow_styles_1.getTableRowStyles)();
    const { variant } = tableSubContext;
    const { showStriped } = table;
    if (!theme) {
        if (__DEV__) {
            console.error('`TableRow` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    if (!table) {
        if (__DEV__) {
            console.error('`TableRow` component must be a child of a `Table` component');
        }
        return null;
    }
    if (!tableSubContext) {
        if (__DEV__) {
            console.error('`TableRow` component must be a child of a `TableHead` or `TableBody` component');
        }
        return null;
    }
    const dti = (0, Utils_1.getTestIdAttribute)((testId !== null && testId !== void 0 ? testId : variant === Table_1.TableVariantType.HEAD) ? 'headTableRow' : 'bodyTableRow');
    return ((0, jsx_runtime_1.jsx)("tr", Object.assign({ className: `TableRow-local ${styles.container} ${showStriped ? styles.striped : ''} ${className}` }, dti, { children: children })));
};
exports.TableRow = TableRow;
