import { User } from '@groma-dev/scaffold';
import { AppConfig } from '../AppConfig';

export const ScaffoldClientHeaders = {
  'Content-Type': `vnd.groma.${AppConfig.Settings.Server.apiVersion}+json`,
  Accept: `vnd.groma.${AppConfig.Settings.Server.apiVersion}+json`,
};

export interface UserStateInterface {
  fetchingUser: boolean;
  user?: User;
}
