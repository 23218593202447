"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NavDropdown = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const icons_1 = require("../../assets/icons");
const Utils_1 = require("../../utils/Utils");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const NavDropdown_styles_1 = require("./styles/NavDropdown.styles");
const SubNavItem_1 = require("./SubNavItem");
const SubNavDropdown = ({ navItem, domains }) => {
    var _a, _b;
    const styles = (0, NavDropdown_styles_1.getNavDropdownStyles)();
    const parent = document.getElementById(navItem.id);
    const width = 175;
    const [offset, setOffset] = (0, react_1.useState)(width - ((_a = parent === null || parent === void 0 ? void 0 : parent.offsetWidth) !== null && _a !== void 0 ? _a : 0));
    (0, react_1.useEffect)(() => {
        if (parent) {
            setOffset(width - parent.offsetWidth);
        }
    });
    return ((0, jsx_runtime_1.jsx)("ul", { children: (0, jsx_runtime_1.jsx)("div", Object.assign({ className: `NavDropdown-dropdownContentContainer-local ${styles.dropdownContentContainer}`, style: {
                left: `-${offset / 2}px`,
                width: `${width}px`,
            } }, { children: (_b = navItem === null || navItem === void 0 ? void 0 : navItem.subNavItems) === null || _b === void 0 ? void 0 : _b.map((subNavItem) => {
                return (0, jsx_runtime_1.jsx)(SubNavItem_1.SubNavItem, { navItem: subNavItem, domains: domains }, subNavItem.id);
            }) })) }));
};
const NavDropdown = (props) => {
    var _a, _b;
    const { navItem, domains } = props;
    const styles = (0, NavDropdown_styles_1.getNavDropdownStyles)();
    const dropdownRef = (0, react_1.createRef)();
    const dropdownWrapperRef = (0, react_1.createRef)();
    const { pathname } = (0, Utils_1.parseLocation)((0, ThemeProvider_1.useThemeRouter)().router);
    const childLinkActive = (_a = navItem === null || navItem === void 0 ? void 0 : navItem.subNavItems) === null || _a === void 0 ? void 0 : _a.find(item => pathname.includes(item.href));
    const isActive = childLinkActive || pathname.includes((_b = navItem.href) !== null && _b !== void 0 ? _b : '');
    const handleShowSubNav = () => {
        setShowSubNav(true);
    };
    const handleHideSubNav = (event) => {
        if (!event.relatedTarget)
            setShowSubNav(false);
        if (dropdownRef.current && event.relatedTarget) {
            const isRelatedTarget = dropdownRef.current.contains(event.relatedTarget.parentElement);
            if (showSubNav && !isRelatedTarget) {
                setShowSubNav(false);
            }
        }
    };
    const [showSubNav, setShowSubNav] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        setShowSubNav(false);
    }, [pathname]);
    return ((0, jsx_runtime_1.jsx)("li", { children: (0, jsx_runtime_1.jsxs)("div", Object.assign({ ref: dropdownRef, role: "button", tabIndex: 0, id: navItem.id, onFocus: handleShowSubNav, onMouseEnter: handleShowSubNav, onMouseLeave: handleHideSubNav, onBlur: handleHideSubNav, className: `${styles.dropdownContainer}` }, { children: [(0, jsx_runtime_1.jsxs)("div", Object.assign({ className: styles.navItem }, { children: [(0, jsx_runtime_1.jsx)("span", Object.assign({ className: `${styles.dropdownItem} ${isActive ? styles.activeNav : ''}` }, { children: navItem.label })), (0, jsx_runtime_1.jsx)(icons_1.DownIcon, { className: styles.dropdownIcon })] })), (0, jsx_runtime_1.jsx)("div", Object.assign({ ref: dropdownWrapperRef, className: styles.dropdownWrapper }, { children: showSubNav && (0, jsx_runtime_1.jsx)(SubNavDropdown, { navItem: navItem, domains: domains }) }))] })) }));
};
exports.NavDropdown = NavDropdown;
