"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getHeaderStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
const theme_1 = require("../../../themes/typings/theme");
const StyleUtils_1 = require("../../../utils/StyleUtils");
exports.getHeaderStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    headerContainer: Object.assign(Object.assign(Object.assign({}, (0, StyleUtils_1.zIndexMixin)(1000)), theme.shadows.$shadow200), { backgroundColor: theme.colors.$background100, display: 'flex', width: '100%' }),
    stickyHeader: {
        position: 'sticky',
        top: '0px',
    },
    mainContainer: {
        position: 'relative',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
    },
    mainWrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr 2fr 1fr',
        margin: '0px 24px',
        maxWidth: `${StyleUtils_1.maxContainerWidth}px`,
        width: '100%',
        height: '100%',
    },
    leftContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
        width: '100%',
    },
    centerContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
    },
    rightContent: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        paddingRight: theme.spacing.$s5,
        width: '100%',
    },
    logoLink: {
        padding: 0,
        marginLeft: theme.spacing.$s4,
        '& a': {
            padding: 0,
        },
    },
    logo: {
        height: '55px',
        width: 'auto',
    },
    menuButton: {
        alignItems: 'center',
        backgroundColor: 'transparent',
        display: 'flex',
        height: 'fit-content',
        justifyContent: 'center',
        marginLeft: theme.spacing.$s3,
        '&:hover': {
            cursor: 'pointer',
        },
        width: '25px',
    },
    menuIcon: {
        '& path': {
            fill: theme.colors.$content100,
        },
    },
    closeIcon: {
        '& path': {
            fill: theme.colors.$content100,
        },
    },
    loginButton: {
        textTransform: 'uppercase',
    },
    loginButtonContainer: Object.assign(Object.assign({ position: 'relative', backgroundColor: 'transparent', padding: `${theme.spacing.$s3} ${theme.spacing.$s3}` }, theme.typography.$textPrimary250), { color: theme.colors.$content100, borderRadius: '4px', textTransform: 'uppercase', '&:hover, &:focus': {
            backgroundColor: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$primary200 : theme.colors.$tertiary200,
            cursor: 'pointer',
        } }),
    hidden: {
        display: 'none',
    },
    loginDropdown: Object.assign({ position: 'absolute', top: '100%', 
        // Rough estimate to center dropdown
        right: `-${(150 - 126) / 2}px`, backgroundColor: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$background300 : theme.colors.$background200, borderRadius: '0 0 4px 4px', display: 'flex', width: '150px', flexDirection: 'column', alignItems: 'center' }, theme.shadows.$shadow100),
    portalLink: {
        '& a': {
            padding: theme.spacing.$s4,
            '&:hover, &:focus': {
                color: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$interactiveLinkHover : theme.colors.$secondary500,
            },
            '&:visited': {
                '&:hover, &:focus': {
                    color: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$interactiveLinkHover : theme.colors.$secondary500,
                },
            },
            '&:active': {
                color: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$interactiveLinkDefault : theme.colors.$secondary400,
            },
        },
        '&:not(:last-child)': {
            borderBottom: `1px solid ${theme.colors.$content100}`,
        },
    },
    accountContainer: {
        width: 'fit-content',
        position: 'relative',
        height: 'fit-content',
        marginLeft: theme.spacing.$s3,
    },
    accountButton: {
        '&.Button-local': {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$neutral100 : theme.colors.$primary300,
            borderRadius: '50px',
            width: 'fit-content',
            height: 'fit-content',
            transition: 'none',
            '& button': {
                backgroundColor: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$neutral100 : theme.colors.$primary300,
                borderRadius: '50px',
                width: '50px',
                height: '50px',
                padding: 0,
                '&:hover, &:focus': {
                    backgroundColor: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$neutral100 : theme.colors.$primary300,
                },
            },
        },
    },
    loadingSpinner: {
        width: 'fit-content',
        justifySelf: 'flex-end',
    },
    [`@media (max-width: ${theme.breakpoints.$lg}px)`]: {
        loginDropdown: {
            right: 0,
        },
    },
    [`@media (max-width: ${theme.breakpoints.$md}px)`]: {
        rightContent: {
            padding: 0,
        },
    },
    [`@media (max-width: ${theme.breakpoints.$sm}px)`]: {
        logoLink: {
            marginLeft: theme.spacing.$s3,
        },
        logo: {
            height: '45px',
        },
    },
}), { name: 'Blueprint-Header' });
