"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDividerStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
exports.getDividerStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    dividerContainer: {
        width: '100%',
    },
    [`@media (max-width: ${theme.breakpoints.$sm}px)`]: {},
}), { name: 'Blueprint-Divider' });
