"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAccordionDetailsStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
const StyleUtils_1 = require("../../../utils/StyleUtils");
exports.getAccordionDetailsStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    container: Object.assign(Object.assign(Object.assign({}, (0, StyleUtils_1.flexMixin)('column')), theme.typography.$textPrimary100), { height: 'auto', padding: `${theme.spacing.$s3} ${theme.spacing.$s3}`, width: '100%', minHeight: '0px' }),
}), {
    name: 'Blueprint-AccordionDetails',
});
