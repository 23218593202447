"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.typographyValues = void 0;
const Utils_1 = require("../../../utils/Utils");
const _rootPartial = {
    color: '$content100',
    fontFamily: '$primaryFont',
    fontStyle: 'normal',
    margin: 0,
    textTransform: 'none',
};
const _rootFontWeights = {
    light: {
        fontWeight: '100',
    },
    medium: {
        fontWeight: '400',
    },
    semiBold: {
        fontWeight: '600',
    },
    bold: {
        fontWeight: '700',
    },
};
const _textTransform = {
    uppercase: {
        textTransform: 'uppercase',
    },
};
const _textPrimary7Partial = {
    fontSize: '92px',
    lineHeight: '114px',
};
const _textPrimary6Partial = {
    fontSize: '52px',
    lineHeight: '64px',
};
const _textPrimary5Partial = {
    fontSize: '36px',
    lineHeight: '46px',
};
const _textPrimary4Partial = {
    fontSize: '28px',
    lineHeight: '34px',
};
const _textPrimary3Partial = {
    fontSize: '22px',
    lineHeight: '32px',
};
const _textPrimary2Partial = {
    fontSize: '18px',
    lineHeight: '28px',
};
const _textPrimary1Partial = {
    fontSize: '14px',
    lineHeight: '24px',
};
const _textPrimary0Partial = {
    fontSize: '12px',
    lineHeight: '16px',
};
const _textSecondary2Partial = {
    fontFamily: '$secondaryFont',
    fontSize: '16px',
    lineHeight: '21px',
};
const _textSecondary1Partial = {
    fontFamily: '$secondaryFont',
    fontSize: '14px',
    lineHeight: '18px',
};
const _textSecondary0Partial = {
    fontFamily: '$secondaryFont',
    fontSize: '10px',
    lineHeight: '13px',
};
const _textSubtext1Partial = {
    fontSize: '10px',
    lineHeight: '12px',
};
const _textSubtext0Partial = {
    fontSize: '8px',
    lineHeight: '10px',
};
exports.typographyValues = {
    $textPrimary750: (0, Utils_1.constructVariantStyles)([_rootPartial, _textPrimary7Partial, _rootFontWeights.bold]),
    $textPrimary725: (0, Utils_1.constructVariantStyles)([_rootPartial, _textPrimary7Partial, _rootFontWeights.medium]),
    $textPrimary700: (0, Utils_1.constructVariantStyles)([_rootPartial, _textPrimary7Partial, _rootFontWeights.light]),
    $textPrimary650: (0, Utils_1.constructVariantStyles)([_rootPartial, _textPrimary6Partial, _rootFontWeights.bold]),
    $textPrimary625: (0, Utils_1.constructVariantStyles)([_rootPartial, _textPrimary6Partial, _rootFontWeights.medium]),
    $textPrimary600: (0, Utils_1.constructVariantStyles)([_rootPartial, _textPrimary6Partial, _rootFontWeights.light]),
    $textPrimary550: (0, Utils_1.constructVariantStyles)([_rootPartial, _textPrimary5Partial, _rootFontWeights.bold]),
    $textPrimary525: (0, Utils_1.constructVariantStyles)([_rootPartial, _textPrimary5Partial, _rootFontWeights.medium]),
    $textPrimary500: (0, Utils_1.constructVariantStyles)([_rootPartial, _textPrimary5Partial, _rootFontWeights.light]),
    $textPrimary450: (0, Utils_1.constructVariantStyles)([_rootPartial, _textPrimary4Partial, _rootFontWeights.bold]),
    $textPrimary425: (0, Utils_1.constructVariantStyles)([_rootPartial, _textPrimary4Partial, _rootFontWeights.medium]),
    $textPrimary400: (0, Utils_1.constructVariantStyles)([_rootPartial, _textPrimary4Partial, _rootFontWeights.light]),
    $textPrimary350: (0, Utils_1.constructVariantStyles)([_rootPartial, _textPrimary3Partial, _rootFontWeights.bold]),
    $textPrimary325: (0, Utils_1.constructVariantStyles)([
        _rootPartial,
        _textPrimary3Partial,
        _rootFontWeights.medium,
        { lineHeight: '26px' },
    ]),
    $textPrimary300: (0, Utils_1.constructVariantStyles)([_rootPartial, _textPrimary3Partial, _rootFontWeights.light]),
    $textPrimary250: (0, Utils_1.constructVariantStyles)([_rootPartial, _textPrimary2Partial, _rootFontWeights.bold]),
    $textPrimary225: (0, Utils_1.constructVariantStyles)([_rootPartial, _textPrimary2Partial, _rootFontWeights.medium]),
    $textPrimary200: (0, Utils_1.constructVariantStyles)([_rootPartial, _textPrimary2Partial, _rootFontWeights.light]),
    $textPrimary150: (0, Utils_1.constructVariantStyles)([
        _rootPartial,
        _textPrimary1Partial,
        _rootFontWeights.bold,
        { lineHeight: '17px' },
    ]),
    $textPrimary125: (0, Utils_1.constructVariantStyles)([_rootPartial, _textPrimary1Partial, _rootFontWeights.medium]),
    $textPrimary100: (0, Utils_1.constructVariantStyles)([_rootPartial, _textPrimary1Partial, _rootFontWeights.light]),
    $textPrimary050: (0, Utils_1.constructVariantStyles)([
        _rootPartial,
        _textPrimary0Partial,
        _rootFontWeights.bold,
        { lineHeight: '14px' },
    ]),
    $textPrimary025: (0, Utils_1.constructVariantStyles)([_rootPartial, _textPrimary0Partial, _rootFontWeights.medium]),
    $textPrimary000: (0, Utils_1.constructVariantStyles)([_rootPartial, _textPrimary0Partial, _rootFontWeights.light]),
    $textSecondary200: (0, Utils_1.constructVariantStyles)([
        _rootPartial,
        _textSecondary2Partial,
        _rootFontWeights.medium,
        _textTransform.uppercase,
    ]),
    $textSecondary100: (0, Utils_1.constructVariantStyles)([
        _rootPartial,
        _textSecondary1Partial,
        _rootFontWeights.medium,
        _textTransform.uppercase,
    ]),
    $textSecondary000: (0, Utils_1.constructVariantStyles)([_rootPartial, _textSecondary0Partial, _rootFontWeights.medium]),
    $textSubtext150: (0, Utils_1.constructVariantStyles)([_rootPartial, _textSubtext1Partial, _rootFontWeights.bold]),
    $textSubtext125: (0, Utils_1.constructVariantStyles)([_rootPartial, _textSubtext1Partial, _rootFontWeights.medium]),
    $textSubtext100: (0, Utils_1.constructVariantStyles)([_rootPartial, _textSubtext1Partial, _rootFontWeights.light]),
    $textSubtext050: (0, Utils_1.constructVariantStyles)([_rootPartial, _textSubtext0Partial, _rootFontWeights.bold]),
    $textSubtext025: (0, Utils_1.constructVariantStyles)([_rootPartial, _textSubtext0Partial, _rootFontWeights.medium]),
    $textSubtext000: (0, Utils_1.constructVariantStyles)([_rootPartial, _textSubtext0Partial, _rootFontWeights.light]),
};
