"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LinkedinIcon = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Utils_1 = require("../../utils/Utils");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const __DEV__ = process.env.NODE_ENV !== 'production';
const LinkedinIcon = (props) => {
    const theme = (0, ThemeProvider_1.useTheme)();
    if (!theme) {
        if (__DEV__) {
            console.error('`LinkedinIcon` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const { className, testId, fill = theme.colors.$primary500 } = props;
    return ((0, jsx_runtime_1.jsx)("svg", Object.assign({ className: className, viewBox: "0 0 12 12", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, (0, Utils_1.getTestIdAttribute)(testId), { children: (0, jsx_runtime_1.jsx)("path", { d: "M2.62082 11.4037V4.2462H0.24179V11.4037H2.62082ZM1.4318 3.26914C2.26125 3.26914 2.77763 2.71953 2.77763 2.03267C2.76211 1.33017 2.26125 0.795898 1.44758 0.795898C0.633348 0.795898 0.101562 1.33017 0.101562 2.03261C0.101562 2.71947 0.617761 3.26908 1.41622 3.26908L1.4318 3.26914ZM3.93765 11.4037H6.3165V7.40705C6.3165 7.19341 6.33202 6.97922 6.39487 6.82664C6.56677 6.39906 6.9582 5.95645 7.61556 5.95645C8.47618 5.95645 8.82067 6.61275 8.82067 7.57504V11.4037H11.1994V7.2998C11.1994 5.10142 10.026 4.0784 8.46078 4.0784C7.17755 4.0784 6.61397 4.79563 6.30085 5.28413H6.31668V4.24645H3.93777C3.96883 4.91791 3.93765 11.4037 3.93765 11.4037Z", fill: fill }) })));
};
exports.LinkedinIcon = LinkedinIcon;
