"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Menu = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const icons_1 = require("../../assets/icons");
const Menu_styles_1 = require("./styles/Menu.styles");
const MenuItem_1 = require("./MenuItem");
const SubMenu_1 = require("./SubMenu");
const defaultNavItems_1 = require("./defaultNavItems");
const Account_1 = require("./Account");
const MenuItem_styles_1 = require("./styles/MenuItem.styles");
const Account_styles_1 = require("./styles/Account.styles");
const Menu = (props) => {
    const { isOpen, navItems, handleCloseMenu, account, domains } = props;
    const styles = (0, Menu_styles_1.getMenuStyles)();
    const [subMenuOpen, setSubMenuOpen] = (0, react_1.useState)(false);
    const [subMenuItem, setSubMenuItem] = (0, react_1.useState)(undefined);
    const accountSubNavItem = {
        id: 'accountDetails',
        href: '',
        label: '',
        render: () => (account ? (0, jsx_runtime_1.jsx)(Account_1.Account, Object.assign({}, account, { domains: domains })) : (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {})),
    };
    const accountStyles = (0, Account_styles_1.getAccountStyles)();
    const menuItemStyles = (0, MenuItem_styles_1.getMenuItemStyles)();
    const userLetters = `${account === null || account === void 0 ? void 0 : account.user.first_name.charAt(0).toUpperCase()}${account === null || account === void 0 ? void 0 : account.user.last_name.charAt(0).toUpperCase()}`;
    const accountNavItem = {
        id: 'accountHeader',
        href: '',
        label: 'Account',
        subNavItems: [accountSubNavItem],
        render: () => userAvatar,
    };
    const handleOpenSubMenu = (subMenuItem) => {
        setSubMenuOpen(true);
        setSubMenuItem(subMenuItem);
    };
    const handleCloseSubMenu = (linkClick = false) => {
        var _a, _b;
        setSubMenuOpen(false);
        // set focus on main menu element before removing it
        (_b = document.getElementById((_a = subMenuItem === null || subMenuItem === void 0 ? void 0 : subMenuItem.id) !== null && _a !== void 0 ? _a : '')) === null || _b === void 0 ? void 0 : _b.focus();
        setSubMenuItem(undefined);
        if (linkClick)
            handleCloseMenu();
    };
    (0, react_1.useEffect)(() => {
        setSubMenuOpen(false);
        setSubMenuItem(undefined);
    }, [isOpen]);
    const userAvatar = ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: `MenuItem-menuItem-local ${menuItemStyles.menuItem}` }, { children: (0, jsx_runtime_1.jsx)("li", { children: (0, jsx_runtime_1.jsxs)("button", Object.assign({ tabIndex: !isOpen || subMenuOpen ? -1 : 0, className: `MenuItem-subNavItem-local ${menuItemStyles.subNavItem}`, id: accountNavItem.id, onClick: () => handleOpenSubMenu(accountNavItem) }, { children: [(0, jsx_runtime_1.jsx)("div", Object.assign({ className: accountStyles.avatarContainer }, { children: (0, jsx_runtime_1.jsx)("span", Object.assign({ className: accountStyles.avatar }, { children: userLetters.length > 0 ? userLetters : account === null || account === void 0 ? void 0 : account.user.email.charAt(0).toUpperCase() })) })), (0, jsx_runtime_1.jsx)(icons_1.ArrowRightIcon, { className: menuItemStyles.rightIcon })] })) }) })));
    return ((0, jsx_runtime_1.jsxs)("div", Object.assign({ className: `Menu-mainContainer-local ${styles.menuContainer} ${isOpen ? styles.menuOpen : ''}` }, { children: [(0, jsx_runtime_1.jsx)("div", Object.assign({ className: `Menu-menuWrapper-local ${styles.menuWrapper}` }, { children: (0, jsx_runtime_1.jsx)("nav", Object.assign({ "aria-label": "gromaHeaderMenuNav", className: `Menu-menuItems-local ${styles.menuItems}` }, { children: (0, jsx_runtime_1.jsxs)("ul", { children: [account && ((0, jsx_runtime_1.jsx)(MenuItem_1.MenuItem, { subMenuOpen: subMenuOpen, navItem: accountNavItem, handleOpenSubMenu: handleOpenSubMenu, handleOnClose: handleCloseMenu, isOpen: isOpen, domains: domains }, "account")), navItems.map(navItem => {
                                return ((0, jsx_runtime_1.jsx)(MenuItem_1.MenuItem, { subMenuOpen: subMenuOpen, navItem: navItem, handleOpenSubMenu: handleOpenSubMenu, handleOnClose: handleCloseMenu, isOpen: isOpen, domains: domains }, navItem.id));
                            }), !account && ((0, jsx_runtime_1.jsx)(MenuItem_1.MenuItem, { subMenuOpen: subMenuOpen, navItem: defaultNavItems_1.loginItems, handleOpenSubMenu: handleOpenSubMenu, handleOnClose: handleCloseMenu, isOpen: isOpen, domains: domains }, defaultNavItems_1.loginItems.id))] }) })) })), (0, jsx_runtime_1.jsx)(SubMenu_1.SubMenu, { subMenuOpen: subMenuOpen, navItem: subMenuItem, handleOnClose: handleCloseSubMenu, domains: domains })] })));
};
exports.Menu = Menu;
