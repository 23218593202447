"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLinkStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
exports.getLinkStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    wrapper: {
        alignItems: 'center',
        borderRadius: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        width: 'fit-content',
    },
    link: {
        position: 'relative',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        maxWidth: '100%',
        overflow: 'hidden',
        textAlign: 'center',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    activeNav: {
        '&::after': {
            position: 'absolute',
            bottom: 0,
            content: '""',
            backgroundColor: theme.colors.$secondary300,
            width: '100%',
            height: '4px',
            left: 0,
        },
    },
    default: Object.assign({}, theme.variants.links.default),
    nav: Object.assign({}, theme.variants.links.nav),
    callToAction: Object.assign(Object.assign({}, theme.variants.links.callToAction), { '&$disabled': {
            backgroundColor: `${theme.colors.$interactiveLinkDisabled}4D`,
            borderColor: theme.colors.$interactiveLinkDisabled,
        } }),
    subNav: Object.assign({}, theme.variants.links.subNav),
    disabled: {
        color: theme.colors.$interactiveLinkDisabled,
        '&:visited, &:active': {
            color: theme.colors.$interactiveLinkDisabled,
            '&:hover, &:focus': {
                color: theme.colors.$interactiveLinkDisabled,
                cursor: 'not-allowed',
            },
        },
        '&:hover, &:focus': {
            color: theme.colors.$interactiveLinkDisabled,
            cursor: 'not-allowed',
        },
    },
    fullWidth: {
        width: '100%',
    },
}), { name: 'Blueprint-Link' });
