"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buttonStyles = void 0;
const Utils_1 = require("../../../../utils/Utils");
const spacing_1 = require("../spacing");
const typography_1 = require("../typography");
const _rootDefault = Object.assign(Object.assign({}, typography_1.typographyValues.$textPrimary200), { backgroundColor: '$interactivePrimaryDefault', borderColor: '$interactivePrimaryDefault', borderRadius: '4px', borderStyle: 'solid', borderWidth: 0, boxSizing: 'border-box', color: '$neutral100', 
    // DIFF lineHeight: '30px',
    margin: 0, padding: `${spacing_1.spacingValues.$s2} ${spacing_1.spacingValues.$s4}`, textDecoration: 'none', textTransform: 'uppercase', transition: 'background-color 300ms, opacity 300ms', whiteSpace: 'nowrap', '&:hover, &:focus-visible': {
        backgroundColor: '$interactivePrimaryHover',
        borderColor: '$interactivePrimaryHover',
    }, '&:active': {
        backgroundColor: '$interactivePrimaryPressed',
        borderColor: '$interactivePrimaryPressed',
    }, '&:disabled, &:disabled:hover, &:disabled:focus-visible, &:disabled:active': {
        // Note: for accessibility we do not add `pointerEvents: 'none'` when disabled,
        // because that suppresses the voiceover on focus
        backgroundColor: '$interactivePrimaryDisabled',
        borderColor: '$interactivePrimaryDisabled',
        cursor: 'not-allowed',
        userSelect: 'none',
    }, label: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }, 'label:busy': {
        cursor: 'wait',
        opacity: 0.3,
    } });
const _smallPartial = Object.assign(Object.assign({}, typography_1.typographyValues.$textPrimary000), { color: '$neutral100', padding: `${spacing_1.spacingValues.$s2} ${spacing_1.spacingValues.$s3}`, textTransform: 'uppercase' });
const _ghostPartial = Object.assign(Object.assign({}, typography_1.typographyValues.$textPrimary200), { backgroundColor: 'transparent', borderColor: '$interactivePrimaryDefault', borderWidth: '1px', color: '$interactivePrimaryDefault', textTransform: 'uppercase', '&:hover, &:focus-visible': {
        backgroundColor: 'transparent',
        borderColor: '$interactivePrimaryDefault',
    }, '&:active': {
        backgroundColor: 'transparent',
        borderColor: '$interactivePrimaryDefault',
    }, '&:disabled, &:disabled:hover, &:disabled:focus-visible, &:disabled:active': {
        backgroundColor: 'transparent',
        borderColor: '$interactivePrimaryDefault',
    } });
exports.buttonStyles = {
    default: (0, Utils_1.constructVariantStyles)([_rootDefault]),
    small: (0, Utils_1.constructVariantStyles)([_rootDefault, _smallPartial]),
    ghost: (0, Utils_1.constructVariantStyles)([_rootDefault, _ghostPartial]),
};
