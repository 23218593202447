import React from 'react';
import { useTheme, Spinner } from '@groma-dev/blueprint';
import { observer } from 'mobx-react-lite';
import styles from './Loading.module.scss';

export interface LoadingProps {
  color?: string;
  height?: string;
  isPage?: boolean;
}

const Loading = observer((props: LoadingProps) => {
  const theme = useTheme();

  const { color = theme.colors.$primary300, height = '50px', isPage = false } = props;

  return (
    <div className={`${styles.mainContainer} ${isPage ? styles.fullPage : ''}`}>
      <div style={{ width: height, height }}>
        <Spinner color={color} />
      </div>
    </div>
  );
});

export default Loading;
