"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.globalLightTheme = void 0;
const ResolveThemeVariables_1 = require("../../utils/ResolveThemeVariables");
const shadow_1 = require("../typings/shadow");
const theme_1 = require("../typings/theme");
const color_1 = require("../typings/color");
const breakpoints_1 = require("./theme-parts/breakpoints");
const buttons_1 = require("./theme-parts/variants/buttons");
const cards_1 = require("./theme-parts/variants/cards");
const font_1 = require("./theme-parts/font");
const footers_1 = require("./theme-parts/variants/footers");
const inputs_1 = require("./theme-parts/variants/inputs");
const labels_1 = require("./theme-parts/variants/labels");
const links_1 = require("./theme-parts/variants/links");
const modals_1 = require("./theme-parts/variants/modals");
const pills_1 = require("./theme-parts/variants/pills");
const colors_1 = require("./theme-parts/colors");
const shadows_1 = require("./theme-parts/shadows");
const spacing_1 = require("./theme-parts/spacing");
const textareas_1 = require("./theme-parts/variants/textareas");
const toasts_1 = require("./theme-parts/variants/toasts");
const typography_1 = require("./theme-parts/typography");
exports.globalLightTheme = {
    __base: theme_1.GlobalBaseTheme.LIGHT,
    __cache: (0, ResolveThemeVariables_1.constructThemeCache)(),
    __description: 'Groma Blueprint light theme',
    __name: theme_1.GlobalThemes.GlobalLightTheme,
    breakpoints: Object.assign({}, breakpoints_1.breakpointValues),
    colors: Object.assign(Object.assign({}, color_1.paletteColorValues), colors_1.semanticLightColorValues),
    font: Object.assign({}, font_1.fontValues),
    shadows: Object.assign(Object.assign({}, shadow_1.dropShadowValues), shadows_1.semanticShadowValues),
    spacing: Object.assign({}, spacing_1.spacingValues),
    typography: Object.assign({}, typography_1.typographyValues),
    variants: {
        buttons: Object.assign({}, buttons_1.buttonStyles),
        cards: Object.assign({}, cards_1.cardsStyles),
        footers: Object.assign({}, footers_1.footerStyles),
        inputs: Object.assign({}, inputs_1.inputStyles),
        labels: Object.assign({}, labels_1.labelStyles),
        links: Object.assign({}, links_1.linkStyles),
        modals: Object.assign({}, modals_1.modalStyles),
        pills: Object.assign({}, pills_1.pillStyles),
        textareas: Object.assign({}, textareas_1.textareaStyles),
        toasts: Object.assign({}, toasts_1.toastStyles),
    },
};
