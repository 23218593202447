"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getToastContainerStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
exports.getToastContainerStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    mainContainer: {
        alignItems: 'flex-end',
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing.$s3,
        maxWidth: '95vw',
        padding: theme.spacing.$s2,
        position: 'fixed',
        right: '0',
        zIndex: '1000',
    },
}), { name: 'Blueprint-ToastContainer' });
