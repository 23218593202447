"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FeedbackIcon = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Utils_1 = require("../../utils/Utils");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const __DEV__ = process.env.NODE_ENV !== 'production';
const FeedbackIcon = (props) => {
    const theme = (0, ThemeProvider_1.useTheme)();
    if (!theme) {
        if (__DEV__) {
            console.error('`FeedbackIcon` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const { className, testId, fill = theme.colors.$primary500 } = props;
    return ((0, jsx_runtime_1.jsx)("svg", Object.assign({ className: className, viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, (0, Utils_1.getTestIdAttribute)(testId), { children: (0, jsx_runtime_1.jsx)("path", { d: "M13.2 1.5H2.8C2.085 1.5 1.5065 2.085 1.5065 2.8L1.5 14.5L4.1 11.9H13.2C13.915 11.9 14.5 11.315 14.5 10.6V2.8C14.5 2.085 13.915 1.5 13.2 1.5ZM13.2 10.6H3.5605L3.177 10.9835L2.8 11.3605V2.8H13.2V10.6ZM7.35 8H8.65V9.3H7.35V8ZM7.35 4.1H8.65V6.7H7.35V4.1Z", fill: fill }) })));
};
exports.FeedbackIcon = FeedbackIcon;
