"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNavItemStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
exports.getNavItemStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    navItem: {
        height: '100%',
        display: 'flex',
        '&$disabled': {
            '& a': {
                color: theme.colors.$interactiveLinkDisabled,
                '&:visited, &:active': {
                    color: theme.colors.$interactiveLinkDisabled,
                    '&:hover, &:focus': {
                        color: theme.colors.$interactiveLinkDisabled,
                        cursor: 'not-allowed',
                    },
                },
                '&:hover, &:focus': {
                    color: theme.colors.$interactiveLinkDisabled,
                    cursor: 'not-allowed',
                },
            },
        },
    },
    disabled: {},
}), { name: 'Blueprint-NavItem' });
