"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.semanticDarkColorValues = void 0;
const color_1 = require("../../typings/color");
const semanticDarkColorValues = {
    $actionIcon100: color_1.paletteColorValues.$tertiary100,
    $background100: color_1.paletteColorValues.$primary500,
    $background200: color_1.paletteColorValues.$primary400,
    $background300: color_1.paletteColorValues.$primary300,
    $content100: color_1.paletteColorValues.$neutral100,
    $content200: color_1.paletteColorValues.$tertiary100,
    $content300: color_1.paletteColorValues.$primary400,
    $inputPrimaryDefault: color_1.paletteColorValues.$primary200,
    $inputPrimaryDisabled: color_1.paletteColorValues.$primary500,
    $inputPrimaryError: color_1.paletteColorValues.$error100,
    $inputPrimaryHover: color_1.paletteColorValues.$primary100,
    $interactiveLinkDefault: color_1.paletteColorValues.$secondary300,
    $interactiveLinkDisabled: color_1.paletteColorValues.$neutral300,
    $interactiveLinkHover: color_1.paletteColorValues.$secondary100,
    $interactiveLinkPressed: color_1.paletteColorValues.$secondary200,
    $interactivePrimaryDefault: color_1.paletteColorValues.$secondary300,
    $interactivePrimaryDisabled: color_1.paletteColorValues.$neutral300,
    $interactivePrimaryHover: color_1.paletteColorValues.$secondary100,
    $interactivePrimaryPressed: color_1.paletteColorValues.$secondary200,
    $staticIcon100: color_1.paletteColorValues.$tertiary100,
};
exports.semanticDarkColorValues = semanticDarkColorValues;
