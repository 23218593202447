"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getIconStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
exports.getIconStyles = (0, Utils_1.createStyleSheet)({
    iconContainer: {
        width: ({ size }) => size,
        height: ({ size }) => size,
    },
    icon: {
        width: '100%',
        height: '100%',
        '& path': {
            fill: ({ color }) => color,
        },
    },
}, { name: 'Blueprint-Icon' });
