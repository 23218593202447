"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableCell = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const Contants_1 = require("../../utils/Contants");
const icons_1 = require("../../assets/icons");
const Utils_1 = require("../../utils/Utils");
const TableCell_styles_1 = require("./styles/TableCell.styles");
const Table_1 = require("./Table");
const __DEV__ = process.env.NODE_ENV !== 'production';
const TableCell = (props) => {
    const { children, className = '', align = 'right', sticky = false, stickyOffset, allowSorting, accessor, colSpan, rowSpan, testId, } = props;
    const theme = (0, ThemeProvider_1.useTheme)();
    const table = (0, Table_1.useTable)();
    const styles = (0, TableCell_styles_1.getTableCellStyles)();
    const tableSubContext = (0, react_1.useContext)(Table_1.TableSubContext);
    const { setSortedColumn, sortedColumn, showGrid } = table;
    if (!theme) {
        if (__DEV__) {
            console.error('`TableCell` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    if (!table) {
        if (__DEV__) {
            console.error('`TableCell` component must be a child of a `Table` component');
        }
        return null;
    }
    if (!tableSubContext) {
        if (__DEV__) {
            console.error('`TableCell` component must be a child of a `TableHead` or `TableBody` component');
        }
        return null;
    }
    const { variant } = tableSubContext;
    if (allowSorting && variant !== Table_1.TableVariantType.HEAD) {
        if (__DEV__) {
            console.warn('`allowSorting` property only applied when `TableCell` is a child of the `TableHead` component');
        }
    }
    const dti = (0, Utils_1.getTestIdAttribute)((testId !== null && testId !== void 0 ? testId : variant === Table_1.TableVariantType.HEAD) ? 'headTableCell' : 'bodyTableCell');
    const sortIcon = () => {
        if ((sortedColumn === null || sortedColumn === void 0 ? void 0 : sortedColumn.type) === Contants_1.SortType.ASC)
            return (0, jsx_runtime_1.jsx)(icons_1.UpIcon, { className: styles.sortIcon });
        else
            return (0, jsx_runtime_1.jsx)(icons_1.DownIcon, { className: styles.sortIcon });
    };
    return variant === Table_1.TableVariantType.HEAD ? ((0, jsx_runtime_1.jsx)("th", Object.assign({ className: `TableCell-local ${styles.headCell} ${styles[align]} ${sticky ? styles.sticky : ''} ${showGrid ? styles.grid : ''} ${className}`, style: Object.assign({}, stickyOffset), colSpan: colSpan, rowSpan: rowSpan }, dti, { children: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: allowSorting ? ((0, jsx_runtime_1.jsxs)("button", Object.assign({ className: `TableCell-button-local ${styles.sort} ${styles[align]}`, "aria-label": (sortedColumn === null || sortedColumn === void 0 ? void 0 : sortedColumn.key) === accessor
                    ? sortedColumn.type === Contants_1.SortType.ASC
                        ? 'Ascending Sorted'
                        : 'Descending Sorted'
                    : 'Sort Column', onClick: () => setSortedColumn(accessor) }, (0, Utils_1.getTestIdAttribute)('sortButton'), { children: [(sortedColumn === null || sortedColumn === void 0 ? void 0 : sortedColumn.key) === accessor ? sortIcon() : (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}), children] }))) : (children) }) }))) : ((0, jsx_runtime_1.jsx)("td", Object.assign({ className: `TableCell-local ${styles.bodyCell} ${styles[align]} ${sticky ? styles.sticky : ''} ${showGrid ? styles.grid : ''} ${className}`, style: Object.assign({}, stickyOffset), colSpan: colSpan, rowSpan: rowSpan }, dti, { children: children })));
};
exports.TableCell = TableCell;
