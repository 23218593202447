"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Skeleton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const Utils_1 = require("../../utils/Utils");
const theme_1 = require("../../themes/typings/theme");
const Skeleton_styles_1 = require("./styles/Skeleton.styles");
const __DEV__ = process.env.NODE_ENV === 'production';
const Skeleton = (props) => {
    const theme = (0, ThemeProvider_1.useTheme)();
    if (!theme) {
        if (__DEV__) {
            console.error('`Header` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const isDarkTheme = theme.__base === theme_1.GlobalBaseTheme.DARK;
    const { height = '10px', lines = 1, color = isDarkTheme ? theme.colors.$neutral600 : theme.colors.$neutral200, className = '', localClassName = 'Skeleton-local', testId, } = props;
    const styles = (0, Skeleton_styles_1.getSkeletonStyles)();
    const dti = (0, Utils_1.getTestIdAttribute)(testId);
    const skeletons = [];
    for (let i = 0; i < lines; i++) {
        skeletons.push((0, jsx_runtime_1.jsx)("div", Object.assign({ className: `${localClassName} ${styles.mainContainer} ${i > 0 ? styles.spacing : ''} ${className}`, style: { backgroundColor: color, height } }, dti, { children: (0, jsx_runtime_1.jsx)("div", { className: styles.shine, style: {
                    backgroundImage: `linear-gradient(to right, ${color} 0%, ${(0, Utils_1.lightenColor)(0.25, color)} 20%, ${color} 40%, ${color} 100%)`,
                } }) }), i));
    }
    return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: skeletons });
};
exports.Skeleton = Skeleton;
