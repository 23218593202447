"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setFocusableOnElement = exports.getFocusableElements = exports.setBodyOverflow = exports.classNames = exports.zIndexMixin = exports.flexMixin = exports.vh = exports.maxContainerMobileWidth = exports.maxContainerWidth = exports.headerHeight = void 0;
exports.headerHeight = 80;
exports.maxContainerWidth = 1440;
exports.maxContainerMobileWidth = 600;
/**
 * mixin that calculates the view height based on screen size and browser
 * @param quantity
 */
const vh = (quantity = 100) => {
    return `calc(var(--vh, 1vh) * ${quantity})`;
};
exports.vh = vh;
/**
 * mixin method to return flex object
 * @param direction - flexDirection prop - defaults to row
 * @param justifyContent
 * @param alignItems
 */
const flexMixin = (direction = 'row', justifyContent = 'normal', alignItems = 'normal') => {
    const value = {
        display: 'flex',
        flexDirection: direction,
        justifyContent,
        alignItems,
    };
    return value;
};
exports.flexMixin = flexMixin;
/**
 * mixin method to set zIndex
 * returns and object to spread in styles
 * @param value
 */
const zIndexMixin = (value = 1) => {
    const style = {
        zIndex: value,
    };
    return style;
};
exports.zIndexMixin = zIndexMixin;
/**
 * takes in an arbitrary amount of arguments and returns classNames that are truthy
 * @param classes
 */
const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ');
};
exports.classNames = classNames;
/**
 * Set overflow style on document body for modal / dialog purposes
 * @param style
 */
const setBodyOverflow = (style) => {
    document.body.style.overflow = style;
};
exports.setBodyOverflow = setBodyOverflow;
/**
 * returns an array of focusable elements
 */
const getFocusableElements = (parentId) => {
    var _a;
    const list = 'button:not([disabled]), [href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"]):not([disabled]), details:not([disabled]), summary:not(:disabled)';
    let elements;
    if (parentId)
        elements = (_a = document.getElementById(parentId)) === null || _a === void 0 ? void 0 : _a.querySelectorAll(list);
    else
        elements = document.querySelectorAll(list);
    return elements;
};
exports.getFocusableElements = getFocusableElements;
/**
 * sets the tabIndex on an array of focusable elements
 * @param elements
 * @param tabIndex
 */
const setFocusableOnElement = (elements, tabIndex) => {
    elements === null || elements === void 0 ? void 0 : elements.forEach((el) => {
        el.tabIndex = tabIndex;
    });
};
exports.setFocusableOnElement = setFocusableOnElement;
