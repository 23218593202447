"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPrivacyPolicyStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
exports.getPrivacyPolicyStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    mainContainer: {
        width: '100%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
    },
    date: Object.assign(Object.assign({}, theme.typography.$textPrimary150), { paddingBottom: theme.spacing.$s2 }),
    title: Object.assign(Object.assign({}, theme.typography.$textPrimary550), { textAlign: 'center', margin: `${theme.spacing.$s4} auto` }),
    section: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        margin: `${theme.spacing.$s3} 0`,
    },
    subTitle: Object.assign(Object.assign({}, theme.typography.$textPrimary250), { textTransform: 'uppercase' }),
    content: Object.assign(Object.assign({}, theme.typography.$textPrimary100), { whiteSpace: 'pre-wrap', margin: `${theme.spacing.$s3} 0 ${theme.spacing.$s4}` }),
    list: {},
    subSection: {
        margin: `${theme.spacing.$s3} 0`,
    },
    listItem: Object.assign(Object.assign({}, theme.typography.$textPrimary100), { listStyle: 'initial', marginLeft: theme.spacing.$s4 }),
    parentItem: Object.assign(Object.assign({}, theme.typography.$textPrimary150), { marginLeft: theme.spacing.$s4 }),
    number: {
        listStyle: 'number',
        marginLeft: theme.spacing.$s4,
    },
    subListItem: Object.assign(Object.assign({ listStyle: 'circle', marginLeft: theme.spacing.$s6 }, theme.typography.$textPrimary100), { fontStyle: 'italic' }),
    subListContent: Object.assign(Object.assign({}, theme.typography.$textPrimary100), { fontStyle: 'italic', margin: `${theme.spacing.$s2} 0 ${theme.spacing.$s2} ${theme.spacing.$s4}` }),
    [`@media (max-width: ${theme.breakpoints.$sm}px)`]: {
        date: Object.assign(Object.assign({}, theme.typography.$textPrimary050), { paddingBottom: theme.spacing.$s2 }),
        title: Object.assign(Object.assign({}, theme.typography.$textPrimary450), { 
            // DIFF lineHeight: '36px',
            margin: `${theme.spacing.$s3} auto` }),
        section: {
            margin: `${theme.spacing.$s2} 0`,
        },
        subTitle: Object.assign(Object.assign({}, theme.typography.$textPrimary150), { textTransform: 'uppercase' }),
        content: {
            margin: `${theme.spacing.$s2} 0 ${theme.spacing.$s3}`,
        },
        subSection: {
            margin: `${theme.spacing.$s2} 0`,
        },
        listItem: {
            marginLeft: theme.spacing.$s3,
        },
        parentItem: {
            marginLeft: theme.spacing.$s3,
        },
        number: {
            marginLeft: theme.spacing.$s3,
        },
        subListItem: {
            marginLeft: theme.spacing.$s4,
        },
        subListContent: {
            margin: `${theme.spacing.$s2} 0 ${theme.spacing.$s2} ${theme.spacing.$s3}`,
        },
    },
}), { name: 'Blueprint-PrivacyPolicy' });
