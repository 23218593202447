"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NativeSelect = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const StyleUtils_1 = require("../../utils/StyleUtils");
const Utils_1 = require("../../utils/Utils");
const Label_1 = require("../Label/Label");
const icons_1 = require("../../assets/icons");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const NativeSelect_styles_1 = require("./styles/NativeSelect.styles");
const Select_styles_1 = require("./styles/Select.styles");
const NativeOption_1 = require("./NativeOption");
const __DEV__ = process.env.NODE_ENV !== 'production';
const NativeSelect = (props) => {
    const { children, className = '', defaultValue, disabled = false, hasError = false, helperMessage, name, id = name, label, onBlur, onChange, onClick, onFocus, shouldHideLabel = false, testId, } = props;
    const theme = (0, ThemeProvider_1.useTheme)();
    if (!theme) {
        if (__DEV__) {
            console.error('`NativeSelect` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const styles = (0, NativeSelect_styles_1.getNativeSelectStyles)();
    const selectStyles = (0, Select_styles_1.getSelectStyles)();
    const selectRef = (0, react_1.createRef)();
    const dti = (0, Utils_1.getTestIdAttribute)(testId);
    const helperContent = (el) => ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: (0, StyleUtils_1.classNames)('NativeSelect-helperContainer-local', selectStyles.helperContainer, hasError && selectStyles.errorMessage) }, (0, Utils_1.getTestIdAttribute)('nativeSelectHelperContent'), { children: el })));
    const handleOnChange = (e) => {
        if (onChange)
            onChange(e.currentTarget.value);
    };
    const handleOnBlur = (e) => {
        if (onBlur)
            onBlur(e);
    };
    const handleOnFocus = (e) => {
        if (onFocus)
            onFocus(e);
    };
    const handleOnClick = (e) => {
        if (onClick)
            onClick(e);
    };
    const handleContainerClick = (e) => {
        var _a, _b;
        // focus on select when clicking anywhere in the container except if the user is clicking on the select itself
        if (!((_a = selectRef.current) === null || _a === void 0 ? void 0 : _a.contains(e.target))) {
            (_b = document.getElementById(id)) === null || _b === void 0 ? void 0 : _b.focus();
        }
    };
    return ((0, jsx_runtime_1.jsxs)("div", Object.assign({ role: "presentation", onClick: handleContainerClick, className: (0, StyleUtils_1.classNames)('NativeSelect-container-local', styles.container, className) }, dti, { children: [(0, jsx_runtime_1.jsxs)("div", Object.assign({ className: (0, StyleUtils_1.classNames)('NativeSelect-wrapper-local', selectStyles.wrapper, hasError && selectStyles.hasError, disabled && selectStyles.disabled) }, { children: [(0, jsx_runtime_1.jsx)(Label_1.Label, { htmlFor: id, content: !shouldHideLabel ? label : undefined, screenReaderOnlyText: shouldHideLabel ? label : undefined, className: (0, StyleUtils_1.classNames)(selectStyles.label, disabled && selectStyles.disabled, hasError && selectStyles.hasError) }), (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: (0, StyleUtils_1.classNames)('NativeSelect-wrapper-local', styles.wrapper) }, { children: [(0, jsx_runtime_1.jsx)("select", Object.assign({ ref: selectRef, id: id, className: (0, StyleUtils_1.classNames)('NativeSelect-local', styles.select), name: name, disabled: disabled, defaultValue: defaultValue, onBlur: handleOnBlur, onChange: handleOnChange, onFocus: handleOnFocus, onClick: handleOnClick }, { children: children })), (0, jsx_runtime_1.jsx)(icons_1.DownIcon, { className: (0, StyleUtils_1.classNames)(selectStyles.icon) })] }))] })), helperMessage && helperContent(helperMessage)] })));
};
exports.NativeSelect = NativeSelect;
exports.NativeSelect.Option = (props) => (0, jsx_runtime_1.jsx)(NativeOption_1.NativeOption, Object.assign({}, props, { children: props.children }));
