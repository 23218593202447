"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAccordionStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
const theme_1 = require("../../../themes/typings/theme");
const StyleUtils_1 = require("../../../utils/StyleUtils");
exports.getAccordionStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    container: Object.assign(Object.assign({}, (0, StyleUtils_1.flexMixin)('column')), { backgroundColor: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$primary400 : theme.colors.$neutral100, height: 'auto', width: '100%', '&:first-child': {
            borderTopLeftRadius: theme.spacing.$s1,
            borderTopRightRadius: theme.spacing.$s1,
        }, '&:last-child': {
            borderBottomLeftRadius: theme.spacing.$s1,
            borderBottomRightRadius: theme.spacing.$s1,
        }, '&:not(:last-child)': {
            borderBottom: `1px solid ${theme.colors.$neutral200}`,
        } }),
}), {
    name: 'Blueprint-Accordion',
});
