"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Panel = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const StyleUtils_1 = require("../../utils/StyleUtils");
const Utils_1 = require("../../utils/Utils");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const Panel_styles_1 = require("./styles/Panel.styles");
const __DEV__ = process.env.NODE_ENV !== 'production';
const Panel = (props) => {
    const { children, className = '', testId, id, role } = props;
    const theme = (0, ThemeProvider_1.useTheme)();
    if (!theme) {
        if (__DEV__) {
            console.error('`Panel` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const styles = (0, Panel_styles_1.getPanelStyles)();
    const dti = (0, Utils_1.getTestIdAttribute)(testId);
    return ((0, jsx_runtime_1.jsx)("div", Object.assign({ role: role, id: id, className: (0, StyleUtils_1.classNames)('Panel-local', styles.container, className) }, dti, { children: children })));
};
exports.Panel = Panel;
