"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAccordionSummaryStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
const theme_1 = require("../../../themes/typings/theme");
const StyleUtils_1 = require("../../../utils/StyleUtils");
exports.getAccordionSummaryStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    container: Object.assign(Object.assign(Object.assign({}, (0, StyleUtils_1.flexMixin)('row', 'space-between', 'center')), theme.typography.$textPrimary100), { backgroundColor: 'transparent', minHeight: '60px', padding: `${theme.spacing.$s3} ${theme.spacing.$s3}`, width: '100%', cursor: 'pointer', '&:disabled': {
            backgroundColor: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$primary300 : theme.colors.$neutral200,
            cursor: 'initial',
        }, '&$noExpand': {
            cursor: 'initial',
        } }),
    content: Object.assign(Object.assign({}, (0, StyleUtils_1.flexMixin)()), { flexWrap: 'wrap', width: '100%' }),
    iconContainer: Object.assign(Object.assign({}, (0, StyleUtils_1.flexMixin)('column', 'center', 'center')), { height: 'fit-content', width: 'fit-content', backgroundColor: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$primary300 : theme.colors.$tertiary100, borderRadius: theme.spacing.$s1, '&$left': {
            marginRight: theme.spacing.$s3,
        }, '&$right': {
            marginLeft: theme.spacing.$s3,
        } }),
    icon: {
        width: '25px',
        height: '25px',
        transition: 'transform 0.15s linear',
        '& path': {
            fill: theme.colors.$content100,
        },
        '&$open': {
            transform: 'rotate(180deg)',
        },
    },
    left: {},
    right: {},
    open: {},
    noExpand: {},
}), {
    name: 'Blueprint-AccordionSummary',
});
