"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.APIClient = void 0;
const BaseAPIClient_1 = require("./BaseAPIClient");
const Routes_1 = require("./Routes");
class APIClient extends BaseAPIClient_1.BaseAPIClient {
}
exports.APIClient = APIClient;
_a = APIClient;
APIClient.fetchUser = (extraConfig, url = Routes_1.ScaffoldRoutes.fetchUser) => {
    const { client, config } = _a.createClient({}, Object.assign({ withCredentials: true }, extraConfig));
    return client
        .get(url, config)
        .then(res => {
        const response = _a.prepareResponse({}, res);
        if (response.success)
            response.user = res.data;
        return response;
    })
        .catch(err => _a.prepareResponse({}, err));
};
APIClient.signOutUser = (extraConfig, url = Routes_1.ScaffoldRoutes.signOutUser) => {
    const { client, config } = _a.createClient({}, Object.assign({ withCredentials: true }, extraConfig));
    return client
        .post(url, {}, config)
        .then(res => _a.prepareResponse({}, res))
        .catch(err => _a.prepareResponse({}, err));
};
