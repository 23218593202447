"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFooterSectionStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
exports.getFooterSectionStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: `${theme.spacing.$s4} ${theme.spacing.$s5}`,
    },
    divider: {},
    contentContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    title: Object.assign(Object.assign({}, theme.typography.$textPrimary250), { color: theme.variants.footers.default.color, marginBottom: theme.spacing.$s1 }),
    staticContent: Object.assign(Object.assign({}, theme.typography.$textPrimary100), { color: theme.variants.footers.default.color, padding: `${theme.spacing.$s1} 0` }),
}), { name: 'Blueprint-FooterSection' });
