"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DrawerPanel = exports.DrawerPanelAnchor = exports.DrawerPanelVariant = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_intl_1 = require("react-intl");
const Utils_1 = require("../../utils/Utils");
const StyleUtils_1 = require("../../utils/StyleUtils");
const icons_1 = require("../../assets/icons");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const DrawerPanel_styles_1 = require("./styles/DrawerPanel.styles");
const Panel_1 = require("./Panel");
const __DEV__ = process.env.NODE_ENV !== 'production';
var DrawerPanelVariant;
(function (DrawerPanelVariant) {
    DrawerPanelVariant["Takeover"] = "takeover";
    DrawerPanelVariant["Popout"] = "popout";
    DrawerPanelVariant["Inline"] = "inline";
})(DrawerPanelVariant = exports.DrawerPanelVariant || (exports.DrawerPanelVariant = {}));
var DrawerPanelAnchor;
(function (DrawerPanelAnchor) {
    DrawerPanelAnchor["Top"] = "top";
    DrawerPanelAnchor["Bottom"] = "bottom";
    DrawerPanelAnchor["Left"] = "left";
    DrawerPanelAnchor["Right"] = "right";
})(DrawerPanelAnchor = exports.DrawerPanelAnchor || (exports.DrawerPanelAnchor = {}));
const DrawerPanel = (props) => {
    const { anchor = DrawerPanelAnchor.Right, allowClose = true, children, className = '', disableScroll = false, id, onClose, open = false, role = 'navigation', testId, variant = DrawerPanelVariant.Popout, } = props;
    const theme = (0, ThemeProvider_1.useTheme)();
    const intl = (0, react_intl_1.useIntl)();
    const { formatMessage } = (0, react_intl_1.useIntl)();
    if (!theme) {
        if (__DEV__) {
            console.error('`DrawerPanel` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    if (!intl) {
        if (__DEV__) {
            console.error('`DrawerPanel` component must be a child of a `IntlProvider`');
        }
        return null;
    }
    const styles = (0, DrawerPanel_styles_1.getDrawerPanelStyles)();
    const dti = (0, Utils_1.getTestIdAttribute)(testId);
    const maskRef = (0, react_1.createRef)();
    const containerRef = (0, react_1.createRef)();
    const focusableElements = (0, react_1.useRef)();
    const prevFocusedElement = (0, react_1.useRef)();
    const [isOpen, setIsOpen] = (0, react_1.useState)(open);
    const [maskVisible, setMaskVisible] = (0, react_1.useState)(open);
    const handleClose = () => {
        if (allowClose) {
            setIsOpen(false);
            if (onClose)
                onClose();
        }
    };
    const handleMaskClick = (e) => {
        var _a;
        if (allowClose && open) {
            if (!((_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.contains(e.target)))
                handleClose();
        }
    };
    const toggleFocusOnDrawerPanelElements = () => {
        const elements = (0, StyleUtils_1.getFocusableElements)(id);
        if (elements) {
            if (open) {
                (0, StyleUtils_1.setFocusableOnElement)(elements, 0);
            }
            else
                (0, StyleUtils_1.setFocusableOnElement)(elements, -1);
        }
    };
    const toggleFocusableDocumentElements = () => {
        if (open) {
            // gather all focusable elements in document
            const elements = (0, StyleUtils_1.getFocusableElements)();
            const { activeElement } = document;
            if (elements) {
                // set all focusable elements in document tab index = -1
                (0, StyleUtils_1.setFocusableOnElement)(elements, -1);
                focusableElements.current = elements;
                // set overflow of body to hidden to allow for modal feel
                if (disableScroll)
                    (0, StyleUtils_1.setBodyOverflow)('hidden');
            }
            if (activeElement)
                prevFocusedElement.current = activeElement;
        }
        else if (focusableElements) {
            // set all focusable elements in document tab index = 0
            (0, StyleUtils_1.setFocusableOnElement)(focusableElements.current, 0);
            focusableElements.current = undefined;
            if (disableScroll)
                document.body.style.removeProperty('overflow');
            // if previous element exists, focus when drawer panel is closed
            if (prevFocusedElement.current) {
                prevFocusedElement.current.focus();
                prevFocusedElement.current = undefined;
            }
        }
        // toggle elements inside panel focusable
        toggleFocusOnDrawerPanelElements();
    };
    (0, react_1.useEffect)(() => {
        if (maskVisible) {
            setIsOpen(true);
            toggleFocusableDocumentElements();
        }
    }, [maskVisible]);
    (0, react_1.useEffect)(() => {
        if (variant === DrawerPanelVariant.Takeover) {
            if (open) {
                setMaskVisible(true);
            }
            else {
                setIsOpen(false);
                toggleFocusableDocumentElements();
                // wait until animations have finished to remove from DOM
                setTimeout(() => {
                    setMaskVisible(false);
                }, 300);
            }
        }
        if (variant === DrawerPanelVariant.Popout) {
            setIsOpen(open);
            toggleFocusOnDrawerPanelElements();
        }
    }, [open]);
    const closeContainer = () => {
        return ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: (0, StyleUtils_1.classNames)('DrawerPanel-closeContainer-local', styles.closeContainer) }, { children: (0, jsx_runtime_1.jsx)("button", Object.assign({ "aria-label": formatMessage({
                    id: 'blueprint.drawerPanel.closeButton.ariaLabel',
                    defaultMessage: 'Close Drawer Panel',
                    description: 'Drawer Panel close button',
                }), className: (0, StyleUtils_1.classNames)('DrawerPanel-closeButton-local', styles.closeButton), onClick: handleClose }, (0, Utils_1.getTestIdAttribute)('drawerPanelCloseButton'), { children: (0, jsx_runtime_1.jsx)(icons_1.CloseIcon, { className: (0, StyleUtils_1.classNames)('DrawerPanel-closeIcon-local', styles.closeIcon) }) })) })));
    };
    switch (variant) {
        case DrawerPanelVariant.Takeover:
            return maskVisible ? ((0, jsx_runtime_1.jsx)("div", Object.assign({ role: "presentation", id: id, ref: maskRef, className: (0, StyleUtils_1.classNames)('DrawerPanel-maskContainer-local', styles.maskContainer, styles[anchor], isOpen && styles.fadeIn, isOpen && styles.fadeForward), onClick: handleMaskClick }, dti, { children: (0, jsx_runtime_1.jsx)("div", Object.assign({ ref: containerRef, className: (0, StyleUtils_1.classNames)('DrawerPanel-maskPanelContainer-local', styles.maskPanelContainer, styles[anchor], isOpen && styles.slide) }, { children: (0, jsx_runtime_1.jsx)(Panel_1.Panel, Object.assign({ role: role, className: (0, StyleUtils_1.classNames)(styles[anchor], styles.slide) }, { children: (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [allowClose && closeContainer(), children] }) })) })) }))) : null;
        case DrawerPanelVariant.Popout:
            return ((0, jsx_runtime_1.jsx)("div", Object.assign({ ref: containerRef, id: id, className: (0, StyleUtils_1.classNames)('DrawerPanel-popoutContainer-local', styles.popoutContainer, className, styles[anchor], isOpen && styles.slide) }, dti, { children: (0, jsx_runtime_1.jsx)(Panel_1.Panel, Object.assign({ role: role, className: (0, StyleUtils_1.classNames)(styles[anchor], styles.slide) }, { children: (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [allowClose && closeContainer(), children] }) })) })));
        case DrawerPanelVariant.Inline:
            return ((0, jsx_runtime_1.jsx)("div", Object.assign({ ref: containerRef, id: id }, dti, { children: (0, jsx_runtime_1.jsx)(Panel_1.Panel, Object.assign({ role: role }, { children: children })) })));
        default:
            return null;
    }
};
exports.DrawerPanel = DrawerPanel;
