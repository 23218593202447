"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRadioGroupStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
const StyleUtils_1 = require("../../../utils/StyleUtils");
exports.getRadioGroupStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    container: Object.assign(Object.assign({}, (0, StyleUtils_1.flexMixin)('column')), { padding: `${theme.spacing.$s3} ${theme.spacing.$s0}` }),
}), {
    name: 'Blueprint-RadioGroup',
});
