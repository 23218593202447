"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TwitterIcon = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Utils_1 = require("../../utils/Utils");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const __DEV__ = process.env.NODE_ENV !== 'production';
const TwitterIcon = (props) => {
    const theme = (0, ThemeProvider_1.useTheme)();
    if (!theme) {
        if (__DEV__) {
            console.error('`TwitterIcon` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const { className, testId, fill = theme.colors.$primary500 } = props;
    return ((0, jsx_runtime_1.jsx)("svg", Object.assign({ className: className, viewBox: "0 0 9 7", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, (0, Utils_1.getTestIdAttribute)(testId), { children: (0, jsx_runtime_1.jsx)("path", { xmlns: "http://www.w3.org/2000/svg", d: "M3.31815 6.69638C6.39733 6.69638 8.08223 4.14291 8.08223 1.9323C8.08223 1.86046 8.08223 1.78863 8.07896 1.71679C8.40549 1.48169 8.68958 1.18454 8.91488 0.848218C8.61447 0.982096 8.29121 1.07026 7.95162 1.11271C8.29774 0.906994 8.56223 0.577197 8.68958 0.185361C8.36631 0.378014 8.00713 0.515157 7.62509 0.590259C7.31815 0.263728 6.88386 0.0612793 6.40386 0.0612793C5.47978 0.0612793 4.72876 0.8123 4.72876 1.73638C4.72876 1.86699 4.74509 1.99434 4.77121 2.11842C3.38019 2.04985 2.1459 1.38046 1.31978 0.368218C1.17611 0.616381 1.09447 0.903728 1.09447 1.21067C1.09447 1.79189 1.39162 2.30454 1.83896 2.60495C1.56468 2.59516 1.30672 2.52005 1.08141 2.39597C1.08141 2.4025 1.08141 2.40903 1.08141 2.41883C1.08141 3.22863 1.65937 3.90781 2.42345 4.06128C2.28305 4.10046 2.13611 4.12005 1.98264 4.12005C1.87488 4.12005 1.77039 4.11026 1.66917 4.09067C1.88141 4.75679 2.50182 5.24005 3.23325 5.25312C2.65856 5.70373 1.93692 5.97148 1.15325 5.97148C1.01937 5.97148 0.885495 5.96495 0.754883 5.94863C1.49284 6.41883 2.37447 6.69638 3.31815 6.69638Z", fill: fill }) })));
};
exports.TwitterIcon = TwitterIcon;
