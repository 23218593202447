"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.breakpointValues = void 0;
exports.breakpointValues = {
    $xxl: 1800,
    $xl: 1600,
    $lg: 1200,
    $md: 1023,
    $sm: 767,
    $xs: 475,
    $xxs: 375,
};
