"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TooltipProvider = exports.useTooltips = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const Utils_1 = require("../../utils/Utils");
let currentTooltips;
const getCurrentTooltips = () => {
    return currentTooltips;
};
const TooltipContext = (0, react_1.createContext)({
    addTooltip: () => { },
    removeTooltip: () => { },
    removeAll: () => { },
});
const useTooltips = () => (0, react_1.useContext)(TooltipContext);
exports.useTooltips = useTooltips;
const TooltipProvider = (props) => {
    const { children } = props;
    const [Tooltips, setTooltips] = (0, react_1.useState)([]);
    const { pathname } = (0, Utils_1.parseLocation)((0, ThemeProvider_1.useThemeRouter)().router);
    const addTooltip = (content, id, cb) => {
        const Tooltip = { content, id };
        const tooltips = [Tooltip];
        setTooltips(tooltips);
        setTimeout(() => cb(), 100);
    };
    const removeTooltip = (id) => {
        var _a;
        const newTooltips = (_a = getCurrentTooltips()) === null || _a === void 0 ? void 0 : _a.filter(t => t.id !== id);
        setTooltips(newTooltips);
    };
    const removeAll = () => {
        setTooltips([]);
    };
    const value = (0, react_1.useMemo)(() => ({
        addTooltip,
        removeTooltip,
        removeAll,
    }), [Tooltips]);
    (0, react_1.useEffect)(() => {
        currentTooltips = Tooltips;
    }, [Tooltips]);
    (0, react_1.useEffect)(() => {
        removeAll();
    }, [pathname]);
    return ((0, jsx_runtime_1.jsxs)(TooltipContext.Provider, Object.assign({ value: value }, { children: [children, Tooltips.map((tooltip, index) => {
                return (0, jsx_runtime_1.jsx)(react_1.Fragment, { children: tooltip.content }, index);
            })] })));
};
exports.TooltipProvider = TooltipProvider;
