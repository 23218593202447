"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSkeletonStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
exports.getSkeletonStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    '@keyframes shimmer': {
        to: {
            transform: 'translateX(100%)',
        },
    },
    mainContainer: {
        display: 'inline-block',
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
    },
    spacing: {
        marginTop: theme.spacing.$s2,
    },
    shine: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: '100%',
        transform: 'translateX(-100%)',
        backgroundSize: '200% 100%',
        animationName: '$shimmer',
        animationDuration: '1.5s',
        animationIterationCount: 'infinite',
        backgroundRepeat: 'no-repeat',
    },
}), { name: 'Blueprint-Skeleton' });
