import { defineMessages } from 'react-intl';
import * as enUS from './lang/en-US.json';
import * as esES from './lang/es-ES.json';

export const defaultLocaleMessages = defineMessages({
  commonError: {
    id: 'app.errors.common',
    description: 'Common App Error when API request fails due to known reasons',
    defaultMessage: 'Something went wrong. Please try again.',
  },
});

export interface LocaleOption {
  [key: string]: { [key: string]: string };
}
export const locales: LocaleOption = {
  'en-us': enUS,
  'es-es': esES,
};
