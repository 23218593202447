"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Spinner = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const Utils_1 = require("../../utils/Utils");
const Spinner_styles_1 = require("./styles/Spinner.styles");
const __DEV__ = process.env.NODE_ENV !== 'production';
const Spinner = ({ color, maxHeight = '75px', className, localClassName = 'Spinner-local', id, testId, description, title = 'Busy', }) => {
    const theme = (0, ThemeProvider_1.useTheme)();
    if (!theme) {
        if (__DEV__) {
            console.error('`Spinner` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const { colors } = theme;
    const spinnerColor = color !== null && color !== void 0 ? color : colors.$content100;
    const styles = (0, Spinner_styles_1.getSpinnerStyles)();
    const dtiList = (0, Utils_1.getTestIdAttribute)(testId);
    return ((0, jsx_runtime_1.jsx)("div", Object.assign({ id: id, className: `${localClassName} ${styles.spinner} ${className !== null && className !== void 0 ? className : ''}`, style: { maxHeight } }, dtiList, { children: (0, jsx_runtime_1.jsxs)("svg", Object.assign({ role: "img", x: "0", y: "0", height: "100%", width: "100%", "aria-label": title, viewBox: "0 0 50 50", style: {
                alignSelf: 'center',
                justifyContent: 'center',
            } }, { children: [(0, jsx_runtime_1.jsx)("title", { children: title }), !!description && (0, jsx_runtime_1.jsx)("desc", { children: description }), (0, jsx_runtime_1.jsx)("path", Object.assign({ d: "M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z", fill: spinnerColor }, { children: (0, jsx_runtime_1.jsx)("animateTransform", { attributeType: "xml", attributeName: "transform", type: "rotate", from: "0 25 25", to: "360 25 25", dur: "0.6s", repeatCount: "indefinite" }) }))] })) })));
};
exports.Spinner = Spinner;
