"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.modalStyles = void 0;
const Utils_1 = require("../../../../utils/Utils");
const color_1 = require("../../../typings/color");
const colors_1 = require("../colors");
const shadows_1 = require("../shadows");
const StyleUtils_1 = require("../../../../utils/StyleUtils");
const _rootPartial = {
    maxHeight: '90vh',
    maxWidth: '600px',
    minHeight: '500px',
    $mask: {
        backgroundColor: `${colors_1.semanticLightColorValues.$background100}D9`,
        bottom: 0,
        display: 'flex',
        height: (0, StyleUtils_1.vh)(),
        left: 0,
        maxHeight: (0, StyleUtils_1.vh)(),
        maxWidth: '100vw',
        minHeight: (0, StyleUtils_1.vh)(),
        minWidth: '100vw',
        overflow: 'auto',
        position: 'fixed',
        right: 0,
        top: 0,
        width: '100vw',
        zIndex: '10000',
    },
    $content: Object.assign({ backgroundColor: color_1.paletteColorValues.$neutral100, borderRadius: '4px', height: 'fit-content', margin: 'auto', maxHeight: '90vh', overflow: 'auto', width: '90%' }, shadows_1.semanticShadowValues.$shadow100),
};
const _smallPartial = {
    maxWidth: '400px',
    minHeight: '300px',
};
const _largePartial = {
    maxWidth: '1000px',
    minHeight: '800px',
};
const _fullScreenPartial = {
    maxHeight: (0, StyleUtils_1.vh)(),
    maxWidth: '100vw',
    minHeight: (0, StyleUtils_1.vh)(),
    width: '100%',
    $content: {
        width: '100%',
    },
};
exports.modalStyles = {
    default: (0, Utils_1.constructVariantStyles)([_rootPartial]),
    small: (0, Utils_1.constructVariantStyles)([_rootPartial, _smallPartial]),
    large: (0, Utils_1.constructVariantStyles)([_rootPartial, _largePartial]),
    fullScreen: (0, Utils_1.constructVariantStyles)([_rootPartial, _fullScreenPartial]),
};
