"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Radio = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = __importStar(require("react"));
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const Utils_1 = require("../../utils/Utils");
const StyleUtils_1 = require("../../utils/StyleUtils");
const icons_1 = require("../../assets/icons");
const Radio_styles_1 = require("./styles/Radio.styles");
const RadioGroup_1 = require("./RadioGroup");
const __DEV__ = process.env.NODE_ENV !== 'production';
const Radio = (props) => {
    var _a;
    const { checked = false, checkedIcon, className = '', disabled = false, id, label, name, notCheckedIcon, onBlur, onChange, onFocus, testId, value, } = props;
    const theme = (0, ThemeProvider_1.useTheme)();
    const dti = (0, Utils_1.getTestIdAttribute)(testId);
    const radioGroup = (0, RadioGroup_1.useRadioGroup)();
    if (!theme) {
        if (__DEV__) {
            console.error('`Radio` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const styles = (0, Radio_styles_1.getRadioStyles)();
    const [isChecked, setIsChecked] = (0, react_1.useState)(checked);
    const handleChange = (e) => {
        if (radioGroup === null || radioGroup === void 0 ? void 0 : radioGroup.onChange)
            radioGroup.onChange(e);
        if (onChange)
            onChange(e);
    };
    const handleFocus = (e) => {
        if (onFocus)
            onFocus(e);
    };
    const handleBlur = (e) => {
        if (onBlur)
            onBlur(e);
    };
    (0, react_1.useEffect)(() => {
        if (radioGroup.selectedValue === value) {
            setIsChecked(true);
        }
        else
            setIsChecked(false);
    }, [radioGroup]);
    (0, react_1.useEffect)(() => {
        if (!radioGroup.selectedValue)
            setIsChecked(checked);
    }, [checked]);
    return ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: (0, StyleUtils_1.classNames)('Radio-local', styles.container, className) }, dti, { children: (0, jsx_runtime_1.jsxs)("label", Object.assign({ htmlFor: id, className: (0, StyleUtils_1.classNames)('Radio-label-local', styles.label, disabled && styles.disabled) }, (0, Utils_1.getTestIdAttribute)('radioLabel'), { children: [(0, jsx_runtime_1.jsxs)("span", Object.assign({ className: (0, StyleUtils_1.classNames)('Radio-inputContainer-local', styles.inputContainer) }, { children: [(0, jsx_runtime_1.jsx)("input", Object.assign({ className: (0, StyleUtils_1.classNames)('Radio-input-local', styles.input, disabled && styles.disabled), checked: isChecked, disabled: disabled, id: id, name: (_a = radioGroup.name) !== null && _a !== void 0 ? _a : name, value: value, onChange: handleChange, onBlur: handleBlur, onFocus: handleFocus, type: "radio" }, (0, Utils_1.getTestIdAttribute)('radioInput'))), (0, jsx_runtime_1.jsxs)("span", Object.assign({ className: (0, StyleUtils_1.classNames)('Radio-iconContainer-local', styles.iconContainer, disabled && styles.disabled) }, { children: [!isChecked &&
                                    (notCheckedIcon ? (react_1.default.cloneElement(notCheckedIcon, { className: 'Radio-icon-local' })) : ((0, jsx_runtime_1.jsx)(icons_1.RadioEmptyIcon, { className: (0, StyleUtils_1.classNames)('Radio-icon-local', styles.icon, disabled && styles.disabled), testId: "notCheckedIcon" }))), isChecked &&
                                    (checkedIcon ? (react_1.default.cloneElement(checkedIcon, { className: 'Radio-icon-local' })) : ((0, jsx_runtime_1.jsx)(icons_1.RadioSelectedIcon, { className: (0, StyleUtils_1.classNames)('Radio-icon-local', styles.icon, disabled && styles.disabled), testId: "checkedIcon" })))] }))] })), label] })) })));
};
exports.Radio = Radio;
