"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLegalFooterStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
const StyleUtils_1 = require("../../../utils/StyleUtils");
exports.getLegalFooterStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    container: Object.assign(Object.assign({}, (0, StyleUtils_1.flexMixin)('column')), { backgroundColor: theme.colors.$primary500, width: '100%' }),
    header: Object.assign(Object.assign({}, theme.typography.$textPrimary250), { color: theme.colors.$neutral100, margin: '0 auto', maxWidth: StyleUtils_1.maxContainerWidth, padding: theme.spacing.$s5, width: '100%' }),
    content: Object.assign(Object.assign({}, theme.typography.$textSubtext100), { color: theme.colors.$neutral100, columnGap: theme.spacing.$s8, columns: '3', lineHeight: '16px', margin: '0 auto', maxWidth: StyleUtils_1.maxContainerWidth, padding: `0 ${theme.spacing.$s5} ${theme.spacing.$s5}`, width: '100%', '& a': {
            textDecoration: 'underline',
        } }),
    [`@media (max-width: ${theme.breakpoints.$md}px)`]: {
        content: {
            columnGap: theme.spacing.$s5,
            columns: '2',
        },
    },
    [`@media (max-width: ${theme.breakpoints.$sm}px)`]: {
        header: Object.assign(Object.assign({}, theme.typography.$textPrimary150), { color: theme.colors.$neutral100 }),
        content: {
            columns: '1',
        },
    },
}), { name: 'Blueprint-LegalFooter' });
