"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFooterSectionItemStyle = void 0;
const Utils_1 = require("../../../utils/Utils");
exports.getFooterSectionItemStyle = (0, Utils_1.createStyleSheet)((theme) => ({
    container: {
        width: '100%',
    },
    link: Object.assign({}, theme.variants.footers.default.$link),
}), { name: 'Blueprint-FooterSectionItem' });
