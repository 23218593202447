"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Collapse = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const StyleUtils_1 = require("../../utils/StyleUtils");
const Utils_1 = require("../../utils/Utils");
const Collapse_styles_1 = require("./styles/Collapse.styles");
const Collapse = (props) => {
    const { children, className = '', shouldUnmount = false, in: inProp = false, transitionSpeed = 200, testId, id, } = props;
    const styles = (0, Collapse_styles_1.getCollapseStyles)();
    const dti = (0, Utils_1.getTestIdAttribute)(testId);
    const elId = id !== null && id !== void 0 ? id : (0, react_1.useMemo)(() => (0, Utils_1.generateUUID)(), []);
    const collapseRef = (0, react_1.useRef)(null);
    const [state, setState] = (0, react_1.useState)({
        entered: inProp,
        exited: !inProp,
        isEntering: false,
        isExiting: false,
    });
    const setTabIndexOnChildren = () => {
        const elements = (0, StyleUtils_1.getFocusableElements)(elId);
        if (elements) {
            (0, StyleUtils_1.setFocusableOnElement)(elements, inProp ? 0 : -1);
        }
    };
    const handleTransitionIn = () => {
        setState(Object.assign(Object.assign({}, state), { exited: false, isEntering: true }));
        // allow render to happen is shouldUnmount is true
        setTimeout(() => {
            setCollapseStyles();
            if (!shouldUnmount)
                setTabIndexOnChildren();
        }, 0);
        setTimeout(() => {
            setState(Object.assign(Object.assign({}, state), { isEntering: false, entered: true, exited: false }));
            // once transition has completed, let height be auto;
            if (collapseRef.current)
                collapseRef.current.style.height = 'auto';
        }, transitionSpeed);
    };
    const handleTransitionOut = () => {
        // reset height to fixed amount based on scroll height to allow for transition to close
        if (collapseRef.current)
            collapseRef.current.style.height = `${collapseRef.current.scrollHeight}px`;
        setState(Object.assign(Object.assign({}, state), { entered: false, isExiting: true }));
        // allow render to happen is shouldUnmount is true
        setTimeout(() => {
            setCollapseStyles();
            if (!shouldUnmount)
                setTabIndexOnChildren();
        }, 0);
        setTimeout(() => {
            setState(Object.assign(Object.assign({}, state), { isExiting: false, entered: false, exited: true }));
        }, transitionSpeed);
    };
    const setCollapseStyles = () => {
        if (collapseRef.current) {
            collapseRef.current.style.height = `${inProp ? collapseRef.current.scrollHeight : 0}px`;
            collapseRef.current.style.opacity = inProp ? '1' : '0';
            collapseRef.current.style.transition = `height ${transitionSpeed}ms ease, opacity ${transitionSpeed}ms ease`;
        }
    };
    (0, react_1.useEffect)(() => {
        if (inProp)
            handleTransitionIn();
        else
            handleTransitionOut();
    }, [inProp]);
    return ((0, jsx_runtime_1.jsx)("div", Object.assign({ ref: collapseRef, id: elId, className: (0, StyleUtils_1.classNames)('Collapse-local', styles.container, className) }, dti, { children: shouldUnmount && state.exited ? null : children })));
};
exports.Collapse = Collapse;
