"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SquareMark = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const Utils_1 = require("../../utils/Utils");
const SquareMark = (props) => {
    const theme = (0, ThemeProvider_1.useTheme)();
    const { background = theme.colors.$primary300, className, fill = theme.colors.$neutral100, testId } = props;
    return ((0, jsx_runtime_1.jsxs)("svg", Object.assign({ className: className, width: "200", height: "200", viewBox: "0 0 200 200", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, (0, Utils_1.getTestIdAttribute)(testId), { children: [(0, jsx_runtime_1.jsx)("rect", { width: "200", height: "200", rx: "4", fill: background }), (0, jsx_runtime_1.jsx)("path", { d: "M147.086 64.5796L104.9 21.6064L102.341 19L94.689 21.6064L52.509 64.5796V73.7886L87.2693 48.6608C87.2693 48.6608 91.7127 46.3108 91.7127 63.4822V138.09C91.5815 152.494 87.8359 150.449 87.8359 150.449L52.4732 124.945L52.515 133.921L99.7825 182L107.876 173.906V63.4822C107.876 46.3227 112.314 48.6548 112.32 48.6608L147.086 73.7886V64.5796Z", fill: fill })] })));
};
exports.SquareMark = SquareMark;
