"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Switch = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_intl_1 = require("react-intl");
const StyleUtils_1 = require("../../utils/StyleUtils");
const Utils_1 = require("../../utils/Utils");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const theme_1 = require("../../themes/typings/theme");
const Switch_styles_1 = require("./styles/Switch.styles");
const __DEV__ = process.env.NODE_ENV !== 'production';
const Switch = (props) => {
    const theme = (0, ThemeProvider_1.useTheme)();
    const intl = (0, react_intl_1.useIntl)();
    if (!theme) {
        if (__DEV__) {
            console.error('`Switch` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    if (!intl) {
        if (__DEV__) {
            console.error('`Switch` component must be a child of a `IntlProvider`');
        }
        return null;
    }
    const { ariaLabel, checked = false, className = '', disabled = false, id, offIcon = null, onChange, onIcon = null, options = {
        scale: 1,
    }, testId, } = props;
    const styles = (0, Switch_styles_1.getSwitchStyles)();
    const dti = (0, Utils_1.getTestIdAttribute)(testId);
    const [isChecked, setIsChecked] = (0, react_1.useState)(checked);
    const handleScalingComponent = () => {
        var _a, _b, _c, _d, _e, _f;
        return {
            slide: {
                width: `${40 * ((_a = options === null || options === void 0 ? void 0 : options.scale) !== null && _a !== void 0 ? _a : 1)}px`,
                height: `${12 * ((_b = options === null || options === void 0 ? void 0 : options.scale) !== null && _b !== void 0 ? _b : 1)}px`,
                borderRadius: `${6 * ((_c = options === null || options === void 0 ? void 0 : options.scale) !== null && _c !== void 0 ? _c : 1)}px`,
            },
            knob: {
                width: `${24 * ((_d = options === null || options === void 0 ? void 0 : options.scale) !== null && _d !== void 0 ? _d : 1)}px`,
                height: `${24 * ((_e = options === null || options === void 0 ? void 0 : options.scale) !== null && _e !== void 0 ? _e : 1)}px`,
                top: `-${((24 - 12) * ((_f = options === null || options === void 0 ? void 0 : options.scale) !== null && _f !== void 0 ? _f : 1)) / 2}px`,
            },
        };
    };
    const getColor = (type) => {
        if (disabled)
            return {};
        if (type === 'slide') {
            return {
                backgroundColor: options.slideColor
                    ? options.slideColor
                    : theme.__base === theme_1.GlobalBaseTheme.DARK
                        ? theme.colors.$primary200
                        : theme.colors.$neutral400,
            };
        }
        else {
            return {
                backgroundColor: options.knobColor
                    ? options.knobColor
                    : isChecked
                        ? theme.colors.$secondary300
                        : theme.__base === theme_1.GlobalBaseTheme.DARK
                            ? theme.colors.$primary100
                            : theme.colors.$neutral100,
            };
        }
    };
    const handleOnChange = (e) => {
        if (onChange)
            onChange(e.currentTarget.checked);
        setIsChecked(!isChecked);
    };
    return ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: (0, StyleUtils_1.classNames)('Switch-local', styles.container, className) }, dti, { children: (0, jsx_runtime_1.jsxs)("label", Object.assign({ htmlFor: id, className: (0, StyleUtils_1.classNames)('Switch-label-local', styles.label, disabled && styles.disabled) }, { children: [(0, jsx_runtime_1.jsx)("input", Object.assign({ id: id, type: "checkbox", className: (0, StyleUtils_1.classNames)('Switch-input-local', styles.input, disabled && styles.disabled), "aria-label": ariaLabel !== null && ariaLabel !== void 0 ? ariaLabel : intl.formatMessage({
                        id: 'blueprint.switch.ariaLabel',
                        defaultMessage: `Switch ${isChecked ? 'on' : 'off'}`,
                        description: 'Blueprint Switch default aria label',
                    }), defaultChecked: isChecked, disabled: disabled, onChange: handleOnChange }, (0, Utils_1.getTestIdAttribute)('switchInput'))), (0, jsx_runtime_1.jsx)("div", Object.assign({ className: (0, StyleUtils_1.classNames)('Switch-slideContainer-local', styles.slideContainer, disabled && styles.disabled), style: Object.assign(Object.assign({}, handleScalingComponent().slide), getColor('slide')) }, (0, Utils_1.getTestIdAttribute)('switchSlideContainer'), { children: (0, jsx_runtime_1.jsx)("span", Object.assign({ className: (0, StyleUtils_1.classNames)('Switch-slideKnob-local', styles.slideKnob, isChecked ? styles.on : styles.off, disabled && styles.disabled), style: Object.assign(Object.assign({}, handleScalingComponent().knob), getColor('knob')) }, (0, Utils_1.getTestIdAttribute)('switchSlideKnob'), { children: isChecked ? onIcon : offIcon })) }))] })) })));
};
exports.Switch = Switch;
