"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNavBarStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
exports.getNavBarStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    navBarContainer: {
        display: 'flex',
        width: '100%',
        height: '100%',
    },
    navBarWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
        gap: theme.spacing.$s3,
    },
}), { name: 'Blueprint-NavBar' });
