"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultFooterItems = void 0;
exports.defaultFooterItems = [
    {
        id: 'locationFooter',
        title: 'Location',
        items: [],
    },
    {
        id: 'companyFooter',
        title: 'Company',
        items: [
            {
                id: 'aboutFooter',
                href: '/about',
                label: 'About',
                experience: 'landing',
            },
            {
                id: 'careersFooter',
                href: '/careers',
                label: 'Careers',
                experience: 'landing',
            },
            {
                id: 'careersRealty',
                href: '/realty',
                label: 'Groma Realty',
                experience: 'landing',
            },
            {
                id: 'faqFooter',
                href: '/faq',
                label: 'FAQ',
                experience: 'landing',
            },
        ],
    },
    {
        id: 'resourcesFooter',
        title: 'Resources',
        items: [
            {
                id: 'investorPortalFooter',
                href: '/',
                label: 'Investor Portal',
                isExternal: true,
                experience: 'investors',
            },
            {
                id: 'tenantPortalFooter',
                href: 'https://groma.managebuilding.com/Resident/portal/login',
                label: 'Tenant Portal',
                isExternal: true,
            },
        ],
    },
    {
        id: 'connectFooter',
        title: 'Connect',
        items: [
            {
                id: 'pressFooter',
                href: '/press',
                label: 'Press',
                experience: 'landing',
            },
            {
                id: 'blogFooter',
                href: 'https://groma.substack.com/',
                label: 'Blog',
                isExternal: true,
            },
            {
                id: 'contactFooter',
                href: '/contact',
                label: 'Contact',
                experience: 'landing',
            },
            {
                id: 'emailFooter',
                href: 'mailto:hello@groma.com',
                label: 'Email',
            },
        ],
    },
];
