"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.spacingValues = void 0;
exports.spacingValues = {
    $s0: '0px',
    $s1: '4px',
    $s2: '8px',
    $s3: '16px',
    $s4: '24px',
    $s5: '32px',
    $s6: '48px',
    $s7: '64px',
    $s8: '80px',
};
