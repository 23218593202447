"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTableStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
exports.getTableStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    tableContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        position: 'relative',
    },
    tableWrapper: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'auto',
        width: '100%',
    },
    table: {
        borderSpacing: 0,
        height: 'fit-content',
        tableLayout: 'auto',
        width: '100%',
    },
    grid: {
        borderSpacing: '1px',
    },
    caption: Object.assign(Object.assign({}, theme.typography.$textSecondary100), { textAlign: 'left', margin: `0 0 ${theme.spacing.$s2} ${theme.spacing.$s3}` }),
    utils: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        margin: `${theme.spacing.$s3} 0`,
    },
    pagination: {},
    loading: {
        zIndex: '1000',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: `${theme.colors.$background100}CC`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    spinner: {
        width: '40px',
        height: '40px',
        '& path': {
            fill: theme.colors.$content100,
        },
    },
}), { name: 'Blueprint-Table' });
