"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSlideStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
exports.getSlideStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    slide: {
        alignItems: 'center',
        display: 'flex',
        height: 'fit-content',
        justifyContent: 'center',
        minWidth: '100%',
        width: '100%',
    },
    [`@media (max-width: ${theme.breakpoints.$sm}px)`]: {},
}), { name: 'Blueprint-Slide' });
