"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InfoBean = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Utils_1 = require("../../utils/Utils");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const __DEV__ = process.env.NODE_ENV !== 'production';
const InfoBean = (props) => {
    const theme = (0, ThemeProvider_1.useTheme)();
    if (!theme) {
        if (__DEV__) {
            console.error('`InfoBean` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const { className, testId } = props;
    return ((0, jsx_runtime_1.jsxs)("svg", Object.assign({ className: className, width: "28", height: "36", viewBox: "0 0 28 36", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, (0, Utils_1.getTestIdAttribute)(testId), { children: [(0, jsx_runtime_1.jsx)("rect", { width: "28", height: "35.3334", rx: "14", transform: "matrix(-1 0 0 1 28 0.083313)", fill: "#F57233" }), (0, jsx_runtime_1.jsx)("path", { d: "M6.22029 17.638C6.22029 18.9748 7.38273 20.5269 9.44251 20.5269C11.5023 20.5269 12.6647 18.9748 12.6647 17.638", stroke: "#293831", strokeWidth: "1.76116" }), (0, jsx_runtime_1.jsx)("ellipse", { rx: "1.55556", ry: "1.66667", transform: "matrix(-1 0 0 1 14.8887 12.8607)", fill: "#293831" }), (0, jsx_runtime_1.jsx)("ellipse", { rx: "1.55556", ry: "1.66667", transform: "matrix(-1 0 0 1 5.11327 12.8607)", fill: "#293831" })] })));
};
exports.InfoBean = InfoBean;
