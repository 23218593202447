"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.JssProvider = exports.generateStandardId = exports.generateHashCode = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_jss_1 = require("react-jss");
const generateHashCode = (s) => {
    return s.split('').reduce((a, b) => {
        // eslint-disable-next-line no-bitwise
        a = (a << 5) - a + b.charCodeAt(0);
        // eslint-disable-next-line no-bitwise
        return a & a;
    }, 0);
};
exports.generateHashCode = generateHashCode;
/**
 * Used to generate a className based on sheet name, class and a hash from the classname
 * @param rule
 * @param sheet
 */
const generateStandardId = (rule, sheet, themeId = '') => {
    const className = `${sheet === null || sheet === void 0 ? void 0 : sheet.options.classNamePrefix}${rule.key}`;
    const strHash = (0, exports.generateHashCode)(`${className}${themeId}`);
    return `${process.env.NODE_ENV === 'production' ? 'jss' : className}${String(strHash).indexOf('-') >= 0 ? '' : '-'}${strHash}`;
};
exports.generateStandardId = generateStandardId;
/**
 * Should be as high in the application as possible so all styles in the application leverage JSS
 */
const JssProvider = (_a) => {
    var props = __rest(_a, []);
    const { children, generateId } = props, additionalProps = __rest(props, ["children", "generateId"]);
    // TODO: Update when JSS resolves the warning about dynamic values in stylesheets
    // https://github.com/cssinjs/jss/issues/1533
    if (typeof window === 'undefined') {
        const originalWarn = console.warn;
        console.warn = (...args) => {
            if (args[0] !== 'Warning: [JSS] Rule is not linked. Missing sheet option "link: true".') {
                originalWarn(...args);
            }
        };
    }
    return ((0, jsx_runtime_1.jsx)(react_jss_1.JssProvider, Object.assign({}, additionalProps, { generateId: generateId !== null && generateId !== void 0 ? generateId : exports.generateStandardId }, { children: children })));
};
exports.JssProvider = JssProvider;
