"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tract = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const Utils_1 = require("../../utils/Utils");
const Track_styles_1 = require("./styles/Track.styles");
const Carousel_1 = require("./Carousel");
const __DEV__ = process.env.NODE_ENV !== 'production';
const Tract = (props) => {
    const { children } = props;
    const theme = (0, ThemeProvider_1.useTheme)();
    const carousel = (0, Carousel_1.useCarousel)();
    const dti = (0, Utils_1.getTestIdAttribute)('carouselTrack');
    const [slides] = (0, react_1.useState)(react_1.Children.toArray(children));
    const clonedFirstSlide = (0, react_1.useMemo)(() => (0, react_1.cloneElement)(slides[0], { tabIndex: -1, 'data-index': -1, key: -1 }), []);
    const clonedLastSlide = (0, react_1.useMemo)(() => (0, react_1.cloneElement)(slides[slides.length - 1], {
        tabIndex: -1,
        'data-index': carousel.totalSlides + 1,
        key: carousel.totalSlides + 1,
    }), []);
    const [currentSlides] = (0, react_1.useState)([clonedLastSlide, ...slides, clonedFirstSlide]);
    if (!theme) {
        if (__DEV__) {
            console.error('`Track` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const styles = (0, Track_styles_1.getTrackStyles)();
    const controls = (0, react_1.useMemo)(() => ({
        carouselTrackRef: (0, react_1.createRef)(),
    }), []);
    const translateTrack = () => {
        if (controls.carouselTrackRef.current) {
            controls.carouselTrackRef.current.style.translate = `-${carousel.translate}px`;
            controls.carouselTrackRef.current.style.transition = `translate ${carousel.transition}ms`;
        }
    };
    (0, react_1.useEffect)(() => {
        translateTrack();
    }, []);
    (0, react_1.useEffect)(() => {
        translateTrack();
    }, [carousel]);
    return ((0, jsx_runtime_1.jsx)("div", Object.assign({ ref: controls.carouselTrackRef, className: `Carousel-track-local ${styles.track}` }, dti, { children: currentSlides })));
};
exports.Tract = Tract;
