"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTableRowStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
const theme_1 = require("../../../themes/typings/theme");
exports.getTableRowStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    container: {
        width: '100%',
        height: 'fit-content',
        backgroundColor: theme.colors.$background100,
    },
    striped: {
        '&:nth-child(even)': {
            backgroundColor: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$primary400 : theme.colors.$tertiary200,
        },
    },
}), { name: 'Blueprint-TableRow' });
