"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Account = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_intl_1 = require("react-intl");
const Link_1 = require("../Link/Link");
const Utils_1 = require("../../utils/Utils");
const Account_styles_1 = require("./styles/Account.styles");
const Account = (props) => {
    const { user, domains, handleSignOut } = props;
    const styles = (0, Account_styles_1.getAccountStyles)();
    const dti = (0, Utils_1.getTestIdAttribute)('accountDetails');
    const userLetters = `${user.first_name.charAt(0).toUpperCase()}${user.last_name.charAt(0).toUpperCase()}`;
    return ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: styles.mainContainer }, { children: (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: styles.mainWrapper }, dti, { children: [(0, jsx_runtime_1.jsxs)("div", Object.assign({ className: styles.contentContainer }, { children: [(0, jsx_runtime_1.jsxs)("div", Object.assign({ className: styles.detailsContainer }, { children: [(0, jsx_runtime_1.jsx)("div", Object.assign({ className: styles.avatarContainer }, { children: (0, jsx_runtime_1.jsx)("span", Object.assign({ className: styles.avatar }, { children: userLetters.length > 0 ? userLetters : user.email.charAt(0).toUpperCase() })) })), (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: styles.detailsWrapper }, { children: [(0, jsx_runtime_1.jsx)("p", Object.assign({ className: styles.name }, { children: `${user.first_name} ${user.last_name}` })), (0, jsx_runtime_1.jsx)("p", Object.assign({ className: styles.email }, { children: user.email }))] }))] })), (0, jsx_runtime_1.jsx)("div", Object.assign({ className: styles.linkContainer }, { children: (0, jsx_runtime_1.jsx)(Link_1.Link, Object.assign({ href: domains.identity, useRouter: false, variant: Link_1.LinkVariants.NAV, className: styles.link, testId: "manageAccountLink" }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "blueprint.account.manageProfile", defaultMessage: "Manage Profile", description: "Blueprint Account manage profile link text" }) })) }))] })), (0, jsx_runtime_1.jsx)("div", Object.assign({ className: styles.contentContainer }, { children: (0, jsx_runtime_1.jsx)(Link_1.Link, Object.assign({ href: `${domains.api}/logout`, useRouter: false, onClick: handleSignOut, className: styles.link, testId: "signOutLink" }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "blueprint.account.signout", defaultMessage: "Sign Out", description: "Blueprint Account signout link text" }) })) }))] })) })));
};
exports.Account = Account;
