"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NavItem = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Link_1 = require("../Link/Link");
const StyleUtils_1 = require("../../utils/StyleUtils");
const Utils_1 = require("../../utils/Utils");
const NavItem_styles_1 = require("./styles/NavItem.styles");
const NavItem = (props) => {
    const { navItem, domains } = props;
    const styles = (0, NavItem_styles_1.getNavItemStyles)();
    return ((0, jsx_runtime_1.jsx)("li", { children: !navItem.render ? ((0, jsx_runtime_1.jsx)(Link_1.Link, Object.assign({ id: navItem.id, href: `${navItem.experience ? (0, Utils_1.getDomainBasedOnExperience)(domains, navItem.experience) : ''}${navItem.href}`, disabled: navItem.disabled, className: (0, StyleUtils_1.classNames)(styles.navItem, navItem.disabled && styles.disabled), variant: Link_1.LinkVariants.NAV, useActiveNav: true, useRouter: !navItem.isExternal, target: navItem.openNewTab ? '_blank' : '_self' }, { children: navItem.label }))) : (navItem.render(props)) }));
};
exports.NavItem = NavItem;
