"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toastStyles = void 0;
const Utils_1 = require("../../../../utils/Utils");
const typography_1 = require("../typography");
const spacing_1 = require("../spacing");
const color_1 = require("../../../typings/color");
const shadow_1 = require("../../../typings/shadow");
const colors_1 = require("../colors");
const _rootPartial = Object.assign(Object.assign({ backgroundColor: colors_1.semanticLightColorValues.$background100, borderColor: colors_1.semanticLightColorValues.$background100, borderRadius: '4px', borderStyle: 'solid', borderWidth: '1px', display: 'flex', maxWidth: '80vw', width: 'fit-content' }, shadow_1.dropShadowValues.$dropShadow100), { $success: Object.assign(Object.assign({}, typography_1.typographyValues.$textPrimary000), { borderColor: color_1.paletteColorValues.$success500, color: color_1.paletteColorValues.$success500, marginBottom: spacing_1.spacingValues.$s3 }), $error: Object.assign(Object.assign({}, typography_1.typographyValues.$textPrimary000), { borderColor: color_1.paletteColorValues.$error300, color: color_1.paletteColorValues.$error300, marginBottom: spacing_1.spacingValues.$s3 }), $icon: {
        width: '15px',
        height: '15px',
        '& path': {
            fill: colors_1.semanticLightColorValues.$background100,
        },
    }, $close: {
        alignItems: 'center',
        backgroundColor: 'transparent',
        display: 'flex',
        height: '20px',
        justifyContent: 'center',
        position: 'absolute',
        right: '5px',
        top: '5px',
        width: '20px',
        padding: spacing_1.spacingValues.$s1,
        '&:hover': {
            cursor: 'pointer',
        },
    }, $closeIcon: {
        height: '12px',
        width: '12px',
        '& path': {
            fill: color_1.paletteColorValues.$error300,
        },
    } });
exports.toastStyles = {
    default: (0, Utils_1.constructVariantStyles)([_rootPartial]),
};
