"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Accordion = exports.useAccordion = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = __importStar(require("react"));
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const Utils_1 = require("../../utils/Utils");
const StyleUtils_1 = require("../../utils/StyleUtils");
const Collapse_1 = require("../Collapse/Collapse");
const Accordion_styles_1 = require("./styles/Accordion.styles");
const AccordionGroup_1 = require("./AccordionGroup");
const __DEV__ = process.env.NODE_ENV !== 'production';
const AccordionContext = (0, react_1.createContext)(undefined);
const useAccordion = () => (0, react_1.useContext)(AccordionContext);
exports.useAccordion = useAccordion;
const Accordion = (props) => {
    const { allowExpand = true, children: childrenProp, className = '', testId, id, expanded = false, disabled = false, onChange, transitionSpeed = 200, } = props;
    const theme = (0, ThemeProvider_1.useTheme)();
    if (!theme) {
        if (__DEV__) {
            console.error('`Accordion` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const styles = (0, Accordion_styles_1.getAccordionStyles)();
    const dti = (0, Utils_1.getTestIdAttribute)(testId);
    const accordionGroup = (0, AccordionGroup_1.useAccordionGroup)();
    const parentAccordion = (0, exports.useAccordion)();
    // If Accordion is a child of an Accordion Group, then check if the AccordionGroup is nested or if the Accordion is nested inside a parent Accordion.
    const hasAccordionGroup = accordionGroup && (accordionGroup.isNested || !parentAccordion);
    let elId = id;
    const [initialized, setInitialized] = (0, react_1.useState)(false);
    if (accordionGroup && !elId) {
        // generate ID on initialization but not re-renders
        elId = (0, react_1.useMemo)(() => (0, Utils_1.generateUUID)(), []);
    }
    const [summary, ...remainingChildren] = react_1.default.Children.toArray(childrenProp);
    const [open, setOpen] = (0, react_1.useState)(hasAccordionGroup ? accordionGroup.selectedAccordionId === elId : expanded);
    const handleOnChange = (isExpanded) => {
        if (onChange)
            onChange(isExpanded);
        setOpen(isExpanded);
        // set active ID in AccordionGroup
        if (isExpanded && (accordionGroup === null || accordionGroup === void 0 ? void 0 : accordionGroup.setActiveAccordion) && hasAccordionGroup) {
            accordionGroup.setActiveAccordion(elId);
        }
    };
    const value = (0, react_1.useMemo)(() => {
        return {
            allowExpand,
            expanded: open,
            disabled,
            toggle: handleOnChange,
        };
    }, [allowExpand, open, disabled, onChange]);
    (0, react_1.useEffect)(() => {
        // if Accordion is a child of the AccordionGroup component, check ID of active Accordion
        // otherwise, use Expanded key
        setOpen(hasAccordionGroup ? accordionGroup.selectedAccordionId === elId : expanded);
    }, [accordionGroup, expanded]);
    (0, react_1.useEffect)(() => {
        // Prevents transition on first render
        setInitialized(true);
    }, []);
    return ((0, jsx_runtime_1.jsx)("div", Object.assign({ id: elId, className: (0, StyleUtils_1.classNames)('Accordion-local', styles.container, className, open && styles.open) }, dti, { children: (0, jsx_runtime_1.jsx)(AccordionContext.Provider, Object.assign({ value: value }, { children: (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [summary, allowExpand && ((0, jsx_runtime_1.jsx)(Collapse_1.Collapse, Object.assign({ in: open, transitionSpeed: initialized ? transitionSpeed : 0 }, { children: remainingChildren })))] }) })) })));
};
exports.Accordion = Accordion;
