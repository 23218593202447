"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Carousel = exports.useCarousel = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const Utils_1 = require("../../utils/Utils");
const icons_1 = require("../../assets/icons");
const Carousel_styles_1 = require("./styles/Carousel.styles");
const Slide_1 = require("./Slide");
const Track_1 = require("./Track");
const __DEV__ = process.env.NODE_ENV !== 'production';
const CarouselContext = (0, react_1.createContext)({
    carouselWidth: 0,
    currentSlide: 0,
    transition: 0,
    translate: 0,
    totalSlides: 0,
    isActiveSlide: () => { },
});
const useCarousel = () => (0, react_1.useContext)(CarouselContext);
exports.useCarousel = useCarousel;
const Carousel = (props) => {
    var _a, _b;
    const { children, id, testId, className = '', showArrows = true, showDots = true, autoPlay = false, autoPlaySpeed = 5000, transitionSpeed = 1000, activeSlideIndex = 0, allowSwipe = true, } = props;
    const theme = (0, ThemeProvider_1.useTheme)();
    const styles = (0, Carousel_styles_1.getCarouselStyles)();
    const dti = (0, Utils_1.getTestIdAttribute)(testId);
    // Prevents new refs from being created on re-render
    const controls = (0, react_1.useMemo)(() => ({
        carouselRef: (0, react_1.createRef)(),
    }), []);
    const autoPlayRef = (0, react_1.useRef)();
    const starterSlide = activeSlideIndex <= react_1.Children.count(children) - 1 ? activeSlideIndex + 1 : 1;
    const [carouselWidth, setCarouselWidth] = (0, react_1.useState)((_a = controls.carouselRef.current) === null || _a === void 0 ? void 0 : _a.offsetWidth);
    const [currentSlide, setCurrentSlide] = (0, react_1.useState)(starterSlide);
    const [totalSlides] = (0, react_1.useState)(react_1.Children.count(children));
    const [transition, setTransition] = (0, react_1.useState)(0);
    const [translate, setTranslate] = (0, react_1.useState)((_b = controls.carouselRef.current) === null || _b === void 0 ? void 0 : _b.offsetWidth);
    const [transitioningSlides, setTransitioningSlides] = (0, react_1.useState)(false);
    const [pauseAutoPlay, setPauseAutoPlay] = (0, react_1.useState)(false);
    const [swipeLocation, setSwipeLocation] = (0, react_1.useState)(0);
    // Allows initial render, regardless of slide, to set before rendering Carousel
    const [hidden, setHidden] = (0, react_1.useState)(true);
    const modifiedSlides = (0, react_1.useMemo)(() => react_1.Children.map(children, (child, index) => {
        return (0, react_1.cloneElement)(child, {
            'data-index': index,
            key: index,
        });
    }), [currentSlide]);
    if (!theme) {
        if (__DEV__) {
            console.error('`Carousel` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    const handlePauseAutoPlay = (value) => {
        setPauseAutoPlay(value);
    };
    const handleResize = () => {
        var _a;
        if (controls.carouselRef.current) {
            setTransition(0);
            setCarouselWidth((_a = controls.carouselRef) === null || _a === void 0 ? void 0 : _a.current.offsetWidth);
        }
    };
    const handleArrowClick = (direction) => {
        if (!transitioningSlides) {
            if (direction === 'prev')
                prevSlide();
            else
                nextSlide();
        }
    };
    const getTranslateAmount = (slide) => {
        return carouselWidth * slide;
    };
    const isActiveSlide = (index) => {
        const currentIndex = currentSlide <= 1 ? 0 : currentSlide >= totalSlides ? totalSlides - 1 : currentSlide - 1;
        return index === currentIndex;
    };
    const prevSlide = (speed) => {
        // current slide equals first slide
        if (currentSlide === 1) {
            // translate to cloned last slide
            setTranslate(getTranslateAmount(0));
            setTransition(speed !== null && speed !== void 0 ? speed : transitionSpeed);
            setCurrentSlide(0);
            // translate to last slide
            setTimeout(() => {
                setTranslate(getTranslateAmount(totalSlides));
                setTransition(0);
                setCurrentSlide(totalSlides);
            }, speed !== null && speed !== void 0 ? speed : transitionSpeed);
        }
        else {
            // translate to previous slide
            setTranslate(getTranslateAmount(currentSlide - 1));
            setTransition(speed !== null && speed !== void 0 ? speed : transitionSpeed);
            setCurrentSlide(currentSlide - 1);
        }
        manageTransitionSlides(speed);
    };
    const nextSlide = (speed) => {
        // current slide equals last slide
        if (currentSlide === totalSlides) {
            // translate to cloned first slide
            setTranslate(getTranslateAmount(totalSlides + 1));
            setTransition(speed !== null && speed !== void 0 ? speed : transitionSpeed);
            setCurrentSlide(totalSlides + 1);
            // translate to first slide
            setTimeout(() => {
                setTranslate(getTranslateAmount(1));
                setTransition(0);
                setCurrentSlide(1);
            }, speed !== null && speed !== void 0 ? speed : transitionSpeed);
        }
        else {
            // translate to next slide
            setTranslate(getTranslateAmount(currentSlide + 1));
            setTransition(speed !== null && speed !== void 0 ? speed : transitionSpeed);
            setCurrentSlide(currentSlide + 1);
        }
        manageTransitionSlides(speed);
    };
    const providerValue = (0, react_1.useMemo)(() => ({
        carouselWidth,
        currentSlide,
        totalSlides,
        translate,
        transition,
        isActiveSlide,
    }), [currentSlide, translate, transition, carouselWidth]);
    const manageTransitionSlides = (speed) => {
        setTransitioningSlides(true);
        setTimeout(() => setTransitioningSlides(false), speed !== null && speed !== void 0 ? speed : transitionSpeed);
    };
    const renderDots = () => {
        const dots = [];
        const handleDotClick = (i) => {
            if (!transitioningSlides) {
                setTranslate(getTranslateAmount(i));
                setTransition(transitionSpeed);
                setCurrentSlide(i);
                manageTransitionSlides();
            }
        };
        for (let i = 1; i <= totalSlides; i++) {
            const isActive = i === currentSlide;
            dots.push((0, jsx_runtime_1.jsx)("button", Object.assign({ "aria-label": `slide ${i + 1}` }, (0, Utils_1.getTestIdAttribute)('dot'), { className: `Carousel-dot-local ${styles.dot} ${isActive ? styles.active : ''}`, onClick: () => handleDotClick(i) }), i));
        }
        return (0, jsx_runtime_1.jsx)("div", Object.assign({ className: `Carousel-dots-local ${styles.dotsContainer}` }, { children: dots.map(dot => dot) }));
    };
    const handleKeyboardEvent = (e) => {
        var _a;
        // Handles toggle pause of autoplay based on focus or blur
        if ((_a = controls.carouselRef.current) === null || _a === void 0 ? void 0 : _a.contains(e.target)) {
            if (e.type === 'focus')
                handlePauseAutoPlay(true);
            else if (e.type === 'blur')
                handlePauseAutoPlay(false);
        }
    };
    const handleTouchStart = (e) => {
        var _a, _b;
        if (allowSwipe)
            setSwipeLocation((_b = (_a = e.changedTouches[0]) === null || _a === void 0 ? void 0 : _a.clientX) !== null && _b !== void 0 ? _b : 0);
    };
    const handleTouchEnd = (e) => {
        var _a, _b;
        if (allowSwipe) {
            const distance = swipeLocation - ((_b = (_a = e.changedTouches[0]) === null || _a === void 0 ? void 0 : _a.clientX) !== null && _b !== void 0 ? _b : 0);
            const distanceThreshold = 75;
            if (!transitioningSlides) {
                // swipe right
                if (distance < 0 && Math.abs(distance) >= distanceThreshold)
                    prevSlide(500);
                // swipe left
                else if (Math.abs(distance) >= distanceThreshold)
                    nextSlide(500);
                else {
                    setTranslate(getTranslateAmount(currentSlide));
                    setTransition(transitionSpeed);
                }
            }
            setSwipeLocation(0);
        }
    };
    const handleDrag = (e) => {
        var _a, _b;
        if (allowSwipe) {
            const distance = swipeLocation - ((_b = (_a = e.changedTouches[0]) === null || _a === void 0 ? void 0 : _a.clientX) !== null && _b !== void 0 ? _b : 0);
            if (!transitioningSlides) {
                if (distance < 0) {
                    const translateDistance = getTranslateAmount(currentSlide) - Math.abs(distance);
                    setTranslate(translateDistance);
                    setTransition(0);
                }
                // swipe left
                else {
                    const translateDistance = getTranslateAmount(currentSlide) + Math.abs(distance);
                    setTranslate(translateDistance);
                    setTransition(0);
                }
            }
        }
    };
    (0, react_1.useEffect)(() => {
        // Removes hidden class from Carousel to make visible
        if (hidden)
            setTimeout(() => setHidden(false), 0);
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    (0, react_1.useEffect)(() => {
        // initial translate to align on active slide
        setTranslate(getTranslateAmount(currentSlide));
        setTransition(0);
    }, [carouselWidth]);
    (0, react_1.useEffect)(() => {
        if (autoPlay) {
            autoPlayRef.current = nextSlide;
        }
    });
    (0, react_1.useEffect)(() => {
        const play = () => autoPlayRef.current();
        if (autoPlay) {
            const interval = setInterval(play, autoPlaySpeed);
            if (!pauseAutoPlay) {
                return () => clearInterval(interval);
            }
            else
                clearInterval(interval);
        }
    }, [pauseAutoPlay]);
    return ((0, jsx_runtime_1.jsxs)("div", Object.assign({ ref: controls.carouselRef, role: "presentation", id: id, className: `Carousel-local ${styles.mainContainer} ${className} ${hidden ? styles.hidden : ''}`, onMouseEnter: () => handlePauseAutoPlay(true), onMouseLeave: () => handlePauseAutoPlay(false), onFocus: handleKeyboardEvent, onBlur: handleKeyboardEvent, onTouchStart: handleTouchStart, onTouchEnd: handleTouchEnd, onTouchMove: handleDrag }, dti, { children: [(0, jsx_runtime_1.jsxs)("div", Object.assign({ className: `Carousel-wrapper-local ${styles.mainWrapper}` }, { children: [showArrows && ((0, jsx_runtime_1.jsx)("button", Object.assign({ className: `Carousel-arrowContainer-local ${styles.arrowContainer} ${styles.prevArrow}`, "aria-label": "previous slide" }, (0, Utils_1.getTestIdAttribute)('prevButton'), { onClick: () => handleArrowClick('prev') }, { children: (0, jsx_runtime_1.jsx)(icons_1.ArrowLeftIcon, { className: styles.icon }) }))), (0, jsx_runtime_1.jsx)(CarouselContext.Provider, Object.assign({ value: providerValue }, { children: (0, jsx_runtime_1.jsx)(Track_1.Tract, { children: modifiedSlides }) })), showArrows && ((0, jsx_runtime_1.jsx)("button", Object.assign({ className: `Carousel-arrowContainer-local ${styles.arrowContainer} ${styles.nextArrow}`, "aria-label": "next slide" }, (0, Utils_1.getTestIdAttribute)('nextButton'), { onClick: () => handleArrowClick('next') }, { children: (0, jsx_runtime_1.jsx)(icons_1.ArrowRightIcon, { className: styles.icon }) })))] })), showDots && renderDots()] })));
};
exports.Carousel = Carousel;
Carousel.Slide = (args) => (0, jsx_runtime_1.jsx)(Slide_1.Slide, Object.assign({}, args, { children: args.children }));
