"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTooltipStyles = void 0;
const theme_1 = require("../../../themes/typings/theme");
const Utils_1 = require("../../../utils/Utils");
exports.getTooltipStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    mainContainer: {
        position: 'relative',
        width: 'max-content',
    },
    contentPopper: {
        position: 'fixed',
        zIndex: '1000',
    },
    hidden: {
        visibility: 'hidden',
    },
    contentContainer: Object.assign(Object.assign({}, theme.typography.$textPrimary000), { boxSizing: 'inherit', display: 'block', height: 'auto', lineHeight: '18px', position: 'relative', textAlign: 'left', width: 'max-content', zIndex: 1 }),
    contentWrapper: {
        backgroundColor: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$primary200 : theme.colors.$neutral100,
        borderRadius: '4px',
        color: theme.colors.$content100,
        display: 'block',
        filter: 'drop-shadow(rgba(41, 56, 49, 0.5) 0px 4px 10px)',
        height: 'auto',
        maxWidth: '275px',
        padding: `${theme.spacing.$s2} ${theme.spacing.$s3}`,
        width: 'max-content',
    },
    arrow: {
        overflow: 'hidden',
        position: 'absolute',
        pointerEvents: 'none',
        '&::before': {
            content: '""',
            backgroundColor: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$primary200 : theme.colors.$neutral100,
            display: 'block',
            height: '100%',
            width: '100%',
        },
        '&$top': {
            top: '100%',
            height: ({ arrowSize }) => arrowSize,
            left: '50%',
            transform: 'translateX(-50%)',
            width: ({ arrowSize }) => arrowSize * Math.sqrt(2),
            '&::before': {
                transform: 'rotate(45deg)',
                transformOrigin: '100% 0px',
            },
        },
        '&$left': {
            left: 0,
            height: ({ arrowSize }) => arrowSize * Math.sqrt(2),
            top: '50%',
            transform: ({ arrowSize }) => `translateY(-50%) translateX(-${arrowSize - 1}px)`,
            width: ({ arrowSize }) => arrowSize,
            '&::before': {
                transform: 'rotate(45deg)',
                transformOrigin: '100% 100%',
            },
        },
        '&$right': {
            right: 0,
            height: ({ arrowSize }) => arrowSize * Math.sqrt(2),
            bottom: '50%',
            transform: ({ arrowSize }) => `translateY(50%) translateX(${arrowSize - 1}px)`,
            width: ({ arrowSize }) => arrowSize,
            '&::before': {
                transform: 'rotate(45deg)',
                transformOrigin: '0 0',
            },
        },
        '&$bottom': {
            bottom: '100%',
            height: ({ arrowSize }) => arrowSize,
            left: '50%',
            transform: 'translateX(-50%)',
            width: ({ arrowSize }) => arrowSize * Math.sqrt(2),
            '&::before': {
                transform: 'rotate(45deg)',
                transformOrigin: '0px 100%',
            },
        },
    },
    top: {},
    right: {},
    bottom: {},
    left: {},
    [`@media (max-width: ${theme.breakpoints.$sm}px)`]: {},
}), { name: 'Blueprint-Tooltip' });
