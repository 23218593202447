"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlobalThemes = exports.GlobalBaseTheme = exports.specialThemeKeys = exports.THEME_VARIABLES = exports.RAW_THEME_VARIABLES = void 0;
exports.RAW_THEME_VARIABLES = 'rawVariables';
exports.THEME_VARIABLES = 'variables';
exports.specialThemeKeys = ['__cache', 'variants'];
var GlobalBaseTheme;
(function (GlobalBaseTheme) {
    GlobalBaseTheme["LIGHT"] = "light";
    GlobalBaseTheme["DARK"] = "dark";
})(GlobalBaseTheme = exports.GlobalBaseTheme || (exports.GlobalBaseTheme = {}));
var GlobalThemes;
(function (GlobalThemes) {
    GlobalThemes["GlobalLightTheme"] = "globalLightTheme";
    GlobalThemes["GlobalDarkTheme"] = "globalDarkTheme";
})(GlobalThemes = exports.GlobalThemes || (exports.GlobalThemes = {}));
