"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSubMenuItemStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
exports.getSubMenuItemStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    subMenuItem: {
        borderWidth: '0 0 1px 0',
        borderStyle: 'solid',
        borderColor: theme.colors.$content100,
        padding: `${theme.spacing.$s2} ${theme.spacing.$s3}`,
        '&:last-child': {
            marginBottom: theme.spacing.$s4,
        },
    },
    link: {
        '& a': {
            alignItems: 'center',
            color: theme.colors.$content100,
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: theme.spacing.$s3,
            '&:hover, &:focus': {
                color: theme.colors.$content100,
            },
            '&:visited': {
                color: theme.colors.$content100,
                '&:hover, &:focus': {
                    color: theme.colors.$content100,
                },
            },
            '&:active': {
                color: theme.colors.$content100,
            },
        },
        '&$disabled': {
            '& a': {
                color: theme.colors.$interactiveLinkDisabled,
                '&:visited, &:active': {
                    color: theme.colors.$interactiveLinkDisabled,
                    '&:hover, &:focus': {
                        color: theme.colors.$interactiveLinkDisabled,
                        cursor: 'not-allowed',
                    },
                },
                '&:hover, &:focus': {
                    color: theme.colors.$interactiveLinkDisabled,
                    cursor: 'not-allowed',
                },
            },
        },
    },
    rightIcon: {
        width: '40px',
        height: '40px',
        '& path': {
            fill: theme.colors.$content100,
        },
        '&$disabled': {
            '& path': {
                fill: theme.colors.$interactiveLinkDisabled,
            },
        },
    },
    disabled: {},
}), { name: 'Blueprint-SubMenuItem' });
