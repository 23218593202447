"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getInputStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
exports.getInputStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginBottom: 0,
        width: '100%',
    },
    wrapper: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        '&$hideLabel': {
            padding: `0px ${theme.spacing.$s2}`,
        },
    },
    innerWrapper: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
    },
    prefix: {
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    suffix: {
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    helperContainer: Object.assign(Object.assign({}, theme.typography.$textSecondary000), { color: theme.colors.$neutral400, display: 'flex', justifyContent: 'flex-start', margin: `${theme.spacing.$s1} 0px 0px`, '&$errorMessage': {
            color: theme.variants.inputs.default.$error.color,
        } }),
    default: Object.assign(Object.assign({}, theme.variants.inputs.default), { '& $input': Object.assign(Object.assign({}, theme.variants.inputs.default), { backgroundColor: theme.variants.inputs.default.backgroundColor, borderColor: 'transparent', borderStyle: 'none', borderWidth: '0px', height: '100%', padding: 0, '&:disabled': {
                backgroundColor: theme.variants.inputs.default.$disabled.backgroundColor,
                '&:hover': {
                    cursor: 'not-allowed',
                },
            }, '&:focus': {
                outline: 'none',
            } }), '&:hover': {
            backgroundColor: theme.variants.inputs.default.$hover.backgroundColor,
            borderColor: theme.variants.inputs.default.$hover.borderColor,
            color: theme.variants.inputs.default.$hover.color,
        }, '&$focus': {
            backgroundColor: theme.variants.inputs.default.$hover.backgroundColor,
            borderColor: theme.variants.inputs.default.$hover.borderColor,
            color: theme.variants.inputs.default.$hover.color,
        }, '&$hasError': {
            backgroundColor: theme.variants.inputs.default.$error.backgroundColor,
            borderColor: theme.variants.inputs.default.$error.borderColor,
            color: theme.variants.inputs.default.$error.color,
            '&:hover': {
                borderColor: theme.variants.inputs.default.$error.borderColor,
                color: theme.variants.inputs.default.$error.color,
            },
            '&$focus': {
                borderColor: theme.variants.inputs.default.$error.borderColor,
                color: theme.variants.inputs.default.$error.color,
            },
            '&$disabled': {
                borderColor: theme.variants.inputs.default.$error.borderColor,
                color: theme.variants.inputs.default.$error.color,
                backgroundColor: theme.variants.inputs.default.$disabled.backgroundColor,
            },
        }, '&$readOnly': {
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            paddingInline: 0,
            '& input': {
                backgroundColor: 'transparent',
                borderColor: 'transparent',
            },
        }, '&$disabled': {
            backgroundColor: theme.variants.inputs.default.$disabled.backgroundColor,
            borderColor: theme.variants.inputs.default.$disabled.borderColor,
            color: theme.variants.inputs.default.$disabled.color,
        }, '& $label': {
            color: theme.variants.inputs.default.$label.color,
            backgroundColor: 'transparent',
            '&$hasError, &$disabled$hasError': {
                color: theme.variants.inputs.default.$labelError.color,
            },
            '&$disabled': {
                color: theme.variants.inputs.default.$label.color,
                '&:hover': {
                    cursor: 'not-allowed',
                },
            },
        } }),
    label: {},
    input: {},
    hideLabel: {},
    errorMessage: {},
    focus: {},
    readOnly: {},
    disabled: {},
    hasError: {},
}), { name: 'Blueprint-Input' });
