"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getErrorPageStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
exports.getErrorPageStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    mainContainer: {
        backgroundColor: theme.colors.$background100,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    mainWrapper: {
        display: 'flex',
        height: '100%',
        justifyContent: 'stretch',
        margin: '0 auto',
        maxWidth: '1440px',
        width: '100%',
        padding: theme.spacing.$s8,
    },
    leftContent: {
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '50%',
        maxWidth: '500px',
    },
    rightContent: {
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    link: {
        '&.Link-local': {
            height: 'auto',
            '& a': {
                padding: 0,
            },
        },
    },
    error: Object.assign(Object.assign({}, theme.typography.$textSecondary100), { marginBottom: theme.spacing.$s4 }),
    title: Object.assign(Object.assign({}, theme.typography.$textPrimary650), { color: theme.colors.$secondary300, marginBottom: theme.spacing.$s4 }),
    content: Object.assign(Object.assign({}, theme.typography.$textPrimary200), { marginBottom: theme.spacing.$s4 }),
    image: {
        margin: '0 auto',
        maxWidth: '600px',
        minWidth: '300px',
        width: '100%',
        height: '100%',
    },
    [`@media (max-width: ${theme.breakpoints.$sm}px)`]: {
        mainWrapper: {
            flexDirection: 'column-reverse',
            padding: theme.spacing.$s3,
            height: 'auto',
        },
        leftContent: {
            width: '100%',
        },
        error: Object.assign(Object.assign({}, theme.typography.$textSecondary100), { marginBottom: theme.spacing.$s3 }),
        title: Object.assign(Object.assign({}, theme.typography.$textPrimary550), { color: theme.colors.$secondary300, marginBottom: theme.spacing.$s3 }),
        content: Object.assign(Object.assign({}, theme.typography.$textPrimary100), { marginBottom: theme.spacing.$s3 }),
        rightContent: {
            width: '100%',
        },
        image: {
            minWidth: '80%',
        },
    },
}), { name: 'Blueprint-ErrorPage' });
