"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.paletteColorValues = void 0;
// Color Palette
/// Primary Colors
const $primary100 = '#678D7C';
const $primary200 = '#455E52';
const $primary300 = '#293831';
const $primary400 = '#161E1A';
const $primary500 = '#0B100E';
/// Secondary Colors
const $secondary100 = '#F9A881';
const $secondary200 = '#F78D5A';
const $secondary300 = '#F57233';
const $secondary400 = '#C3460A';
const $secondary500 = '#973607';
/// Tertiary Colors
const $tertiary100 = '#FAF7F3';
const $tertiary200 = '#EBE4DA';
const $tertiary300 = '#E0D4C3';
const $tertiary400 = '#CDBEAB';
const $tertiary500 = '#BCA88E';
/// Neutral Colors
const $black = '#000000';
const $neutral100 = '#F6F7F7';
const $neutral200 = '#D4D7D6';
const $neutral300 = '#BFC3C2';
const $neutral400 = '#959C9A';
const $neutral500 = '#676E6C';
const $neutral600 = '#4A524E';
const $white = '#FFFFFF';
/// Success Colors
const $success100 = '#D0E9D4';
const $success200 = '#A0D2A8';
const $success300 = '#86B08C';
const $success400 = '#6DA075';
const $success500 = '#3D5D42';
/// Error Colors
const $error100 = '#EB8A8A';
const $error200 = '#C93C48';
const $error300 = '#921D27';
const $error400 = '#70161E';
const $error500 = '#4A0E14';
/// Accent Colors
const $accent100 = '#7BB2A6';
const $accent200 = '#99E123';
const $accent300 = '#FFE457';
exports.paletteColorValues = {
    $primary100,
    $primary200,
    $primary300,
    $primary400,
    $primary500,
    $secondary100,
    $secondary200,
    $secondary300,
    $secondary400,
    $secondary500,
    $tertiary100,
    $tertiary200,
    $tertiary300,
    $tertiary400,
    $tertiary500,
    $black,
    $neutral100,
    $neutral200,
    $neutral300,
    $neutral400,
    $neutral500,
    $neutral600,
    $white,
    $success100,
    $success200,
    $success300,
    $success400,
    $success500,
    $error100,
    $error200,
    $error300,
    $error400,
    $error500,
    $accent100,
    $accent200,
    $accent300,
};
