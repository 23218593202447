"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTrackStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
exports.getTrackStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    track: {
        display: 'flex',
        height: '100%',
        width: 'fit-content',
        minWidth: '100%',
        alignItems: 'center',
    },
    [`@media (max-width: ${theme.breakpoints.$sm}px)`]: {},
}), { name: 'Blueprint-Track' });
