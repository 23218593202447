"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Label = exports.LabelVariants = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const Utils_1 = require("../../utils/Utils");
const Label_styles_1 = require("./styles/Label.styles");
const __DEV__ = process.env.NODE_ENV !== 'production';
var LabelVariants;
(function (LabelVariants) {
    LabelVariants["DEFAULT"] = "default";
})(LabelVariants = exports.LabelVariants || (exports.LabelVariants = {}));
const Label = ({ htmlFor, id, content, screenReaderOnlyText = undefined, className = '', localClassName = 'Label-local', testId, variant = LabelVariants.DEFAULT, }) => {
    const theme = (0, ThemeProvider_1.useTheme)();
    if (!theme) {
        if (__DEV__) {
            console.error('`Label` component must be a child of a `ThemeProvider`');
        }
        return null;
    }
    if (!content && !screenReaderOnlyText) {
        if (__DEV__) {
            console.error('`content` or `screenReaderOnlyText` are required');
        }
        return null;
    }
    const styles = (0, Label_styles_1.getLabelStyles)();
    const dti = (0, Utils_1.getTestIdAttribute)(testId);
    return ((0, jsx_runtime_1.jsxs)("label", Object.assign({ htmlFor: htmlFor, id: id, className: `${localClassName} ${styles[variant]} ${!content ? styles.hidden : ''} ${className !== null && className !== void 0 ? className : ''}` }, dti, { children: [content, screenReaderOnlyText] })));
};
exports.Label = Label;
