"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuxiliaryFooter = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Utils_1 = require("../../utils/Utils");
const AuxiliaryFooter_styles_1 = require("./styles/AuxiliaryFooter.styles");
const AuxiliaryFooter = (props) => {
    const { content, isMobile } = props;
    const styles = (0, AuxiliaryFooter_styles_1.getAuxiliaryFooterStyles)();
    const dti = (0, Utils_1.getTestIdAttribute)('footer-auxiliary-footer');
    return ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: `AuxiliaryFooter-local ${styles.container} ${isMobile ? styles.mobile : ''}` }, dti, { children: content })));
};
exports.AuxiliaryFooter = AuxiliaryFooter;
