"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubNavItem = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Link_1 = require("../Link/Link");
const Utils_1 = require("../../utils/Utils");
const ThemeProvider_1 = require("../../utils/ThemeProvider");
const StyleUtils_1 = require("../../utils/StyleUtils");
const SubNavItem_styles_1 = require("./styles/SubNavItem.styles");
const SubNavItem = (props) => {
    var _a;
    const { navItem, domains } = props;
    const styles = (0, SubNavItem_styles_1.getSubNavItemStyles)();
    const { pathname } = (0, Utils_1.parseLocation)((0, ThemeProvider_1.useThemeRouter)().router);
    const isActive = pathname.includes((_a = navItem.href) !== null && _a !== void 0 ? _a : '');
    return ((0, jsx_runtime_1.jsx)("li", Object.assign({ className: `SubNavItem-listItem-local ${styles.listItem}` }, { children: !navItem.render ? ((0, jsx_runtime_1.jsx)(Link_1.Link, Object.assign({ id: navItem.id, href: `${navItem.experience ? (0, Utils_1.getDomainBasedOnExperience)(domains, navItem.experience) : ''}${navItem.href}`, disabled: navItem.disabled, variant: Link_1.LinkVariants.NAV, className: (0, StyleUtils_1.classNames)(styles.subNavItem, isActive && styles.active, navItem.disabled && styles.disabled), fullWidth: true, useRouter: !navItem.isExternal, target: navItem.openNewTab ? '_blank' : '_self' }, { children: navItem.label }))) : (navItem.render(props)) })));
};
exports.SubNavItem = SubNavItem;
