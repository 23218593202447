"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getToggleStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
const theme_1 = require("../../../themes/typings/theme");
const StyleUtils_1 = require("../../../utils/StyleUtils");
exports.getToggleStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    container: Object.assign(Object.assign({}, (0, StyleUtils_1.flexMixin)('row')), { backgroundColor: theme.colors.$background100, border: `1px solid ${theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$primary300 : theme.colors.$primary200}`, borderRadius: '4px', gap: '2px', padding: '2px', width: 'fit-content', '&$disabled': {
            borderColor: theme.colors.$interactivePrimaryDisabled,
            cursor: 'not-allowed',
        } }),
    button: Object.assign(Object.assign(Object.assign({}, (0, StyleUtils_1.flexMixin)('row', 'center', 'center')), theme.typography.$textSecondary000), { borderRadius: 2, backgroundColor: 'transparent', color: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$content100 : theme.colors.$primary200, cursor: 'pointer', height: '24px', 
        // DIFF lineHeight: '14px',
        minWidth: '44px', padding: `${theme.spacing.$s1} ${theme.spacing.$s2}`, transition: 'background-color 150ms, color 150ms', width: 'fit-content', '&:hover': {
            backgroundColor: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$primary400 : theme.colors.$neutral200,
        }, '&$active': Object.assign(Object.assign({}, theme.typography.$textSecondary000), { backgroundColor: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$primary300 : theme.colors.$primary200, color: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$content100 : theme.colors.$content300 }), '&:disabled': {
            backgroundColor: 'transparent',
            color: theme.colors.$interactivePrimaryDisabled,
            cursor: 'not-allowed',
            '&$active': {
                backgroundColor: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$neutral600 : theme.colors.$interactivePrimaryDisabled,
                color: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$interactivePrimaryDisabled : theme.colors.$content300,
            },
        } }),
    active: {},
    disabled: {},
}), { name: 'Blueprint-Toggle' });
