"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCarouselStyles = void 0;
const theme_1 = require("../../../themes/typings/theme");
const Utils_1 = require("../../../utils/Utils");
exports.getCarouselStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    mainContainer: {
        display: 'flex',
        justifyContent: 'stretch',
        alignItems: 'center',
        width: '100%',
        height: 'fit-content',
        margin: `${theme.spacing.$s4} auto`,
        flexDirection: 'column',
        backgroundColor: theme.colors.$background100,
    },
    arrowContainer: {
        alignItems: 'center',
        backgroundColor: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$secondary300 : theme.colors.$primary300,
        borderRadius: '50px',
        display: 'flex',
        height: '50px',
        justifyContent: 'center',
        position: 'absolute',
        width: '50px',
        zIndex: '1',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    prevArrow: {
        left: theme.spacing.$s2,
    },
    nextArrow: {
        right: theme.spacing.$s2,
    },
    icon: {
        height: '30px',
        width: '30px',
        '& path': {
            fill: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$primary300 : theme.colors.$neutral100,
        },
    },
    mainWrapper: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        maxWidth: '100%',
        minWidth: '100%',
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
    },
    dotsContainer: {
        alignItems: 'center',
        display: 'flex',
        gap: theme.spacing.$s2,
        justifyContent: 'space-around',
        marginTop: theme.spacing.$s2,
        width: 'fit-content',
    },
    dot: {
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        backgroundColor: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$primary200 : theme.colors.$primary300,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    active: {
        backgroundColor: theme.__base === theme_1.GlobalBaseTheme.DARK ? theme.colors.$secondary300 : theme.colors.$secondary300,
    },
    hidden: {
        opacity: 0,
    },
    [`@media (max-width: ${theme.breakpoints.$sm}px)`]: {
        arrowContainer: {
            borderRadius: '40px',
            height: '40px',
            width: '40px',
        },
        prevArrow: {
            left: theme.spacing.$s1,
        },
        nextArrow: {
            right: theme.spacing.$s1,
        },
        icon: {
            height: '20px',
            width: '20px',
        },
    },
}), { name: 'Blueprint-Carousel' });
