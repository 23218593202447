"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSpinnerStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
exports.getSpinnerStyles = (0, Utils_1.createStyleSheet)({
    spinner: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}, { name: 'Blueprint-Spinner' });
