"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getHighchartStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
exports.getHighchartStyles = (0, Utils_1.createStyleSheet)({
    container: {
        display: 'flex',
        width: '100%',
    },
    chartContainer: {
        width: '100%',
        height: '100%',
    },
}, { name: 'Blueprint-Highchart' });
