"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDrawerPanelStyles = void 0;
const Utils_1 = require("../../../utils/Utils");
const StyleUtils_1 = require("../../../utils/StyleUtils");
exports.getDrawerPanelStyles = (0, Utils_1.createStyleSheet)((theme) => ({
    maskContainer: Object.assign(Object.assign(Object.assign({}, (0, StyleUtils_1.zIndexMixin)(-1)), (0, StyleUtils_1.flexMixin)()), { backgroundColor: `${theme.colors.$background100}D9`, overflow: 'hidden', position: 'fixed', bottom: 0, right: 0, top: 0, left: 0, opacity: 0, transition: 'opacity 0.3s ease-out, z-index 0.05s ease-out', width: '100vw', minWidth: '100vw', maxWidth: '100vw', '&$left': {
            justifyContent: 'flex-start',
        }, '&$right': {
            justifyContent: 'flex-end',
        }, '&$top': {
            alignItems: 'flex-start',
        }, '&$bottom': {
            alignItems: 'flex-end',
        } }),
    maskPanelContainer: Object.assign(Object.assign(Object.assign({}, theme.shadows.$dropShadow100), (0, StyleUtils_1.flexMixin)('column')), { height: (0, StyleUtils_1.vh)(), maxHeight: (0, StyleUtils_1.vh)(), minHeight: (0, StyleUtils_1.vh)(), minWidth: '300px', overflowY: 'auto', visibility: 'hidden', top: 0, transition: 'transform 0.3s ease-out, visibility 0.3s ease-out', width: 'fit-content', '&$right': {
            transform: 'translateX(100%)',
        }, '&$left': {
            transform: 'translateX(-100%)',
        }, '&$top': {
            height: 'fit-content',
            maxWidth: '100vw',
            minHeight: '200px',
            minWidth: '100vw',
            transform: 'translateY(-100%)',
            width: '100vw',
            '& .Panel-local': {
                minHeight: '300px',
                maxHeight: '300px',
                minWidth: '100%',
                alignSelf: 'flex-start',
            },
        }, '&$bottom': {
            height: 'fit-content',
            maxWidth: '100vw',
            minHeight: '200px',
            minWidth: '100vw',
            top: 'auto',
            transform: 'translateY(100%)',
            width: '100vw',
            '& .Panel-local': {
                minHeight: '300px',
                maxHeight: '300px',
                minWidth: '100%',
                alignSelf: 'flex-end',
            },
        }, '&$slide': {
            visibility: 'visible',
            transform: 'translateX(0)',
        } }),
    popoutContainer: Object.assign(Object.assign(Object.assign(Object.assign({}, theme.shadows.$dropShadow100), (0, StyleUtils_1.flexMixin)('column')), (0, StyleUtils_1.zIndexMixin)(1001)), { height: (0, StyleUtils_1.vh)(), maxHeight: (0, StyleUtils_1.vh)(), minHeight: (0, StyleUtils_1.vh)(), minWidth: '300px', overflowY: 'auto', position: 'fixed', visibility: 'hidden', top: 0, transition: 'transform 0.3s ease-out, visibility 0.3s ease-out', width: 'fit-content', '&$right': {
            transform: 'translateX(100%)',
        }, '&$left': {
            transform: 'translateX(-100%)',
        }, '&$top': {
            height: 'fit-content',
            maxWidth: '100vw',
            minHeight: '200px',
            minWidth: '100vw',
            transform: 'translateY(-100%)',
            width: '100vw',
            '& .Panel-local': {
                minHeight: '300px',
                maxHeight: '300px',
                minWidth: '100%',
                alignSelf: 'flex-start',
            },
        }, '&$bottom': {
            height: 'fit-content',
            maxWidth: '100vw',
            minHeight: '200px',
            minWidth: '100vw',
            top: 'auto',
            transform: 'translateY(100%)',
            width: '100vw',
            '& .Panel-local': {
                minHeight: '300px',
                maxHeight: '300px',
                minWidth: '100%',
                alignSelf: 'flex-end',
            },
        }, '&$slide': {
            visibility: 'visible',
            transform: 'translateX(0)',
        } }),
    closeContainer: Object.assign(Object.assign({}, (0, StyleUtils_1.flexMixin)('row', 'flex-end', 'center')), { padding: `${theme.spacing.$s2}`, width: '100%' }),
    closeButton: Object.assign(Object.assign({}, (0, StyleUtils_1.flexMixin)('row', 'center', 'center')), { padding: theme.spacing.$s1, backgroundColor: 'transparent', borderRadius: theme.spacing.$s1, '&:hover': {
            cursor: 'pointer',
        } }),
    closeIcon: {
        width: '15px',
        height: '15px',
        '& path': {
            fill: theme.colors.$content100,
        },
    },
    fadeIn: {
        opacity: '1',
    },
    fadeForward: Object.assign({}, (0, StyleUtils_1.zIndexMixin)(1001)),
    dock: {},
    left: {
        left: 0,
    },
    right: {
        right: 0,
    },
    top: {
        top: 0,
        left: 0,
        right: 0,
    },
    bottom: {
        bottom: 0,
        left: 0,
        right: 0,
    },
    slide: {
        transform: 'translateX(0)',
    },
}), {
    name: 'Blueprint-DrawerPanel',
});
