"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NavBar = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const NavBar_styles_1 = require("./styles/NavBar.styles");
const NavItem_1 = require("./NavItem");
const NavDropdown_1 = require("./NavDropdown");
const NavBar = (props) => {
    const { navItems, domains } = props;
    const styles = (0, NavBar_styles_1.getNavBarStyles)();
    return ((0, jsx_runtime_1.jsx)("nav", Object.assign({ "aria-label": "gromaHeaderNav", className: `NavBar-rightContent-local ${styles.navBarContainer}` }, { children: (0, jsx_runtime_1.jsx)("ul", Object.assign({ className: `NavBar-navBarWrapper-local ${styles.navBarWrapper}` }, { children: navItems.map((navItem) => {
                if (!navItem.href && !navItem.subNavItems && !navItem.render) {
                    console.error("Nav Items require 'href' or 'subNavItems' or 'render'.");
                    return null;
                }
                return !navItem.subNavItems ? ((0, jsx_runtime_1.jsx)(NavItem_1.NavItem, { navItem: navItem, domains: domains }, navItem.id)) : ((0, jsx_runtime_1.jsx)(NavDropdown_1.NavDropdown, { navItem: navItem, domains: domains }, navItem.id));
            }) })) })));
};
exports.NavBar = NavBar;
